import React from 'react';
import './current.scss';
import { MdMoreVert, MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const CurrentChart = () => {
      return (
            <div className='currentChart'>

                  <div className='top'>
                        <h1 className="title">Total Revenue</h1>
                        <MdMoreVert fontSize='small'/>
                  </div>

                  <div className='bottom'>
                        <div className="chart">
                              <CircularProgressbar value={80} text={"80%"} strokeWidth={5} />
                        </div>

                        <p className='title'>Total Monthly Sales</p>
                        <p className='amount'>UGX: 560000</p>
                        <p className='desc'>All transactions processed. Last payment received may not be included</p>

                        <div className="summary">
                              <div className="item">
                                    <div className="itemTitle">Target</div>
                                    <div className="itemResult positive">
                                          <MdKeyboardArrowUp/>
                                          <div className="resultAmount">UGX: 5.24m</div>
                                    </div>
                              </div>
                              <div className="item">
                                    <div className="itemTitle">Last Week</div>
                                    <div className="itemResult negative">
                                          <MdKeyboardArrowDown/>
                                          <div className="resultAmount">UGX: 5.24m</div>
                                    </div>
                              </div>
                              <div className="item">
                                    <div className="itemTitle">Last Month</div>
                                    <div className="itemResult positive">
                                          <MdKeyboardArrowUp/>
                                          <div className="resultAmount">UGX: 5.24m</div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </div>
      )
}

export default CurrentChart