import React from 'react';
import './menuItem.scss';
import { NavLink } from 'react-router-dom';

const MenuItem = ({ label, icon, path }) => {

    // const [active, setActive] = useState(true);
    // const location = useLocation();

    // useEffect(() => {
    //     setActive(location.pathname === path);
    //   }, [location, path]);

    return (
        <NavLink
            to={path}
            style={{textDecoration: 'none', color: 'inherit'}}
            className={({ isActive }) => "adminMenuItem" + (isActive ? " activated" : "")}
        >
            <span className='menuItemIcon'>{icon}</span>
            <h3 className='menuItemLabel'>{label}</h3>

        </NavLink>
    )
}

export default MenuItem