import React, { useState } from 'react';
import './responseNav.scss';
// import { sideMenItems, sideWomenItems } from '../../nav-data';
import { FaBars, FaTimes } from 'react-icons/fa';
// import { BsHandbag } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
// import { IoPerson } from 'react-icons/io5';
// import { MdKeyboardArrowRight } from 'react-icons/md';
// import { NavLink } from 'react-router-dom';
import { FaRegCopyright } from 'react-icons/fa';
import { VscAccount } from "react-icons/vsc";
import { TfiSearch } from "react-icons/tfi";
import { SlHeart } from "react-icons/sl";
// import { PiHeartLight } from "react-icons/pi";
import { IoBagOutline } from "react-icons/io5";
// import { MdKeyboardArrowDown } from 'react-icons/md';

import woman from '../../assets/slider/woman22.png';
import man from '../../assets/slider/man22.png';
import kids from '../../assets/slider/kids22.png';
import ResKids from './resComps/ResKids';
import ResWomen from './resComps/ResWomen';
import ResMen from './resComps/ResMen';
import axios from 'axios';



const ResponseNav = () => {

    const currentUser = false;
    const [sidebar, setSidebar] = useState(false);
    const navigate = useNavigate();
    // const [open, setOpen] = useState(false);
    // const showSidebar = () => setSidebar(!sidebar);

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }
    const [dropdown, setDropdown] = useState(false);


    const [selectedRes, setSelectedRes] = useState("WOMEN");
    const list = [
        {
            id: 1,
            title: "WOMEN",
            image: woman,
        },
        {
            id: 2,
            title: "MEN",
            image: man,
        },
        {
            id: 3,
            title: "KIDS",
            image: kids,
        },

    ]

    const renderCurrentSelection = () => {
        switch (selectedRes) {
            case "WOMEN":
                return <ResWomen sidebar={sidebar} setSidebar={setSidebar} />;
            case "MEN":
                return <ResMen sidebar={sidebar} setSidebar={setSidebar} />;
            case "KIDS":
                return <ResKids sidebar={sidebar} setSidebar={setSidebar} />;
            default:
                return <ResWomen sidebar={sidebar} setSidebar={setSidebar} />;
        }
    };

    const ModuleList = ({ id, active, image, setSelectedRes }) => {
        return (
            <li
                className={active ? "module-listItem active" : "module-listItem"}
                onClick={() => setSelectedRes(id)}
            >
                <p>{id}</p>
                <img src={image} alt="" />
            </li>
        )
    }

    const handleLogout = async () => {
        try {
            await axios.post("https://tunjoo-server-36b3be406a89.herokuapp.com/api/auth/logout");
            window.localStorage.clear();
            navigate("/");
        } catch (err) {
            console.log(err);
        }
    };



    return (
        <div className='responseNav'>

            <div className="left" onClick={() => setSidebar(!sidebar)}>
                {/* <FaBars className="menuIcon" onClick={showSidebar} /> */}
                {sidebar ? <FaTimes className="menuIcon" /> : <FaBars className="menuIcon" />}

                <span>TUNJOO.</span>
            </div>

            <div className='right'>

                <div className="icons">
                    <div
                        className="activeUserWrapper"
                        onMouseEnter={() => setDropdown(true)}
                        onMouseLeave={() => setDropdown(false)}
                    >
                        <VscAccount className='icon' />

                        {dropdown && (
                            <div className="options">


                                <li className="menuItem2">
                                    <Link className='link' to="/login" onClick={scrollToTop}>
                                        <div className='optionBtn1'>Sign In</div>
                                    </Link>
                                    <Link className='link' to="/register" onClick={scrollToTop}>
                                        <div className='optionBtn2'>Sign Up</div>
                                    </Link>
                                </li>
                                <hr />

                                <li className="menuItem">
                                    <Link to={currentUser ? `/my-account/info/${currentUser._id}` : "/login"} className='optionsLink' onClick={scrollToTop}>
                                        <span>My Account</span>
                                    </Link>
                                </li>

                                <>
                                    <hr />
                                    <li className="menuItem3" onClick={handleLogout}>
                                        <div onClick={scrollToTop}>
                                            <h3>Sign Out</h3>
                                        </div>
                                    </li>
                                </>

                            </div>
                        )}
                    </div>

                    <div className="iconWrapper">

                        <div className="cartIcon" >
                            <TfiSearch className='searchIcon' />
                        </div>

                        <Link to={`/my-account/wishlist/${currentUser._id}`} className='link'>
                            <div className="likeIcon" >
                                <SlHeart className='icon' />
                                <span>{currentUser?.favorites?.length || 3}</span>
                            </div>
                        </Link>

                        <Link to="/cart" className='link'>
                            <div className="cartIcon" >
                                <IoBagOutline className='icon' />
                                <span>20</span>
                            </div>
                        </Link>

                    </div>

                    {/* <Link to="/cart" className='link'>
                        <div className="cartIcon" >
                            <BsHandbag className='icon' />
                            <span>20</span>
                        </div>
                    </Link> */}
                </div>

            </div>

            {/* <nav className="menu" style={{ left: sidebar ? "0px" : "-50vw" }}> */}
            <nav className="menu" style={{ left: sidebar ? "0px" : "-100vw" }}>

                <div className='cats'>
                    {list.map((item) => (
                        <ModuleList
                            id={item.title}
                            image={item.image}
                            active={selectedRes === item.title}
                            setSelectedRes={setSelectedRes}
                        />
                    ))}
                </div>

                <div className="resTop">
                    {/* <div className='cats'>
                        {list.map((item) => (
                            <ModuleList
                                id={item.title}
                                image={item.image}
                                active={selectedRes === item.title}
                                setSelectedRes={setSelectedRes}
                            />
                        ))}
                    </div> */}

                    {renderCurrentSelection()}

                    <div className="resText">
                        <FaRegCopyright style={{ color: "#282828", fontSize: 11, marginTop: '-4px', }} />
                        <p>2023</p>
                        <p>Tunjoo Era Limited</p>
                    </div>

                </div>



            </nav>

        </div>
    )
}

export default ResponseNav













// import React, { useState } from 'react';
// import './responseNav.scss';
// import { sideMenItems, sideWomenItems } from '../../nav-data';
// import { FaBars, FaPlus, FaMinus } from 'react-icons/fa';
// import { BsHandbag } from 'react-icons/bs';
// import { Link } from 'react-router-dom';
// import { IoPerson } from 'react-icons/io5';
// import { MdKeyboardArrowRight } from 'react-icons/md';
// import { NavLink } from 'react-router-dom';
// import { FaRegCopyright } from 'react-icons/fa';
// import { VscAccount } from "react-icons/vsc";
// import { TfiSearch } from "react-icons/tfi";
// import { SlHeart } from "react-icons/sl";
// import { PiHeartLight } from "react-icons/pi";
// import { IoBagOutline } from "react-icons/io5";
// import { MdKeyboardArrowDown } from 'react-icons/md';

// import woman from '../../assets/slider/woman22.png';
// import man from '../../assets/slider/man22.png';
// import kids from '../../assets/slider/kids22.png';
// import ResKids from './resComps/ResKids';
// import ResWomen from './resComps/ResWomen';
// import ResMen from './resComps/ResMen';




// const ResponseNav = () => {

//     const currentUser = false;
//     const [sidebar, setSidebar] = useState(false);
//     const showSidebar = () => setSidebar(!sidebar);

//     const scrollToTop = () => {
//         window.scrollTo(0, 0)
//     }
//     const [dropdown, setDropdown] = useState(false);


//     const [selectedRes, setSelectedRes] = useState("WOMEN");
//     const list = [
//         {
//             id: 1,
//             title: "WOMEN",
//             image: woman,
//         },
//         {
//             id: 2,
//             title: "MEN",
//             image: man,
//         },
//         {
//             id: 3,
//             title: "KIDS",
//             image: kids,
//         },

//     ]

//     const renderCurrentSelection = () => {
//         switch (selectedRes) {
//             case "WOMEN":
//                 return <ResWomen showSidebar={showSidebar} sidebar={sidebar}/>;
//             case "MEN":
//                 return <ResMen showSidebar={showSidebar} sidebar={sidebar}/>;
//             case "KIDS":
//                 return <ResKids showSidebar={showSidebar} sidebar={sidebar}/>;
//             default:
//                 return <ResWomen showSidebar={showSidebar} sidebar={sidebar}/>;
//         }
//     };

//     const ModuleList = ({ id, active, image, setSelectedRes }) => {
//         return (
//             <li
//                 className={active ? "module-listItem active" : "module-listItem"}
//                 onClick={() => setSelectedRes(id)}
//             >
//                 <p>{id}</p>
//                 <img src={image} alt="" />
//             </li>
//         )
//     }


//     return (
//         <div className='responseNav'>

//             <div className="left">
//                 <FaBars className="menuIcon" onClick={showSidebar} />
//                 <span>TUNJOO.</span>
//             </div>

//             <div className='right'>

//                 <div className="icons">
//                     <div
//                         className="activeUserWrapper"
//                         onMouseEnter={() => setDropdown(true)}
//                         onMouseLeave={() => setDropdown(false)}
//                     >
//                         <VscAccount className='icon' />
            
//                         {dropdown && (
//                             <div className="options">


//                                 <li className="menuItem2">
//                                     <Link className='link' to="/login" onClick={scrollToTop}>
//                                         <div className='optionBtn1'>Sign In</div>
//                                     </Link>
//                                     <Link className='link' to="/register" onClick={scrollToTop}>
//                                         <div className='optionBtn2'>Sign Up</div>
//                                     </Link>
//                                 </li>
//                                 <hr />

//                                 <li className="menuItem">
//                                     <Link to={currentUser ? `/my-account/info/${currentUser._id}` : "/login"} className='optionsLink' onClick={scrollToTop}>
//                                         <span>My Account</span>
//                                     </Link>
//                                 </li>

//                                 <>
//                                     <hr />
//                                     <li className="menuItem3" >
//                                         <div onClick={scrollToTop}>
//                                             <h3>Sign Out</h3>
//                                         </div>
//                                     </li>
//                                 </>

//                             </div>
//                         )}
//                     </div>

//                     <div className="iconWrapper">

//                         <div className="cartIcon" >
//                             <TfiSearch className='searchIcon' />
//                         </div>

//                         <Link to={`/my-account/wishlist/${currentUser._id}`} className='link'>
//                             <div className="likeIcon" >
//                                 <SlHeart className='icon' />
//                                 <span>{currentUser?.favorites?.length || 3}</span>
//                             </div>
//                         </Link>

//                         <Link to="/cart" className='link'>
//                             <div className="cartIcon" >
//                                 <IoBagOutline className='icon' />
//                                 <span>20</span>
//                             </div>
//                         </Link>

//                     </div>

//                     {/* <Link to="/cart" className='link'>
//                         <div className="cartIcon" >
//                             <BsHandbag className='icon' />
//                             <span>20</span>
//                         </div>
//                     </Link> */}
//                 </div>

//             </div>

//             {/* <nav className="menu" style={{ left: sidebar ? "0px" : "-50vw" }}> */}
//             <nav className="menu"  style={{ left: sidebar ? "0px" : "-100vw" }}>

//                 <div className="resTop">
//                 <div className='cats'>
//                     {list.map((item) => (
//                         <ModuleList
//                             id={item.title}
//                             image={item.image}
//                             active={selectedRes === item.title}
//                             setSelectedRes={setSelectedRes}
//                         />
//                     ))}
//                 </div>

//                 {renderCurrentSelection()}
//                 </div>

//                 <div className="text">
//                     <FaRegCopyright style={{ color: "#282828", fontSize: 11 }} />
//                     <p>2023</p>
//                     <p>Tunjoo Era Limited</p>
//                 </div>

//             </nav>

//         </div>
//     )
// }

// export default ResponseNav