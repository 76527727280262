import React from 'react';
import './adminComps.scss';


const AdminTransactions = () => {
  return (
    <div className='adminUsers'>
        AdminTransactions
    </div>
  )
}

export default AdminTransactions