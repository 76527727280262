//POPULAR STYLES

//All logos
import zaralogo from './assets/featuredBrands/zaralogo.png';
import hmlogo from './assets/featuredBrands/hmlogo.png';
// import splashlogo from './assets/featuredBrands/splashlogo.png';
import topshoplogo from './assets/featuredBrands/topshoplogo.png';
// import newlooklogo from './assets/featuredBrands/newlooklogo.png';
// import riverlogo from './assets/featuredBrands/riverlogo.png';
// import guesslogo from './assets/featuredBrands/guesslogo.png';
import aldologo from './assets/featuredBrands/aldologo.png';
// import ellelogo from './assets/featuredBrands/ellelogo.png';
// import adiddaslogo from './assets/featuredBrands/adiddaslogo.png';
// import celestelogo from './assets/featuredBrands/celestelogo.png';
import ninelogo from './assets/featuredBrands/ninelogo.png';
import pumalogo from './assets/featuredBrands/pumalogo.png';
// import sketcherslogo from './assets/featuredBrands/sketcherslogo.png';
import stevelogo from './assets/featuredBrands/stevelogo.png';
import mangologo from './assets/featuredBrands/mangologo.png';
// import hadanalogo from './assets/featuredBrands/hadanalogo.png';
// import newlooklogo from './assets/featuredBrands/newlooklogo.png';
// import newlooklogo from './assets/featuredBrands/newlooklogo.png';



//Women's page Featured Brands
import zaraBrand from './assets/featuredBrands/zaraBrand.png';
import aldoBrand from './assets/featuredBrands/aldoBrand.png';
import hmBrand from './assets/featuredBrands/hmBrand.png';
import stevebrand from './assets/featuredBrands/steveBrand.png';
import guessBrand from './assets/featuredBrands/guessBrand.png';
// import topshopBrand from './assets/featuredBrands/topshopbrand.jpg';
import tunjooBrand from './assets/featuredBrands/tunjooBrand.png';
import nineWestBrand from './assets/featuredBrands/nineWestBrand.png';
// import americanBrand from './assets/featuredBrands/americanBrand.png';
import mangoBrand from './assets/featuredBrands/mangoBrand.png';
import tunjooMan from './assets/featuredBrands/tunjooMan.png';
import hmMan from './assets/featuredBrands/hmMan.png';
import zaraMan from './assets/featuredBrands/zaraMan.png';
import mangoMan from './assets/featuredBrands/mangoMan.png';
import hugoMan from './assets/featuredBrands/hugoMan.png';
import steveMan from './assets/featuredBrands/steveMan.png';
import gapMan from './assets/featuredBrands/gapMan.png';
import americanMan from './assets/featuredBrands/americanMan.png';
import tommyMan from './assets/featuredBrands/tommyMan.png';
// import tommyLogo from './assets/featuredBrands/tommylogo.png';
import gapLogo from './assets/featuredBrands/gapLogo.png';
import beverlyWoman from './assets/featuredBrands/beverlyWoman.png';
import beverlyMan from './assets/featuredBrands/beverlyMan.png';
import celeste from './assets/featuredBrands/celeste.png';

import zaraKids from './assets/featuredBrands/zaraKids.png';
import hmKids from './assets/featuredBrands/hmKids.png';
import gapKids from './assets/featuredBrands/gapKids.png';
import kappa from './assets/featuredBrands/kappaKids.png';
import juniors from './assets/featuredBrands/juniorsBrand.png';
import tunjooKids from './assets/featuredBrands/tunjooKids.png';
import juniorLogo from './assets/featuredBrands/juniorLogo.png';



//WOMEN DATA SECTION
export const wbrandData = [
    {
        id: 1,
        img: zaraBrand,
        logo: zaralogo,
        brand: 'ZARA WOMAN',
        group: "Women",
    },
    {
        id: 2,
        img: aldoBrand,
        logo: aldologo,
        brand: 'ALDO WOMAN',
        group: "Women",
    },
    {
        id: 3,
        img: hmBrand,
        logo: hmlogo,
        brand: 'HM WOMAN',
        group: "Women",
    },
    {
        id: 4,
        img: stevebrand,
        logo: stevelogo,
        brand: 'STEVE MADDEN WOMAN',
        group: "Women",
    },

]



///////////MEN/////////////
//MEN FEATURED BRANDS
export const mbrandData = [
    {
        id: 1,
        img: zaraMan,
        logo: zaralogo,
        brand: 'ZARA MAN',
        group: "Men",
    },
    {
        id: 2,
        img: gapMan,
        logo: gapLogo,
        brand: 'GAP MAN',
        group: "Men",
    },
    {
        id: 3,
        img: hmMan,
        logo: hmlogo,
        brand: 'HM MAN',
        group: "Men",
    },
    {
        id: 4,
        img: mangoMan,
        logo: mangologo,
        brand: 'MANGO MAN',
        group: "Men",
    },

]


///////////KIDS/////////////
//KIDS FEATURED BRANDS
export const kbrandData = [
    {
        id: 1,
        img: zaraKids,
        logo: zaralogo,
        brand: 'ZARA KIDS',
        group: "Kids",
    },
    {
        id: 2,
        img: juniors,
        logo: juniorLogo,
        brand: 'JUNIORS',
        group: "Kids",
    },
    {
        id: 3,
        img: gapKids,
        logo: gapLogo,
        brand: 'GAP KIDS',
        group: "Kids",
    },
    {
        id: 4,
        img: hmKids,
        logo: hmlogo,
        brand: 'HM KIDS',
        group: "Kids",
    },
   

]



///////////////////////////////// ALL BRANDS PAGE ///////////////////////
///////ALL WOMEN PAGE//////
export const allWbrands = [
    {
        id: 1, 
        image: tunjooBrand,
        name: 'TUNJOO.',
        brand: 'TUNJOO WOMAN',
    },
    {
        id: 2, 
        image: zaraBrand,
        name: 'ZARA',
        brand: 'ZARA WOMAN',
    },
    {
        id: 3, 
        image: hmBrand,
        name: 'H&M',
        brand: 'H&M WOMAN',
    },
    {
        id: 4, 
        image: aldoBrand,
        name: 'ALDO',
        brand: 'ALDO WOMAN',
    },
    {
        id: 5, 
        image: guessBrand,
        name: 'GUESS',
        brand: 'GUESS WOMAN',
    },
    {
        id: 6, 
        image: stevebrand,
        name: 'STEVE MADDEEN',
        brand: 'STEVE MADDEEN WOMAN',
    },
    {
        id: 7, 
        image: nineWestBrand,
        brand: 'NINE WEST',
        name: 'NINE WEST',
    },
    {
        id: 8, 
        image: mangoBrand,
        brand: 'MANGO WOMAN',
        name: 'MANGO',
    },
    {
        id: 9, 
        image: beverlyWoman,
        brand: 'BEVERLY HILLS POLO CLUB WOMAN',
        name: 'BEVERLY HILLS POLO CLUB',
    },
    {
        id: 10, 
        image: celeste,
        brand: 'CELESTE',
        name: 'CELESTE',
    },
]


//////////// ALL MEN BRANDS //////////
export const allBbrands = [
    {
        id: 1, 
        image: tunjooMan,
        name: 'TUNJOO.',
        brand: 'TUNJOO MAN'
    },
    {
        id: 2, 
        image: zaraMan,
        brand: 'ZARA MAN',
        name: 'ZARA'
    },
    {
        id: 3, 
        image: hmMan,
        name: 'H&M',
        brand: 'H&M MAN',
    },
    {
        id: 4, 
        image: tommyMan,
        name: 'TOMMY HILFIGURE',
        brand: 'TOMMY HILFIGURE',
    },
    {
        id: 5, 
        image: hugoMan,
        name: 'HUGO BOSS',
        brand: 'HUGO BOSS',
    },
    {
        id: 6, 
        image: gapMan,
        name: 'GAP',
        brand: 'GAP MAN',
    },
    {
        id: 7, 
        image: mangoMan,
        name: 'MANGO',
        brand: 'MANGO MAN',
    },
    {
        id: 8, 
        image: steveMan,
        name: 'STEVE MADDEN',
        brand: 'STEVE MADDEN MAN',
    },

    {
        id: 8, 
        image: americanMan,
        name: 'AMERICAN EAGLE',
        brand: 'AMERICAN EAGLE MAN',
    },
    {
        id: 9, 
        image: beverlyMan,
        brand: 'BEVERLY HILLS POLO CLUB MAN',
        name: 'BEVERLY HILLS POLO CLUB',
    },


]

//////////// ALL KID BRANDS //////////
export const allKbrands = [
    {
        id: 1, 
        image: tunjooKids,
        name: 'TUNJOO.',
        brand: 'TUNJOO KIDS',
    },
    {
        id: 2, 
        image: zaraKids,
        name: 'ZARA',
        brand: 'ZARA KIDS',
    },
    {
        id: 3, 
        image: hmKids,
        name: 'H&M',
        brand: 'H&M KIDS',
    },
    {
        id: 4, 
        image: gapKids,
        name: 'GAP',
        brand: 'GAP KIDS',
    },
    {
        id: 5, 
        image: juniors,
        name: 'JUNIORS',
        brand: 'JUNIORS',
    },
    {
        id: 6, 
        image: kappa,
        name: 'KAPPA',
        brand: 'KAPPA KIDS',
    },

]


//ALL BRAND SLIDE DATA
export const brandLogos = [
    {
        id: 1,
        logo: zaralogo,
    },
    // {
    //     id: 2,
    //     logo: splashlogo,
    // },
    {
        id: 3,
        logo: hmlogo,
    },
    {
        id: 4,
        logo: topshoplogo,
    },
    // {
    //     id: 5,
    //     logo: guesslogo,
    // },
    {
        id: 6,
        logo: aldologo,
    },
    // {
    //     id: 7,
    //     logo: newlooklogo,
    // },
    // {
    //     id: 8,
    //     logo: riverlogo,
    // },
    // {
    //     id: 9,
    //     logo: ellelogo,
    // },
    // {
    //     id: 10,
    //     logo: adiddaslogo,
    // },
    {
        id: 11,
        logo: ninelogo,
    },
    // {
    //     id: 12,
    //     logo: sketcherslogo,
    // },
    // {
    //     id: 13,
    //     logo: celestelogo,
    // },
    {
        id: 14,
        logo: pumalogo,
    },
    // {
    //     id: 15,
    //     logo: hadanalogo,
    // },
    {
        id: 16,
        logo: mangologo,
    },
    {
        id: 17,
        logo: stevelogo,
    },

]