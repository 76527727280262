import React, { useState } from 'react';
import newRequest from '../../config/newRequest';

const AccountInfo = () => {

    const currentUser = JSON.parse(localStorage.getItem("currentUser"));

    //EDIT / UPDATE USER
    const [member, setMember] = useState({
        firstname: currentUser.firstname,
        lastname: currentUser.lastname,
        email: currentUser.email,
        address: currentUser.address,
        phone: currentUser.phone,
        city: currentUser.city,
        country: currentUser.country,
        accessToken: currentUser.accessToken,
    });

    console.log(currentUser.accessToken)

    const handleChange = (e) => {
        setMember((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const updatedUser = {
                ...member
            };

            const res = await newRequest.put("/api/users/update/" + currentUser._id, updatedUser);
            localStorage.setItem("currentUser", JSON.stringify(res.data));
            window.location.reload();
        } catch (err) {
            return(err);
        }

    }


    return (
        <div className='accountInfoWrapper'>
            {/* {loading && <GeneralLoader/>} */}
            <div className="left">
                <div className="top">
                    <h3>Account Info</h3>
                    <h4>My Account Details.</h4>
                </div>
                <div className="bottom">
                    <div className="item">
                        <span>First Name:</span>
                        <p>{currentUser.firstname}</p>
                    </div>
                    <div className="item">
                        <span>Last Name:</span>
                        <p>{currentUser.lastname}</p>
                    </div>
                    <div className="item">
                        <span>Phone Number:</span>
                        <p>{currentUser.phone}</p>
                    </div>
                    <div className="item">
                        <span>Email:</span>
                        <p>{currentUser.email}</p>
                    </div>
                    <div className="item">
                        <span>Address:</span>
                        <p>{currentUser.address}</p>
                    </div>
                    <div className="item">
                        <span>City:</span>
                        <p>{currentUser.city}</p>
                    </div>
                    <div className="item">
                        <span>Country:</span>
                        <p>{currentUser.country}</p>
                    </div>

                </div>
            </div>

            <hr className='divider' />

            <div className="right">
                <div className="top">
                    <h3>Edit Info</h3>
                    <h4>Edit your information, login details and preferences.</h4>
                </div>
                <form className="bottom" onSubmit={handleSubmit}>
                    <div className="formInput">
                        <label>First Name:</label>
                        <input
                            type="text"
                            name="firstname"
                            value={member.firstname}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="formInput">
                        <label>Last Name:</label>
                        <input
                            type="text"
                            name="lastname"
                            value={member.lastname}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="formInput2">
                        <input
                            type="text"
                            name="email"
                            value={member.email}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="formInput">
                        <label>Phone Number:</label>
                        <input
                            type="text"
                            name="phone"
                            value={member.phone}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="formInput">
                        <label>City</label>
                        <input
                            type="text"
                            name="city"
                            value={member.city}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="formInput">
                        <label>Country</label>
                        <input
                            type="text"
                            name="country"
                            value={member.country}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="formInput">
                        <label>Street Address</label>
                        <input
                            type="text"
                            name="address"
                            value={member.address}
                            onChange={handleChange}
                        />
                    </div>

                    <button type='submit' className="formButton">Update</button>

                </form>

            </div>
        </div >
    )
}

export default AccountInfo