import React from 'react';
// import Button from '../../products/subComps/Button';
import Button from './Button';
import '../brand.scss';



const BrandCats = ({ group, handleClick }) => {
    return (

        <div className="brandCatsWrapper">
            {/* WOMEN */}
            {group === "Women" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="ALL PRODUCTS" />
                    <Button onClickHandler={handleClick} value="Women's Dresses and Jumpsuits" title="DRESSES & JUMPSUITS" />
                    <Button onClickHandler={handleClick} value="Women's Tops and Bodysuits" title="TOPS" />
                    <Button onClickHandler={handleClick} value="Women's Bottoms" title="BOTTOMS" />
                    <Button onClickHandler={handleClick} value="Women's Matching Sets" title="MATCHING SETS" />
                    <Button onClickHandler={handleClick} value="Women's Shirts and Tshirts" title="SHIRTS & TSHIRTS" />
                    <Button onClickHandler={handleClick} value="Women's Jackets and Blazers" title="JACKETS & BLAZERS" />
                    <Button onClickHandler={handleClick} value="Women's Shoes" title="SHOES" />
                    <Button onClickHandler={handleClick} value="Women's Accessories" title="ACCESSORIES" />
                    <Button onClickHandler={handleClick} value="Handbags" title="HANDBAGS" />
                </div>
            }
            {/* MEN */}
            {group === "Men" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="ALL PRODUCTS" />
                    <Button onClickHandler={handleClick} value="Tops" title="TOPS" />
                    <Button onClickHandler={handleClick} value="Bottoms" title="BOTTOMS" />
                    <Button onClickHandler={handleClick} value="Tracksuits" title="TRACKSUITS" />
                    <Button onClickHandler={handleClick} value="Shoes" title="SHOES" />
                    <Button onClickHandler={handleClick} value="Handbags" title="SPORTS" />
                    <Button onClickHandler={handleClick} value="Hoodies&Sweatshirts" title="HOODIES | SWEATSHIRTS" />
                </div>
            }

            {/* KIDS */}
            {group === "Kids" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="ALL PRODUCTS" />
                    <Button onClickHandler={handleClick} value="Dresses&Jumpsuits" title="DRESSES | JUMPSUITS" />
                    <Button onClickHandler={handleClick} value="BTops" title="BOYS' TOPS" />
                    <Button onClickHandler={handleClick} value="BBottoms" title="BOYS' BOTTOMS" />
                    <Button onClickHandler={handleClick} value="Shoes" title="SHOES" />
                    <Button onClickHandler={handleClick} value="GTops" title="GIRLS' TOPS" />
                    <Button onClickHandler={handleClick} value="GBottoms" title="GIRLS' BOTTOMS" />
                </div>
            }

        </div>


    );
}

export default BrandCats