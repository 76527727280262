import React, { useEffect, useState } from 'react';
import './accounthome.scss';
import { MdWest, MdCheck } from "react-icons/md";
import { Link, useLocation } from 'react-router-dom';
// import axios from 'axios';
import newRequest from '../../config/newRequest';
import moment from 'moment';



const SingleOrder = () => {

    const currentUser = JSON.parse(localStorage.getItem("currentUser"));

    const location = useLocation();
    const path = location.pathname.split("/")[3];
    const [order, setOrder] = useState({});

    //GET SINGLE ORDER
    useEffect(() => {
        const getOrder = async () => {
            try {
                const res = await newRequest.get(`/api/orders/single/${path}`);
                setOrder(res.data);
            } catch { }
        };
        getOrder();
    });

    console.log(order.address === "CURRENT" ? "CORRECT" : "WRONG")


    return (
        <div className='singleOrder'>

            <Link className='singleOrderlink' to={`/my-account/orders/${currentUser._id}`}>
                <div className="backWrapper">
                    <MdWest className='backIcon' />
                    <span>Back to Orders</span>
                </div>
            </Link>

            <div className="orderTop">
                <h3>Order #{order.transactionId}</h3>
                <div className="totalWrapper">
                    <h4>Order Total Amount:</h4>
                    <div className="totalAmt">
                        <p>UGX</p>
                        <span>{order?.orderAmount?.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</span>
                    </div>

                </div>
                <span>{moment(order.createdAt).format("DD MMM YYYY -  h:mm A")}</span>
            </div>

            <hr />

            <div className="orderDetailsWrapper">

                <div className="statusWrapper">
                    <span>Order Status</span>
                    <div className="bottom">
                        {order?.delivery_status === "Placed" &&
                            <>
                                <div className={`item ${order?.delivery_status}`}>
                                    <MdCheck className={`statusIcon ${order?.delivery_status}`} />
                                    <p className={`statusText ${order?.delivery_status}`}>Placed</p>
                                </div>

                                <div className="item">
                                    <MdCheck className="statusIcon" />
                                    <p className="statusText">Moving</p>
                                </div>
                                <div className="item">
                                    <MdCheck className="statusIcon" />
                                    <p className="statusText">Delivered</p>
                                </div>
                            </>
                        }

                        {order?.delivery_status === "Moving" &&
                            <>
                                <div className={`item ${order?.delivery_status}`}>
                                    <MdCheck className={`statusIcon ${order?.delivery_status}`} />
                                    <p className={`statusText ${order?.delivery_status}`}>Placed</p>
                                </div>

                                <div className={`item ${order?.delivery_status}`}>
                                    <MdCheck className={`statusIcon ${order?.delivery_status}`} />
                                    <p className={`statusText ${order?.delivery_status}`}>Moving</p>
                                </div>

                                <div className="item">
                                    <MdCheck className="statusIcon" />
                                    <p className="statusText">Delivered</p>
                                </div>
                            </>
                        }

                        {order?.delivery_status === "Delivered" &&
                            <>
                                <div className={`item ${order?.delivery_status}`}>
                                    <MdCheck className={`statusIcon ${order?.delivery_status}`} />
                                    <p className={`statusText ${order?.delivery_status}`}>Placed</p>
                                </div>

                                <div className={`item ${order?.delivery_status}`}>
                                    <MdCheck className={`statusIcon ${order?.delivery_status}`} />
                                    <p className={`statusText ${order?.delivery_status}`}>Moving</p>
                                </div>
                                <div className={`item ${order?.delivery_status}`}>
                                    <MdCheck className={`statusIcon ${order?.delivery_status}`} />
                                    <p className={`statusText ${order?.delivery_status}`}>Delivered</p>
                                </div>

                            </>

                        }

                    </div>
                </div>

                <div className="addressWrapper">
                    <div className="name">
                        <p>Shipped to:</p>
                        <span>{order?.name}</span>
                    </div>

                    {order.address === "CURRENT" ?
                        <div className="addDetails">
                            <p>{currentUser?.newAddres?.newStreet}</p>
                            <p>{currentUser?.newAddres?.newCity},{currentUser?.newAddres?.newCountry} </p>
                            <p>{currentUser?.phone}</p>
                        </div>
                        :
                        <div className="addDetails">
                            <p>{currentUser?.address}</p>
                            <p>{currentUser?.city},{currentUser?.country} </p>
                            <p>+{currentUser?.phone}</p>
                        </div>
                    }

                </div>
            </div>

            <hr />

            <div className="orderProducts">
                <div className="top">
                    <div className="item">
                        <span>Item</span>
                    </div>
                    <div className="desc">
                        <span>Description</span>
                    </div>
                    <div className="price">
                        <span>Total Price</span>
                    </div>
                </div>

                <hr />

                {order.products?.map((item, index) => (
                        <>
                    <div className="orderBottom" key={item._id}>
                        <div className="left">
                            <img className="orderImg" src={item?.image} alt="" />
                        </div>
                        <div className="center">
                            <h4>{item.productName}</h4>
                            <div className="centerItem">
                                <span>Color:</span>
                                <p>{item.color}</p>
                            </div>
                            <div className="centerItem">
                                <span>Size:</span>
                                <p>{item.productSize}</p>
                            </div>
                            <div className="centerItem">
                                <span>Qtty:</span>
                                <p>{item.productQuantity}</p>
                            </div>
                            <div className="centerItem">
                                <span>Price:</span>
                                <div className="priceItem">
                                    <span>UGX</span>
                                    <p>{item.productPrice}</p>
                                </div>
                            </div>

                        </div>
                        <div className="right">
                            <p>UGX</p>
                            <h4>{item.productTotal}</h4>

                        </div>
                    </div>
                    <hr className='productHr' />
                </>
                ))}

                

            </div>


        </div>
    )
}

export default SingleOrder