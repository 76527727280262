import React from 'react';
import './loader.scss';
import ReactDOM  from 'react-dom';

import { CircularProgress,Typography } from '@mui/material';

const GeneralLoader = () => {
    return ReactDOM.createPortal(
        <div className='loaderWrapper'>
            <div className="loader">
                <CircularProgress size='7rem' thickness='1.5' variant="indeterminate" sx={{ color: '#FFFFFF', fontSize: 20, fontWeight: 'medium' }}/>
                <Typography position='absolute' variant="h4" component="div" sx={{ color: '#FFFFFF', fontSize: 20, fontWeight: 'medium' }}>tunjoo.</Typography>
            </div>
        </div>,
        document.getElementById("loader")
    )
}

export default GeneralLoader