import React, { useEffect, useState } from 'react';
import './menNav.scss';
import { Link } from 'react-router-dom';
import MenList from './MenList';
import axios from 'axios';
import { MdSearch } from 'react-icons/md';
import belt from './belt.png';
import mtunjoo from './mtunjoo.png';
import mshoes from './mshoes.png';
import mclothes from './mclothes.png';
// import mtops from './mtops.png';
import mbottoms from './mbottoms.png';
import mbrands from './mbrands.png';


const MenNav = () => {

    // ----------- Input Filter -----------
    const [query, setQuery] = useState("");
    const handleInputChange = (event) => {
        setQuery(event.target.value);
    };

    // ----------- FETCH PRODUCTS FROM BACKEND -----------
    const [products, setProducts] = useState([]);

    useEffect(() => {
        const getProducts = async () => {
            try {
                const res = await axios.get("https://tunjoo-server-36b3be406a89.herokuapp.com/api/products");
                setProducts(res.data);
            } catch (err) { }
        };
        getProducts();
    }, []);


    const filteredItems = products.filter(
        (product) => product.productName?.toLowerCase().indexOf(query?.toLowerCase()) !== -1
    );

    const menNavItems = [
        {
            id: 1,
            sale: "LAUNCH SALE",
            group: "Men",
            cat: "Men's-Sale",
            image: mtunjoo,
            children: [
                {
                    id: 1,
                    title: 'All Clothing',
                    group: "Men",
                    cat: "Sale/Men's-Sale",
                    cName: 'dropdown-link3'
                },
            
            ]
        },
        {
            id: 2,
            title: "CLOTHING",
            group: "Men",
            itemCat: "Clothing",
            cat: "Men's-Clothing",
            image: mclothes,
            children: [
                {
                    id: 1,
                    group: "Men",
                    itemCat: "Clothing",
                    title: 'All Clothing',
                    cat: "Men's-Clothing",
                    cName: 'dropdown-link2'
                },
                {
                    id: 2,
                    group: "Men",
                    itemCat: "Clothing",
                    title: 'T-Shirts | Polo Shirts',
                    cat: "Men's-TShirts-and-Polo",
                    cName: 'dropdown-link'
                },
                {
                    id: 3,
                    group: "Men",
                    itemCat: "Clothing",
                    title: 'Sweaters | Cardigans',
                    cat: "Men's-Sweaters-and-Cardigans",
                    cName: 'dropdown-link'
                },
                {
                    id: 4,
                    group: "Men",
                    itemCat: "Clothing",
                    title: 'Shirts | Overshirts',
                    cat: "Men's-Shirts-and-Overshirts",
                    cName: 'dropdown-link',
                },
                {
                    id: 5,
                    group: "Men",
                    itemCat: "Clothing",
                    title: 'Hoodies | Sweatshirts',
                    cat: "Men's-Hoodies-and-Sweatshirts",
                    cName: 'dropdown-link',
                },
                {
                    id: 6,
                    group: "Men",
                    itemCat: "Clothing",
                    title: 'Bottoms',
                    cat: "Men's-Bottoms",
                    cName: 'dropdown-link'
                },
                {
                    id: 8,
                    group: "Men",
                    itemCat: "Clothing",
                    title: 'Matching Sets | Tracksuits',
                    cat: "Men's-Matchingsets-and-Tracksuits",
                    cName: 'dropdown-link'
                },
                {
                    id: 9,
                    group: "Men",
                    itemCat: "Clothing",
                    title: 'Jackets',
                    cat: "Men's-Jackets",
                    cName: 'dropdown-link'
                },

            ]
        },
        {
            id: 4,
            title: "BOTTOMS",
            group: "Men",
            itemCat: "Clothing",
            cat: "Men's-Bottoms",
            image: mbottoms,
            children: [
                {
                    id: 1,
                    group: "Men",
                    itemCat: "Clothing",
                    title: 'All Bottoms',
                    cat: "Men's-Bottoms",
                    cName: 'dropdown-link2'
                },
                {
                    id: 2,
                    group: "Men",
                    title: 'Trousers',
                    itemCat: "Clothing",
                    cat: "Men's-Trousers",
                    cName: 'dropdown-link'
                },
                {
                    id: 3,
                    group: "Men",
                    title: 'Shorts',
                    itemCat: "Clothing",
                    cat: "Men's-Shorts",
                    cName: 'dropdown-link'
                },
                
            ]
        },
        {
            id: 5,
            title: "SHOES",
            group: "Men",
            itemCat: "Shoes",
            cat: "Men's-Shoes",
            image: mshoes,
            children: [
                {
                    id: 1,
                    group: "Men",
                    itemCat: "Shoes",
                    title: 'All Shoes',
                    cat: "Men's-Shoes",
                    cName: 'dropdown-link2'
                },
                {
                    id: 2,
                    group: "Men",
                    title: 'Sneakers',
                    itemCat: "Shoes",
                    cat: "Men's-Sneakers",
                    cName: 'dropdown-link'
                },
                {
                    id: 3,
                    group: "Men",
                    itemCat: "Shoes",
                    title: 'Formal | Loafers',
                    cat: "Men's-Formal-Shoes",
                    cName: 'dropdown-link'
                },
                {
                    id: 4,
                    group: "Men",
                    title: 'Sandals',
                    itemCat: "Shoes",
                    cat: "Men's-Flat-Sandals",
                    cName: 'dropdown-link'
                },
                 
            ]
        },
        {
            id: 6,
            title: "ACCESSORIES",
            group: "Men",
            itemCat: "Accessories",
            cat: "Men's-Accessories",
            image: belt,
            children: [
                {
                    id: 1,
                    group: "Men",
                    itemCat: "Accessories",
                    title: 'All Accessories',
                    cat: "Men's-Accessories",
                    cName: 'dropdown-link2'
                },
                {
                    id: 2,
                    group: "Men",
                    title: 'Belts',
                    cat: "Men's-Belts",
                    itemCat: "Accessories",
                    cName: 'dropdown-link'
                },
                {
                    id: 3,
                    group: "Men",
                    title: 'Wallets',
                    cat: "Men's-Wallets",
                    itemCat: "Accessories",
                    cName: 'dropdown-link'
                },
                {
                    id: 4,
                    group: "Men",
                    title: 'Socks',
                    cat: "Men's-Socks",
                    itemCat: "Accessories",
                    cName: 'dropdown-link'
                },
                
                
            ]
        },
        {
            id: 7,
            title: "BRANDS",
            group: "Men",
            cat: "All-Brands",
            itemCat: "allbrands",
            image: mbrands,
            children: [
                {
                    id: 1,
                    group: "Men",
                    title: 'All Brands',
                    cat: 'All-Brands',
                    cName: 'dropdown-link2'
                },
                {
                    id: 2,
                    group: "Men",
                    title: 'Zara',
                    itemCat: "brands",
                    cat: 'ZARA-MAN',
                    cName: 'dropdown-link'
                },
                {
                    id: 3,
                    group: "Men",
                    title: 'H&M',
                    cat: 'H&M-MAN',
                    itemCat: "brands",
                    cName: 'dropdown-link'
                },
                {
                    id: 4,
                    group: "Men",
                    itemCat: "brands",
                    title: 'Tommy Hilfigure',
                    cat: 'TOMMY-HILFIGURE',
                    cName: 'dropdown-link'
                },
                {
                    id: 5,
                    group: "Men",
                    title: 'Hugo Boss',
                    cat: 'HUGO-BOSS',
                    itemCat: "brands",
                    cName: 'dropdown-link'
                },
                {
                    id: 6,
                    group: "Men",
                    itemCat: "brands",
                    title: 'Steve Madden',
                    cat: 'STEVE-MADDEN-MAN',
                    cName: 'dropdown-link'
                },
                {
                    id: 7,
                    group: "Men",
                    title: 'Gap',
                    cat: 'GAP-MAN',
                    itemCat: "brands",
                    cName: 'dropdown-link'
                },
                {
                    id: 8,
                    group: "Men",
                    title: 'Mango',
                    cat: 'MANGO-MAN',
                    itemCat: "brands",
                    cName: 'dropdown-link'
                },
                {
                    id: 9,
                    group: "Men",
                    itemCat: "brands",
                    title: 'American Eagle',
                    cat: 'AMERICAN-EAGLE-MAN',
                    cName: 'dropdown-link'
                },

            ]
        },

    ];


    return (

        <div className='menNav'>
            <div className="menNavLeft">

                <div className="navbar-links">
                    <ul className="navList">
                        {menNavItems.map((item) => (
                            <MenList key={item.id} item={item} />
                        ))}
                    </ul>
                </div>

            </div>

            <div className="menNavRight">
                <div className="searchWrapper">
                    <input
                        type="text"
                        value={query}
                        onChange={handleInputChange}
                        placeholder='Search Products...'
                        className='searchInput'
                    />
                    <div className="navSearchItem">
                        <MdSearch className='navSearchIcon' />
                    </div>

                    {query && (
                        <div className="navSearchOptions">
                            {filteredItems.map((item) => (
                                <div key={item._id}>
                                    <Link className='optionsLink' to={`/${item.group}/${item.mainCat}/${item._id}`}>
                                        <div className="searchItemWrapper" key={item._id}>
                                            <img src={item?.photos[0]} className='searchImg' alt='' />
                                            <div className="searchText">
                                                <span>{item.productName}</span>
                                                <p>in {item.group} - {item.mainCat}</p>
                                            </div>
                                        </div>

                                    </Link>
                                </div>


                            ))}

                        </div>
                    )}

                </div>
            </div>



        </div>

    )
}

export default MenNav