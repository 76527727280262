import React from 'react';
import './footer.scss';
import {
	FaInstagramSquare,
	FaFacebookSquare,
	FaTwitterSquare,
	FaRegCopyright
} from 'react-icons/fa';
import { Link } from 'react-router-dom';


const Footer = () => {

	return (
		<div className='footer'>

			<div className="paymentOptions">
				<div className='options'>
					<img src="/images/visa.png" alt="" className='paymentIcon' />
					<img src="/images/mastercard.png" alt="" className='paymentIcon' />
					<img src="/images/mtn.png" alt="" className='paymentIcon' />
					<img src="/images/airtel.png" alt="" className='airtelIcon' />
				</div>

				<div className="separator">
					<span>|</span>
				</div>

				<div className='socialOptions'>
					<FaFacebookSquare className='socialIcon' />
					<FaInstagramSquare className='socialIcon' />
					<FaTwitterSquare className='socialIcon' />
				</div>
			</div>

			<div className="linksWrapper">

				<div className="left">
					<h3>CATEGORIES</h3>
					<Link className='link'>
						<span>Women</span>
					</Link>
					<Link className='link'>
						<span>Men</span>
					</Link>
					<Link className='link'>
						<span>Kids</span>
					</Link>
				</div>

				<div className="center">
					<h3>ABOUT US</h3>
					<Link className='link'>
						<span>Company</span>
					</Link>
					<Link className='link'>
						<span>Contact us</span>
					</Link>
					<Link className='link'>
						<span>Terms of Use</span>
					</Link>
				</div>

				<div className="right">
					<h3>QUICK LINKS</h3>
					<Link className='link'>
						<span>Delivery</span>
					</Link>
					<Link className='link'>
						<span>Size Guide</span>
					</Link>
					<Link className='link'>
						<span>Return Policy</span>
					</Link>
				</div>

				<div className="farRight">
					<h3>MY ACCOUNT</h3>
					<Link className='link'>
						<span>Order History</span>
					</Link>
					<Link className='link'>
						<span>Track My Order</span>
					</Link>
					<Link className='link'>
						<span>My Wishlist</span>
					</Link>
				</div>

			</div>

			<div className="bottomWrapper">

				<div className="hrWrapper">
					<hr />
				</div>
				<div className="text">
					<FaRegCopyright style={{ color: "#282828", fontSize: 12, marginTop: '-3px' }} />
					<p>2023</p>
					<p>Tunjoo Era Limited</p>
				</div>
			</div>
		</div>
	)
}

export default Footer