import React from 'react';
import './kids.scss';
import KidsNav from '../../components/navbar/kids/KidsNav';
import { Link } from 'react-router-dom';
import kids from '../../assets/slider/kids1.png';
import { IoIosArrowRoundForward } from "react-icons/io";
import LogoSlide from '../../promos/LogoSlide';
import ResponseNav from '../../components/res-nav/ResponseNav';
import  { KidsFeaturedBrands } from '../../components/featured/FeaturedBrands';
import { TunjooKids } from '../../components/tunjooLaunch/TunjooLaunch';
import KidsToppicks from '../../components/topPicks/KidsToppicks';


const Kids = () => {
    return (
        <div className='kids'>
            <KidsNav />
            <ResponseNav/>

            <div className="mainImage">

                <div className="imgWrapper">
                    <img className='kids-image' src={kids} alt="" />
                </div>

                <div className="text">
                    <div className="textWrapper">
                        <span>BOLD & PLAYFUL STYLES</span>

                        <div className="heading">
                            <h2>THE ICONIC</h2>
                            <h3>TUNJOO KIDS</h3>
                        </div>

                        <div className='home-Buttons'>
                            <Link  className='kids-slide-link'>
                                <button className='homeBtn'>
                                    BOYS
                                    <IoIosArrowRoundForward className='kidsArrow' />
                                </button>
                            </Link>
                            <Link  className='kids-slide-link'>
                                <button className='homeBtn'>
                                    GIRLS
                                    <IoIosArrowRoundForward className='kidsArrow' />
                                </button>
                            </Link>
                        </div>

                    </div>

                </div>

            </div>

            <LogoSlide/>
            <KidsFeaturedBrands />
            <TunjooKids />
            <KidsToppicks />

            
        </div>
    )
}

export default Kids