// import Spinner from '../../components/loader/Spinner';
import './productlist.scss';


const Products = ({ result,pageContent  }) => {

    return (
        <>
            <div className="mainProducts">
                {/* <Spinner/> */}
                {/* {result} */}
                {pageContent}
            </div>
        </>
    );
};

export default Products;
