/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import './top-picks.scss';
import belt from './belt.png';
import shoe from './shoe.png';
import { Link } from 'react-router-dom';

const MenTopPicks = () => {

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    return (
        <div className='topPicks'>
             <Link to="/Men/Accessories/Men's-Accessories" style={{color: "inherit", textDecoration: "none"}} onClick={scrollToTop}>
                <div className="picksWrapper">
                    <div className="text">
                        <p>ACCESSORIES</p>
                        <button className='textBtn'>SHOP NOW</button>
                    </div>
                    <div className="image">
                        <img src={belt} alt="" />
                    </div>
                </div>
            </Link>

            <Link to="/Men/Shoes/Men's-Shoes" style={{color: "inherit", textDecoration: "none"}} onClick={scrollToTop}>
            <div className="picksWrapper">
                <div className="text">
                    <p>FOOTWEAR</p>
                    <button className='textBtn'>SHOP NOW</button>
                </div>
                <div className="image">
                    <img src={shoe} alt="" />
                </div>
            </div>
            </Link>

        </div>
    )
}

export default MenTopPicks