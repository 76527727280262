import React from 'react';
import './home.scss';
import woman from '../../assets/slider/boss4.png';
import WomenNav from '../../components/navbar/women/WomenNav';
// import { BsFlower1 } from 'react-icons/bs';
import FeaturedWomenProducts from '../../components/featured/FeaturedWomenProducts';
import FeaturedBrands from '../../components/featured/FeaturedBrands';
import LogoSlide from '../../promos/LogoSlide';
import { Link } from 'react-router-dom';
import ResponseNav from '../../components/res-nav/ResponseNav';
import TopPicks from '../../components/topPicks/TopPicks';
import TunjooLaunch from '../../components/tunjooLaunch/TunjooLaunch';


const Home = () => {

    // const location = useLocation();
    // const path = location.pathname.split("/")[1];

    return (
        <div className='home'>
            <ResponseNav/>
            <WomenNav />

            <div className="mainImage">

                <div className="imgWrapper">
                    <img className='image' src={woman} alt="" />
                </div>

                <div className="text">
                    <div className="textWrapper">
                        <span>REINVENT YOURSELF</span>

                        <div className="heading">
                            <h2>10+ GLOBAL</h2>
                            <h3>BRANDS</h3>
                        </div>

                        <Link to={`/Women/Clothing/Women's-Clothing`} className='slide-link'>
                            <button className='homeBtn'>SHOP NOW</button>
                        </Link>

                    </div>

                </div>

            </div>

            <LogoSlide />
            <FeaturedBrands />
            <TunjooLaunch/>
            <TopPicks />
            <FeaturedWomenProducts />

        </div>
    )
}

export default Home