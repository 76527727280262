import React, { useEffect, useState } from 'react';
import './featured.scss';
import { Link } from 'react-router-dom';
import ProductCard from '../card/ProductCard';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import axios from 'axios';
import { IoIosArrowRoundForward } from "react-icons/io";


const FeaturedWomenProducts = () => {

    const [trending, setTrending] = useState([]);

    useEffect(() => {
        const fetchProducts = async () => {
            const res = await axios.get("https://tunjoo-server-36b3be406a89.herokuapp.com/api/products/wtrending")
            setTrending(res.data)
        }
        fetchProducts();
    }, []);

    const productItems = trending.map((item) => {

        return (
            <div className="trendingCarouselItems">
                <ProductCard item={item} key={Math.random()} />
            </div>
        )
    });


    const responsive = {
        0: { items: 2, },
        576: { items: 3 },
        720: { items: 3 },
        1024: { items: 4 },
        1100: { items: 5 },
    };

    return (
        <div className='featuredProduct'>

            <div className="top">
                <h3>WEEKLY BEST SELLERS</h3>
                <Link  to="/Women/Women's-Clothing" className='slide-link'>
                    <span>SEE ALL</span>
                    < IoIosArrowRoundForward className='slide-Arrow'/>
                </Link>
            </div>

            <div className="carousel">
                <AliceCarousel
                    infinite
                    autoPlay
                    mouseTracking
                    items={productItems}
                    disableDotsControls
                    disableButtonsControls
                    responsive={responsive}
                    autoPlayInterval={2000}
                    animationDuration={1000}
                />
            </div>
        </div>
    )
}

export default FeaturedWomenProducts




export const FeaturedMenProducts = () => {
    const [trending, setTrending] = useState([]);

    useEffect(() => {
        const fetchProducts = async () => {
            const res = await axios.get("https://tunjoo-server-36b3be406a89.herokuapp.com/api/products/mtrending")
            setTrending(res.data)
        }
        fetchProducts();
    }, []);

    const productItems = trending.map((item) => {

        return (
            <div className="trendingCarouselItems">
                <ProductCard item={item} key={item._id} />
            </div>
        )
    });


    const responsive = {
        0: { items: 2, },
        576: { items: 3 },
        720: { items: 3 },
        1024: { items: 4 },
        1100: { items: 5 },
    };

    return (
        <div className='featuredProduct'>

            <div className="top">
                <h3>WEEKLY BEST SELLERS</h3>
                <Link  to="/Men/Men's-Clothing" className='slide-link'>
                    <span>SEE ALL</span>
                    < IoIosArrowRoundForward className='slide-Arrow'/>
                </Link>
            </div>

            <div className="carousel">
                <AliceCarousel
                    infinite
                    autoPlay
                    mouseTracking
                    items={productItems}
                    disableDotsControls
                    disableButtonsControls
                    responsive={responsive}
                    autoPlayInterval={2000}
                    animationDuration={1000}
                />
            </div>
        </div>
    )
}










// import React, { useEffect, useState } from 'react';
// import './featured.scss';
// import { Link } from 'react-router-dom';
// // import { wtrendingData } from '../../dummydata';
// import ProductCard from '../card/ProductCard';
// import axios from 'axios';


// const FeaturedProducts = () => {

//     const [trending, setTrending] = useState([]);
//     useEffect(() => {
//         const fetchProducts = async () => {
//             const res = await axios.get("https://tunjoo-server-36b3be406a89.herokuapp.com/api/products/wtrending")
//             setTrending(res.data)
//         }
//         fetchProducts();
//     }, []);

//     return (
//         <div className='featuredProduct'>

//             <div className="top">
//                 <h3>Trending this Week</h3>
//                 <Link className='link'>
//                     <span>See All</span>
//                 </Link>
//             </div>

//             <div className="bottom">
//                 {trending.map(item => (
//                     <ProductCard item={item} key={item.id}/>
//                 ))}
//             </div>
//         </div>
//     )
// }

// export default FeaturedProducts