import React from 'react';
import './policies.scss';

const Return = () => {
  return (
    <div className='return'>
        <div className="returnWrapper">
            
        </div>
    </div>
  )
}

export default Return