import React from 'react';
import './top-picks.scss';
import hoodie from './hoodie.png';
import sets from './sets.png';
import { Link } from 'react-router-dom';

const KidsToppicks = () => {

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }
    
    return (
        <div className='Kids-toppicks'>
            <Link style={{ color: "inherit", textDecoration: "none" }} onClick={scrollToTop}>
                <div className="picksWrapper">
                    <div className="text">
                        <p>BOY'S MATCHING SETS</p>
                        <button className='textBtn'>SHOP NOW</button>
                    </div>
                    <div className="image">
                        <img src={hoodie} alt="" />
                    </div>
                </div>
            </Link>

            <Link style={{ color: "inherit", textDecoration: "none" }} onClick={scrollToTop}>
                <div className="picksWrapper">
                    <div className="text">
                        <p>GIRL'S MATCHING SETS</p>
                        <button className='textBtn'>SHOP NOW</button>
                    </div>
                    <div className="image">
                        <img src={sets} alt="" />
                    </div>
                </div>
            </Link>

        </div>
    )

}

export default KidsToppicks