import React from 'react'
// import { Link } from 'react-router-dom'

const BrandItem = ({ item }) => {

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    return (
        <div className='brandItem' onClick={scrollToTop}>

            <img src={item.photos[0]} className='brandImage' alt="" />

            <div className="detailsWrapper">
                    <div className="titles">
                        <h3>{item.brand}</h3>
                        <span>{item.productName}</span>
                    </div>

                        {item?.discountPrice > 0 ?
                            <div className="discountWrapper">
                                <div className="discountItem2">
                                    <p>UGX</p>
                                    <span>{item?.discountPrice?.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</span>
                                </div>
                                <div className="discountItem1">
                                    <p>UGX</p>
                                    <span>{item?.sellingPrice?.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</span>
                                </div>
                            </div>

                            :
                            <div className="priceWrapper">
                                <span className='price1'>UGX</span>
                                <span className='price3'>{item?.sellingPrice?.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</span>
                            </div>

                        }


                </div>

            {/* <div className="textWrapper">

                <span>{item.productName}</span>

                {item?.discountPrice > 0 ?
                    <div className="discountWrapper">
                        <div className="discountItem2">
                            <p>UGX</p>
                            <span>{item?.discountPrice?.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</span>
                        </div>
                        <div className="discountItem1">
                            <p>UGX</p>
                            <span>{item?.sellingPrice?.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</span>
                        </div>
                    </div>

                    :
                    <div className="priceWrapper">
                        <span className='price1'>UGX</span>
                        <span className='price3'>{item?.sellingPrice?.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</span>
                    </div>

                }
            </div> */}

        </div>
    )
}

export default BrandItem

