import React from 'react';
import './card.scss';
import { Link } from 'react-router-dom'

const BrandCard = ({ item }) => {

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }


    return (
        <Link className="link" to={`/${item.group}/brands/${item.brand}`} onClick={scrollToTop}>
            <div className='brandCard'>
                <div className="image">
                    <img src={item.img} alt="" className='mainImg' />
                </div>
                <div className="logo">
                    <img src={item.logo} alt="" className='logoImg' />
                </div>
            </div>
        </Link>
    )
}

export default BrandCard