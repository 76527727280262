import './app.scss';
import Home from './pages/home/Home';
import Men from './pages/men/Men';
import Kids from './pages/kids/Kids';
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import MainProductList from './pages/products/MainProductList';
import SingleProduct from './pages/single/SingleProduct';
import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';
// import Cart from './cart/Cart';
import Promo from './promos/Promo';
import Return from './components/policies/Return';
import Login from './pages/forms/Login';
import Register from "./pages/forms/Register";
import ResetPassword from './pages/forms/passwords/ResetPassword';
import ForgotPassword from './pages/forms/passwords/ForgotPassword';

//account
import AccountInfo from './components/account/AccountInfo';
import AdminOrders from './admin/adminComps/AdminOrders';
import AdminWishlist from './admin/adminComps/AdminWishlist';
import AdminProducts from './admin/adminComps/AdminProducts';
import Users from './admin/adminComps/Users';
import Navigation from './admin/navigation/Navigation';
import Dashboard from './admin/adminComps/Dashboard';
import AdminTransactions from './admin/adminComps/AdminTransactions';
import AdminSearch from './admin/AdminSearch';
import AccountSidebar from './components/account/AccountSidebar';
import WishList from './components/account/WishList';
import Wallet from './components/account/Wallet';
// import TrackOrders from './components/account/TrackOrders';
import AccountOrders from './components/account/AccountOrders';
import TermsConditions from './components/policies/TermsConditions';
import PrivacyPolicy from './components/policies/PrivacyPolicy';
import SingleOrder from './components/account/SingleOrder';


//TOAST
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NotFound from './components/notFound/NotFound';
import QuickCheckout from './components/shipping/QuickCheckout';
import UserCart from './cart/UserCart';
import Response from './payment-response/Response';
import AllBrands from './pages/brand/AllBrands';
import BrandsMainPage from './pages/brand/BrandsMainPage';
import MainSale from './pages/sale/MainSale';
import SingleAdminProduct from './admin/adminComps/SingleAdminProduct';




const Layout = () => {
    return (
        <div className='app'>
            <Promo />
            <div>
                <Navbar />
                <Outlet />
                <Footer />
            </div>

        </div>
    )
}

const Layout2 = () => {

    return (
        <div className='adminAccount'>
            <Navbar />
            <span className='adminHr' />
            <div className='adminWrapper'>
                <Navigation/>
                <div className='adminDetailsWrapper'>
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

const Layout3 = () => {
    return (
        <div className='customerAccount'>
            <Promo />
            <Navbar />
            <span className='accountHr' />
            <div className='accWrapper'>
                <AccountSidebar />
                <div className='accountDetailsWrapper'>
                    <Outlet />
                </div>
            </div>
        </div>
    )
}


const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout />,
        children: [
            {
                path: "/",
                element: <Home />
            },
            {
                path: "/Men",
                element: <Men />
            },
            {
                path: "/Kids",
                element: <Kids />
            },
            {
                path: "/:group/:itemCat/:category",
                element: <MainProductList />
            },
            {
                path: "/:group/brands/:brand",
                element: <BrandsMainPage />
            },
            {
                path: "/:group/Sale/:cat",
                element: <MainSale />
            },
            {
                path: "/:group/products/:mainCat/:id",
                element: <SingleProduct />
            },
            {
                path: "/:group/:brand/All-Brands",
                element: <AllBrands />
            },
            {
                path: "/cart",
                element: <UserCart />
            },
            {
                path: "/tunjoo-return-policy",
                element: <Return />
            },
            {
                path: "/login",
                element: <Login />
            },
            {
                path: "/register",
                element: <Register />
            },
            {
                path: "/forgot-password",
                element: <ForgotPassword />
            },
            {
                path: "/reset-password/:resetToken",
                element: <ResetPassword />
            },
            {
                path: "/privacy-policy",
                element: <PrivacyPolicy />
            },
            {
                path: "/terms-and-conditions",
                element: <TermsConditions />
            },
            {
                path:"/response",
                element: <Response />,
            },


        ]
    },
    {
        path: "/admin",
        element: <Layout2 />,
        children: [
            {
                path: "/admin/dashboard",
                element: <Dashboard />
            },
            {
                path: "/admin/users",
                element: <Users />
            },
            {
                path: "/admin/products",
                element: <AdminProducts />
            },
            {
                path: "/admin/wishlist",
                element: <AdminWishlist />
            },
            {
                path: "/admin/orders",
                element: <AdminOrders />
            },
            {
                path: "/admin/transactions",
                element: <AdminTransactions />
            },
            {
                path: "/admin/search",
                element: <AdminSearch />
            },
            {
                path: "/admin/:id",
                element: <SingleAdminProduct />
            },

        ]
    },
    {
        path: "*",
        element: <NotFound />
    },
    {
        path: "/quickCheckout",
        element: <QuickCheckout/>
    },
    {
        path: "/my-account",
        element: <Layout3 />,
        children: [
            {
                path: "/my-account/info/:id",
                element: <AccountInfo />
            },
            {
                path: "/my-account/wishlist/:id",
                element: <WishList />
            },
            {
                path: "/my-account/wallet/:id",
                element: <Wallet />
            },
            // {
            //     path: "/my-account/track-my-order/:id",
            //     element: <TrackOrders />
            // },
            {
                path: "/my-account/orders/:id",
                element: <AccountOrders />
            },
            {
                path: "/my-account/singleOrder/:orderId",
                element: <SingleOrder/>
            }


        ]
    },
  

])

const App = () => {

    return (
        <div className='container' id="container">
            <ToastContainer
                position="top-center"
                autoClose={4000}
                style={{ width: "430px", fontSize: "14px" }}
            />
            <RouterProvider router={router} />

        </div>
    )
}

export default App