import React from 'react';
import './adminComps.scss';

const Users = () => {
    return (
        <div className='adminUsers'>
            Users
        </div>
    )
}

export default Users