import React from 'react';
import './card.scss';
import { Link } from 'react-router-dom';


const ProductCard = ({ item }) => {

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    return (
        <Link className="link" to={`/${item.group}/products/${item.category[0].replace(/\s+/g, '-')}/${item._id}`} onClick={scrollToTop}>
            <div className='productCard'>

                <div className="trendingImage">
                    <img src={item.photos[0]} alt="" className='trendingImg' />
                </div>

                <div className="titles">
                    <h3>{item.brand}</h3>
                    <span>{item.productName}</span>
                </div>

                {item?.discountPrice > 0 ?
                    <div className="discountWrapper">
                        <div className="discountItem2">
                            <p>UGX</p>
                            <span>{item?.discountPrice?.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</span>
                        </div>
                        <div className="discountItem1">
                            <p>UGX</p>
                            <span>{item?.sellingPrice?.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</span>
                        </div>
                    </div>

                    :
                    <div className="priceWrapper">
                        <span className='price1'>UGX</span>
                        <span className='price3'>{item?.sellingPrice?.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</span>
                    </div>

                }



            </div>
        </Link>
    )
}

export default ProductCard