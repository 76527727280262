import React from 'react';
import './adminComps.scss';
import CurrentChart from '../charts/currentchart/CurrentChart';
// import PastChart from '../charts/pastchart/PastChart';

//icons
// import { MdSearch } from 'react-icons/md';
import { GiCash } from "react-icons/gi";
import { GiTakeMyMoney } from "react-icons/gi";
import { TiShoppingCart } from "react-icons/ti";
import { FaCartArrowDown } from "react-icons/fa6";


const Dashboard = () => {
    return (
        <div className='dashboard'>

            <div className="topDashWrapper">

                <div className="dashItem1">
                    <h2>Revenue</h2>
                    <div className="dashItemWrapper">
                        <div className="amount">
                            <span>UGX</span>
                            <h2>300,000</h2>
                        </div>
                        <GiCash className='dashIcon1'/>
                    </div>
                </div>

                <div className="dashItem2">
                    <h2>Profits</h2>
                    <div className="dashItemWrapper">
                        <div className="amount">
                            <span>UGX</span>
                            <h2>300,000</h2>
                        </div>
                        <GiTakeMyMoney className='dashIcon2'/>
                    </div>
                </div>

                <div className="dashItem3">
                    <h2>Products</h2>
                    <div className="dashItemWrapper">
                        <h2>300</h2>
                        <TiShoppingCart className='dashIcon3'/>
                    </div>
                </div>

                <div className="dashItem4">
                    <h2>Orders</h2>
                    <div className="dashItemWrapper">
                        <h2>300</h2>
                        <FaCartArrowDown className='dashIcon4'/>
                    </div>
                </div>

            </div>

            <div className="bottomDashWrapper">
           
                <CurrentChart/>
                {/* <PastChart/> */}
            </div>
        </div>
    )
}

export default Dashboard