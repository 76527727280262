import React, { useState } from 'react';
import './kidsNav.scss';
import './dropdown.scss';
import { Link } from 'react-router-dom';

const KidsList = ({ item }) => {

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    const [dropdown, setDropdown] = useState(false);


    return (
        <li
            className="navItem"
            onMouseEnter={() => setDropdown(true)}
            onMouseLeave={() => setDropdown(false)}
        >
            <Link to={`/Kids/${item.itemCat}/${item.cat}`} className='link' onClick={scrollToTop}>
                <p style={{ color: '#b51f29' }}>{item.sale}</p>
                <span>{item.title}</span>
            </Link>

            {dropdown && item.children && (
                // <div className="dropdown-Overlay">
                    <div className="dropdownWrapper">
                        <ul className="dropdown-menu">
                            {item.children.map((dropDownItem) => (
                                <li key={dropDownItem.id} onClick={scrollToTop}>
                                    <Link
                                        className={dropDownItem.cName}
                                        to={`/Kids/${dropDownItem.itemCat}/${dropDownItem.cat}`}
                                        onClick={() => setDropdown(!dropdown)}
                                    >
                                        <span>{dropDownItem.title}</span>
                                    </Link>

                                </li>

                            ))}

                        </ul>
                        <img src={item.image} alt="" className='dropDownImg' />
                    </div>
                // </div>
            )}

        </li>
    )
}

export default KidsList