import React, { useState } from 'react';
import "./login.scss";
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import WomenNav from '../../components/navbar/women/WomenNav';
// import { useSelector } from 'react-redux';
// import { publicRequest } from "../../requestMethod";
// import GeneralLoader from '../../components/loader/GeneralLoader';
// import { login } from '../../redux/apiCalls';
// import newRequest from '../../config/newRequest';
// import { loginFailure, loginStart, loginSuccess } from '../../redux/userSlice';

// import { cartTotalSelector } from '../../redux/cartSlice';
// import { cartQuantitySelector} from '../../redux/cartSlice';

import ResponseNav from '../../components/res-nav/ResponseNav';


function Login() {

    // const isLoading = useSelector((state) => state.user.isLoading);
    // console.log(error)
    // const { currentUser } = useSelector((state) => state.user);
    // const currentUser = JSON.parse(localStorage.getItem("currentUser"));

    // ----------- show and hide password -----------
    const [isReveal, setIsReveal] = useState(false);
    const togglePassword = () => {
        setIsReveal(!isReveal);
    }

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    // ----------- API CALLS -----------
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    // const { isFetching, error } = useSelector((state) => state.user);
    const navigate = useNavigate();

    // const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    // const cartItems = useSelector((state) => state.cart.cartItems);
    const cartItems = JSON.parse(localStorage.getItem("cartItems"));
    // const cartTotal = useSelector(cartTotalSelector);
    // const cartQuantity = useSelector(cartQuantitySelector);

    

    const subTotal = () => {
        let total = 0;
        cartItems?.forEach((item) => {
            total += item.quantity * (item.discountPrice ? item.discountPrice : item.sellingPrice);
        });
        return total
    };
    const productTotal = subTotal();
    console.log(productTotal)



    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            // const productDetails = cartItems?.map(item => ({
            //     productId: item._id,
            //     itemId: item._id + item.size,
            //     productQuantity: item.quantity,
            //     productSize: item.size,
            //     productPrice: item.discountPrice ? item.discountPrice : item.sellingPrice,
            //     productTotal: (item.discountPrice ? item.discountPrice : item.sellingPrice) * item.quantity,
            //     productName: item.productName,
            //     brand: item.brand,
            //     color: item.color,
            //     image: item.photos[0]
            // }));

            const res = await axios.post("https://tunjoo-server-36b3be406a89.herokuapp.com/api/auth/login",
                // { email, password, productDetails, cartTotal, cartQuantity, },
                // { email, password, productDetails, productTotal, cartQuantity, },
                { email, password},
                { withCredentials: true }
            );
            localStorage.setItem("currentUser", JSON.stringify(res.data));
            navigate("/cart");

        } catch (err) {
            setError(err)
        }
    };




    function refreshPage() {
        window.location.reload();
    }


    return (
        <>
            {/* {isLoading && <GeneralLoader/>} */}

            <div className='login'>

                <WomenNav />
                <ResponseNav/>

                <div className="loginWrapper">


                    {/* <div className='loginForm'> */}

                    <div className="top">
                        <h3>WELCOME BACK!</h3>
                    </div>

                    <form className='loginFormWrapper' onClick={handleLogin}>

                        <div className="inputItem">
                            <span>Email Address*</span>
                            <input
                                placeholder='Email'
                                type="email"
                                required
                                name="email"
                                className='emailInput'
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>

                        <div className="inputItem">
                            <span>Password</span>

                            <div className="password">
                                <input
                                    placeholder='Password'
                                    type={isReveal ? "text" : "password"}
                                    minLength="6"
                                    required
                                    name="password"
                                    className='passwordInput'
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <label onClick={togglePassword}>{isReveal ? "Hide" : "Show"}</label>
                            </div>
                        </div>

                        <Link to="/forgot-password" className='link'>
                            <div className="forgotPassword">
                                <span>Forgot Password?</span>
                            </div>
                        </Link>

                        {/* <div className="loginWrapper" onClick={scrollToTop}> */}
                        <button type='submit'  className='loginBtn' onClick={scrollToTop}>
                            <p>LOGIN</p>
                        </button>
                        {/* </div> */}

                        {error === true &&
                            <div className='errorWrapper'>
                                <span className='error'>Email or Password is wrong!!</span>
                                <p onClick={refreshPage} className='reload'>TRY AGAIN</p>
                            </div>
                        }

                        <div className="loginFooter">
                            <p>Already have an Account? <Link className="loginRegisterLink" to="/register" onClick={scrollToTop}>REGISTER</Link></p>
                            <span>By providing your details, you agree with our <Link className="loginLink" to="/privacy-policy"> Privacy Policy</Link> and <Link className="registerLink" to="/terms-and-conditions"> Terms and Conditions. </Link>
                                You agree to be contacted by Tunjoo by email, text and post.
                                We will treat your data with care and respect and never sell them to other
                                companies for marketing..
                            </span>
                        </div>

                    </form>

                    {/* </div> */}


                </div>

            </div>
        </>
    )
}

export default Login


