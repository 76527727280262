import React, { useState } from 'react';
import './response.scss';
// import newRequest from '../config/newRequest';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
// import { MdGppGood } from 'react-icons/md';
import { MdVerified } from "react-icons/md";
import moment from 'moment';
import axios from 'axios';
// import axios from 'axios';
import { MdKeyboardArrowRight } from 'react-icons/md';

const Response = () => {

    const currentUser = JSON.parse(localStorage.getItem("currentUser"));

    const [response, setResponse] = useState();
    const [error, setError] = useState();

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get('transaction_id')

    useEffect(() => {
        const fetchResponse = async (e) => {
            try {
                const res = await axios.get(`https://tunjoo-server-36b3be406a89.herokuapp.com/api/orders/response?transaction_id=${id}`);
                setResponse(res.data);
            } catch (error) {
                setError(error.response.data)
            }
        }
        fetchResponse();
    });





    return (
        <div className='response'>

            {/* {error &&
                <div className="errorWrapper">
                    <span>Transaction Already Exist</span>
                    <Link className='link' to={`/my-account/my-orders/${currentUser._id}`}>
                        <div className='resBtn'>Go to Account</div>
                    </Link>
                </div>
            } */}
            {/* {error &&
                <div className="errorWrapper">
                    <span>{error}</span>
                    <Link className='link' to={`/my-account/orders/${currentUser._id}`}>
                        <div className='resBtn'>
                            View Order Status
                            <MdKeyboardArrowRight className='resArrow'/>
                        </div>
                    </Link>
                </div>
            } */}


            {response ?
                <div className="responseData">

                    <div className="responseStats">
                        <MdVerified className="icon" />
                        <h2>{response.customer.name},</h2>
                        <h5>{response.response}!!</h5>
                        <span>Thank you for your purchase!</span>
                    </div>

                    <hr />

                    <div className="bottomResponse">
                        <div className="left">
                            <p>Transaction Ref</p>
                            <h4>{response.tx_ref}</h4>
                            <p>{moment(response.created_at).format("Do MMM YYYY, h:mm a")}</p>
                        </div>

                        <div className="right">
                            <p>Payment Type</p>
                            <h4>{response.payment_type}</h4>
                            <p>{response.status}</p>
                        </div>

                    </div>

                    {/* <Link className="link" to={`/account/${currentUser.username}`}> */}
                    <Link className="link" to={`/my-account/orders/${currentUser._id}`}>
                        <span className='accountBtn'>
                            View Order Status
                            <MdKeyboardArrowRight className='resArrow' />
                        </span>
                    </Link>


                </div>

                :

                <div className="errorWrapper">
                    <span>{error}</span>
                    <Link className='link' to={`/my-account/orders/${currentUser._id}`}>
                        <div className='resBtn'>
                            View Order Status
                            <MdKeyboardArrowRight className='resArrow' />
                        </div>
                    </Link>
                </div>
            }
        </div>
    )
}

export default Response













// import React, { useState } from 'react';
// import './response.scss';
// import newRequest from '../config/newRequest';
// import { useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import { MdGppGood } from 'react-icons/md';
// import moment from 'moment';
// import axios from 'axios';
// // import axios from 'axios';
// import { MdKeyboardArrowRight } from 'react-icons/md';

// const Response = () => {

//     const currentUser = JSON.parse(localStorage.getItem("currentUser"));

//     const [response, setResponse] = useState();
//     const [error, setError] = useState();

//     const queryString = window.location.search;
//     const urlParams = new URLSearchParams(queryString);
//     const id = urlParams.get('transaction_id')

//     useEffect(() => {
//         const fetchResponse = async (e) => {
//             try {
//                 const res = await axios.get(`https://tunjoo-server-36b3be406a89.herokuapp.com/api/orders/response?transaction_id=${id}`);
//                 setResponse(res.data);
//             } catch (error) {
//                 setError(error.response.data)
//             }
//         }
//         fetchResponse();
//     });

    

   

//     return (
//         <div className='response'>

//             {/* {error &&
//                 <div className="errorWrapper">
//                     <span>Transaction Already Exist</span>
//                     <Link className='link' to={`/my-account/my-orders/${currentUser._id}`}>
//                         <div className='resBtn'>Go to Account</div>
//                     </Link>
//                 </div>
//             } */}
//             {error &&
//                 <div className="errorWrapper">
//                     <span>{error}</span>
//                     <Link className='link' to={`/my-account/orders/${currentUser._id}`}>
//                         <div className='resBtn'>
//                             View Order Status
//                             <MdKeyboardArrowRight className='resArrow'/>
//                         </div>
//                     </Link>
//                 </div>
//             }


//             {response && (
//                 <div className="responseData">
//                     <div className="user">
//                         <img src={currentUser.profile} alt="" />
//                         {/* <h3>OrderID {response.order}</h3> */}
//                         <h3>{response.customer.name}</h3>
//                         <span>{response.customer.email}</span>
//                         <span>{response.customer.phone_number}</span>
//                         <span>{response.customer.address}</span>
//                     </div>

//                     <div className="responseStats">
//                         <MdGppGood className="icon" />
//                         <h2>{response.amount} ugx</h2>
//                         <h5>{response.response}!!</h5>
//                     </div>

//                     <hr />

//                     <div className="bottomResponse">
//                         <div className="left">
//                             <p>Transaction Ref</p>
//                             <h4>{response.tx_ref}</h4>
//                             <p>{moment(response.created_at).format("Do MMM YYYY, h:mm a")}</p>
//                         </div>

//                         <div className="right">
//                             <p>Payment Type</p>
//                             <h4>{response.payment_type}</h4>
//                             <p>{response.status}</p>
//                         </div>

//                     </div>

//                     {/* <Link className="link" to={`/account/${currentUser.username}`}> */}
//                     <Link className="link" to="/my-account/info">
//                         <span className='accountBtn'>
//                             View Order Status
//                             <MdKeyboardArrowRight className='resArrow'/>
//                         </span>
//                     </Link>


//                 </div>
//             )}
//         </div>
//     )
// }

// export default Response