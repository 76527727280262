import React, { useEffect, useState } from 'react';
import './accounthome.scss';
import newRequest from '../../config/newRequest';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import moment from 'moment';
import { Link } from 'react-router-dom';



const AccountOrders = () => {

    const currentUser = JSON.parse(localStorage.getItem("currentUser"));

    const [orders, setOrders] = useState([]);

    //GET WALLET TRANSACTIONS
    useEffect(() => {
        const getOrders = async () => {
            try {
                const res = await newRequest.get("/api/orders/find/" + currentUser._id);
                setOrders(
                    res.data.sort((p1, p2) => {
                        return new Date(p2.createdAt) - new Date(p1.createdAt);
                    })
                );
            } catch { }
        };
        getOrders();
    });

    //PAGINATION
    const [pageSize, setPageSize] = useState(5);
    const [page, setPage] = useState(1);
    const handlePage = (page) => setPage(page);
    const handlePageSizeChange = (event) => {
        setPageSize(event.target.value);
    };
    const totalPages = Math.ceil(orders.length / pageSize);
    const pageContent = orders.slice((page - 1) * pageSize, page * pageSize);


    return (
        <div className='accountOrders'>
            <h3>Your Order History</h3>

            <TableContainer component={Paper} className='table'>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className='tableCellTitle'>S/N</TableCell>
                            <TableCell className='tableCellTitle'>Date</TableCell>
                            <TableCell className='tableCellTitle'>Order Number</TableCell>
                            <TableCell className='tableCellTitle'>Payment Method</TableCell>
                            <TableCell className='tableCellTitle'>Amount</TableCell>
                            <TableCell className='tableCellTitle'>Status</TableCell>
                            <TableCell className='tableCellTitle'>Action</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody name="page-size" id="page-size" onChange={handlePageSizeChange}>
                        {pageContent.map((order, index) => (
                            <TableRow key={order._id}>
                                <TableCell className='tableCell'>
                                    <img src={order.products[0]?.image} alt="" className='orderImg'/>
                                </TableCell>
                                <TableCell className='tableCell'>{moment(order.createdAt).format('DD / MM / YYYY')}</TableCell>
                                <TableCell className='tableCell'>{order.tx_ref}</TableCell>
                                <TableCell className='tableCell'>
                                    <span className={`payment_type ${order.paymentMethod}`}>{order.paymentMethod}</span>
                                </TableCell>

                                <TableCell className='tableCell'>
                                    {order.orderAmount?.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}
                                </TableCell>

                                <TableCell className='tableCell'>
                                    <span className={`status ${order?.delivery_status}`}>{order?.delivery_status}</span>
                                </TableCell>

                                <TableCell className='tableCell'>
                                    <Link to={`/my-account/singleOrder/${order._id}`} style={{ textDecoration: "none", color: 'darkgreen' }}>
                                        <div className="viewButton">View</div>
                                    </Link>
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>

                </Table>

                <div className="pagination">
                    <Pagination
                        className='pagination'
                        count={totalPages}
                        onChange={(event, value) => handlePage(value)}
                        page={page}
                        size="large"
                    ></Pagination>
                </div>

            </TableContainer>

        </div>
    )
}

export default AccountOrders