import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
    products: localStorage.getItem("products") ? JSON.parse(localStorage.getItem("products")) : [],
    cartItems: [],
    cartTotalQuantity: 0,
    cartTotalAmount: 0,
};

export const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        addToCart(state, action) {
            const newItem = { ...action.payload, quantity: action.payload.quantity };
            const existingIndex = state.cartItems.findIndex(
                (item) => item._id === action.payload._id && item.size === action.payload.size
            );

            if (existingIndex !== -1) {
                state.cartItems[existingIndex] = {
                    ...state.cartItems[existingIndex],
                    quantity: state.cartItems[existingIndex].quantity + action.payload.quantity,
                };
                toast.info("Product Quantity Successfully Added To Your Cart!");
            } else {
                state.cartItems.push(newItem);
                toast.success(`${newItem.productName} Successfully Added To Your Cart!`)
            }
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
        },
        removeItem: (state, action) => {

            state.cartItems = state.cartItems.filter((item) => (item._id + item.size) !== action.payload);
            // update localstorage ofcart after removing
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
        },

        increaseItem: (state, action) => {

            const existingItem = state.cartItems.findIndex(
                (item) => (item._id + item.size) === action.payload
            );

            if (existingItem !== -1) {
                state.cartItems[existingItem] = {
                    ...state.cartItems[existingItem],
                    quantity: state.cartItems[existingItem].quantity + 1,
                };
            }
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems));

        },

        decreaseItem: (state, action) => {

            const existingItem = state.cartItems.findIndex(
                (item) => (item._id + item.size) === action.payload
            );

            if (state.cartItems[existingItem].quantity > 1) {
                state.cartItems[existingItem] = {
                    ...state.cartItems[existingItem],
                    quantity: state.cartItems[existingItem].quantity - 1,
                };
            }

            localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
        },
        
        // getCartTotalAmount(state, action) {
        //     //state the price parameter
        //     let { total, cartQuantity } = state.cartItems.reduce(
        //         (cartTotal, cartItem) => {
        //             const { price, quantity } = cartItem;
        //             const itemTotal = price * quantity; // total for this particular cartItem 

        //             cartTotal.total += itemTotal; //add cartItemTotal to the overall total
        //             cartTotal.cartQuantity += quantity; //add cartItem's quantity to the overall quantity

        //             return cartTotal;
        //         },
        //         //initial value
        //         {
        //             total: 0,
        //             cartQuantity: 0,
        //         }
        //     );
        //     total = parseFloat(total.toFixed(2));
        //     state.cartTotalQuantity = cartQuantity;
        //     state.cartTotalAmount = total;
        // },

        clearCart(state, action) {
            state.cartItems = [];
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
            toast.error("Cart cleared");
        },

    },
});

export const cartTotalSelector = (state) => state.cart.cartItems.reduce(
    (cartTotalAmount, item) => cartTotalAmount + item.sellingPrice * item.quantity,
    0
);
export const cartQuantitySelector = (state) => state.cart.cartItems.reduce(
    (quantity, item) => quantity + item.quantity,
    0
);

// Action creators are generated for each case reducer function
export const { addToCart, removeItem, resetCart, increaseItem, decreaseItem, getCartTotalAmount } = cartSlice.actions;
export default cartSlice.reducer;



// addToCart: (state, action) => {
//     const newItem = { ...action.payload, quantity: action.payload.quantity };
//     const existingItemIndex = state.cartItems.findIndex(
//         (item) => item._id === action.payload._id && item.size === action.payload.size
//     );
//     if (existingItemIndex !== -1) {
//         // Product with the same specifications is already in the cart, increment quantity
//         const updatedCart = state.cartItems.map((item, index) =>
//             index === existingItemIndex ? { ...item, quantity: item.quantity + 1 } : item
//         );

//         return { ...state, cartItems: updatedCart };
//     } else {
//         // Product with different specifications or not in the cart, add a new item
//         return { ...state, cartItems: [...state.cartItems, newItem] };

//     }
// },











// import { createSlice } from "@reduxjs/toolkit";

// const initialState = {
//     // products: [],
//     products: localStorage.getItem("products") ? JSON.parse(localStorage.getItem("products")) : [],
//     quantity: 0,
//     total: 0,
// };

// export const cartSlice = createSlice({
//     name: "cart",
//     initialState,
//     reducers: {

//         addToCart: (state, action) => {
//             const newItem = { ...action.payload, quantity: action.payload.quantity };
//             const existingItemIndex = state.products.findIndex(
//                 (item) => item._id === action.payload._id && item.size === action.payload.size
//             );
//             if (existingItemIndex !== -1) {
//                 // Product with the same specifications is already in the cart, increment quantity
//                 const updatedCart = state.products.map((item, index) =>
//                     index === existingItemIndex ? { ...item, quantity: item.quantity + 1 } : item
//                 );

//                 return { ...state, products: updatedCart };
//             } else {
//                 // Product with different specifications or not in the cart, add a new item
//                 return { ...state, products: [...state.products, newItem] };
//             }


//         },
//         removeItem: (state, action) => {

//             state.products = state.products.filter((item) => (item._id + item.size) !== action.payload);
            
//             // update localstorage ofcart after removing
//             // localStorage.setItem("products", JSON.stringify(state.products));
//         },

//         increaseItem: (state, action) => {

//             const existingItem = state.products.findIndex(
//                 (item) => (item._id + item.size) === action.payload
//             );

//             if (existingItem !== -1) {
//                 const increaseCart = state.products.map((item, index) =>
//                     index === existingItem ? { ...item, quantity: item.quantity + 1 } : item
//                 );
//                 return { ...state, products: increaseCart };
//             } else {
//                 return { ...state, products: [...state.products] };
//             }

//         },

//         decreaseItem: (state, action) => {

//             const existingItem = state.products.findIndex(
//                 (item) => (item._id + item.size) === action.payload
//             );

//             if (existingItem !== -1){
//                 const decreaseCart = state.products.map((item, index) =>
//                     index === existingItem && item.quantity > 1 ? { ...item, quantity: item.quantity - 1 } : item
//                 );
//                 return { ...state, products: decreaseCart };
//             } else {
//                 return { ...state, products: [...state.products] };
//             }

//         },
        
//         resetCart: (state) => {
//             // state.products = []
//             state.quantity = 0;
//             state.total = 0;
//         },

//     },
// });

// // Action creators are generated for each case reducer function
// export const { addToCart, removeItem, resetCart, increaseItem, decreaseItem } = cartSlice.actions;

// export default cartSlice.reducer;










