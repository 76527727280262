import React, { useEffect, useState } from 'react';
import './womenNav.scss';
import { Link } from 'react-router-dom';
import WomenList from './WomenList';
import women from './clothing.png';
import shoes from './shoes.png';
import bags from './bags.png';
import wallets from './wallets.png';
import brands from './brands.png';
import tunjoo from './wtunjoo.png';
import { MdSearch } from 'react-icons/md';
import axios from 'axios';
// import NavMenus from './NavMenus';


const WomenNav = () => {

    // ----------- Input Filter -----------
    const [query, setQuery] = useState("");
    const handleInputChange = (event) => {
        setQuery(event.target.value);
    };

    // ----------- FETCH PRODUCTS FROM BACKEND -----------
    const [products, setProducts] = useState([]);

    useEffect(() => {
        const getProducts = async () => {
            try {
                const res = await axios.get("https://tunjoo-server-36b3be406a89.herokuapp.com/api/products");
                setProducts(res.data);
            } catch (err) { }
        };
        getProducts();
    }, []);


    const filteredItems = products.filter(
        (product) => product.productName?.toLowerCase().indexOf(query?.toLowerCase()) !== -1
    );


    const womenNavItems = [
        {
            id: 1,
            sale: "LAUNCH SALE",
            group: "Women",
            cat: "Women's-Sale",
            image: tunjoo,
            children: [
                {
                    id: 1,
                    title: 'All Clothing | Handbags',
                    group: "Women",
                    cat: "Sale/Women's-Sale",
                    cName: 'dropdown-link3'
                },
            
            ]
        },
        {
            id: 2,
            title: "CLOTHING",
            group: "Women",
            itemCat: "Clothing",
            cat: "Women's-Clothing",
            image: women,
            children: [
                {
                    id: 1,
                    group: "Women",
                    title: 'All Clothing',
                    itemCat: "Clothing",
                    cat: "Women's-Clothing",
                    cName: 'dropdown-link2'
                },
                {
                    id: 2,
                    group: "Women",
                    itemCat: "Clothing",
                    title: 'Dresses & Jumpsuits',
                    cat: "Women's-Dresses-and-Jumpsuits",
                    cName: 'dropdown-link'
                },
                {
                    id: 3,
                    group: "Women",
                    itemCat: "Clothing",
                    title: 'Tops & Bodysuits',
                    cat: "Women's-Tops-and-Bodysuits",
                    cName: 'dropdown-link'
                },
                {
                    id: 4,
                    group: "Women",
                    itemCat: "Clothing",
                    title: 'Shirts & Tshirts',
                    cat: "Women's-Shirts-and-Tshirts",
                    cName: 'dropdown-link'
                },
                {
                    id: 5,
                    group: "Women",
                    title: 'Bottoms',
                    itemCat: "Clothing",
                    cat: "Women's-Bottoms",
                    cName: 'dropdown-link'
                },
                {
                    id: 6,
                    group: "Women",
                    itemCat: "Clothing",
                    title: 'Matching Sets',
                    cat: "Women's-Matching-Sets",
                    cName: 'dropdown-link'
                },
                {
                    id: 7,
                    group: "Women",
                    itemCat: "Clothing",
                    title: "Jackets & Blazers",
                    cat: "Women's-Jackets-and-Blazers",
                    cName: 'dropdown-link'
                },

            ]
        },
        {
            id: 3,
            title: "SHOES",
            group: "Women",
            cat: "Women's-Shoes",
            itemCat: "Shoes",
            image: shoes,
            children: [
                {
                    id: 1,
                    group: "Women",
                    title: 'All Shoes',
                    itemCat: "Shoes",
                    cat: "Women's-Shoes",
                    cName: 'dropdown-link2'
                },
                {
                    id: 2,
                    group: "Women",
                    title: 'Heels',
                    cat: "Heels",
                    itemCat: "Shoes",
                    cName: 'dropdown-link'
                },
                {
                    id: 3,
                    group: "Women",
                    itemCat: "Shoes",
                    title: "Women's Flat Shoes",
                    cat: "Women's-Flat-Shoes",
                    cName: 'dropdown-link'
                },
                {
                    id: 4,
                    group: "Women",
                    title: 'Sneakers',
                    itemCat: "Shoes",
                    cat: "Women's-Sneakers",
                    cName: 'dropdown-link'
                },

            ]
        },
        {
            id: 4,
            title: "HANDBAGS",
            group: "Women",
            cat: "Handbags",
            itemCat: "Handbag",
            image: bags,
            children: [
                {
                    id: 1,
                    group: "Women",
                    title: 'All Bags',
                    cat: 'Handbags',
                    itemCat: "Handbag",
                    cName: 'dropdown-link2'
                },
                {
                    id: 2,
                    group: "Women",
                    title: 'Party Purse',
                    cat: 'Party-Purse',
                    itemCat: "Handbag",
                    cName: 'dropdown-link'
                },
                {
                    id: 3,
                    group: "Women",
                    itemCat: "Handbag",
                    title: 'Mini-Tote | Cross Bag',
                    cat: 'Mini-Tote-and-CrossBag',
                    cName: 'dropdown-link'
                },
                {
                    id: 4,
                    group: "Women",
                    itemCat: "Handbag",
                    title: 'Tote Handbag',
                    cat: 'Tote-Handbag',
                    cName: 'dropdown-link'
                },

            ]
        },
        {
            id: 5,
            title: "ACCESSORIES",
            group: "Women",
            itemCat: "Accessories",
            cat: "Women's-Accessories",
            image: wallets,
            children: [
                {
                    id: 1,
                    group: "Women",
                    itemCat: "Accessories",
                    title: 'All Accessories',
                    cat: "Women's-Accessories",
                    cName: 'dropdown-link2'
                },
                {
                    id: 2,
                    group: "Women",
                    title: 'Belts',
                    itemCat: "Accessories",
                    cat: "Women's-Belts",
                    cName: 'dropdown-link'
                },
                {
                    id: 3,
                    group: "Women",
                    title: 'Wallets',
                    itemCat: "Accessories",
                    cat: "Women's-Wallets",
                    cName: 'dropdown-link'
                },
                {
                    id: 5,
                    group: "Women",
                    title: 'Scarves',
                    itemCat: "Accessories",
                    cat: "Women's-Scarves",
                    cName: 'dropdown-link'
                },

            ]

        },
        {
            id: 6,
            title: "BRANDS",
            group: "Women",
            cat: "All-Brands",
            itemCat: "allbrands",
            image: brands,
            children: [
                {
                    id: 1,
                    group: "Women",
                    title: 'All Brands',
                    cat: 'All-Brands',
                    cName: 'dropdown-link2'
                },
                {
                    id: 2,
                    group: "Women",
                    title: 'Zara',
                    itemCat: "brands",
                    cat: 'ZARA-WOMAN',
                    cName: 'dropdown-link'
                },
                {
                    id: 3,
                    group: "Women",
                    title: 'H&M',
                    itemCat: "brands",
                    cat: 'H&M-WOMAN',
                    cName: 'dropdown-link'
                },
                {
                    id: 4,
                    group: "Women",
                    title: 'Aldo',
                    itemCat: "brands",
                    cat: 'ALDO-WOMAN',
                    cName: 'dropdown-link'
                },
                {
                    id: 5,
                    group: "Women",
                    title: 'Guess',
                    itemCat: "brands",
                    cat: 'GUESS-WOMAN',
                    cName: 'dropdown-link'
                },
                {
                    id: 6,
                    group: "Women",
                    title: 'Steve Madden',
                    itemCat: "brands",
                    cat: 'STEVE-MADDEN-WOMAN',
                    cName: 'dropdown-link'
                },
                {
                    id: 7,
                    group: "Women",
                    title: 'Nine West',
                    itemCat: "brands",
                    cat: 'NINE-WEST',
                    cName: 'dropdown-link'
                },
                {
                    id: 8,
                    group: "Women",
                    title: 'Mango',
                    itemCat: "brands",
                    cat: 'MANGO-WOMAN',
                    cName: 'dropdown-link',
                },
                {
                    id: 9,
                    group: "Women",
                    itemCat: "brands",
                    title: 'Beverly Hills Polo Club',
                    cat: 'BEVERLY-HILLS-POLO-CLUB',
                    cName: 'dropdown-link',
                },

            ]

        },

    ];


    return (
        <div className='womenNav'>
            <div className="womenNavLeft">

                <div className="navbar-links">
                    <ul className="navList">
                        {womenNavItems.map((item) => (
                            <WomenList key={item.id} item={item} />
                        ))}
                    </ul>
                </div>

            </div>

            <div className="womenNavRight">
                <div className="searchWrapper">
                    <input
                        type="text"
                        value={query}
                        onChange={handleInputChange}
                        placeholder='Search Products...'
                        className='searchInput'
                    />
                    <div className="navSearchItem">
                        <MdSearch className='navSearchIcon' />
                    </div>

                    {query && (
                        <div className="navSearchOptions">
                            {filteredItems.map((item) => (
                                <div key={item._id}>
                                    <Link className='optionsLink' to={`/${item.group}/${item.mainCat}/${item._id}`}>
                                        <div className="searchItemWrapper" key={item._id}>
                                            <img src={item?.photos[0]} className='searchImg' alt='' />
                                            <div className="searchText">
                                                <span>{item.productName}</span>
                                                <p>in {item.group} - {item.mainCat}</p>
                                            </div>
                                        </div>

                                    </Link>
                                </div>


                            ))}

                        </div>
                    )} 

                </div>
            </div>



        </div>
    )
}

export default WomenNav












// import React, { useState } from 'react';
// import './womenNav.scss';
// import { Link } from 'react-router-dom';
// import { MdSearch } from 'react-icons/md';
// import { womenNavItems } from '../../../nav-data';
// import WomenList from './WomenList';


// const WomenNav = ({ handleInputChange, query }) => {

//     const scrollToTop = () => {
//         window.scrollTo(0, 0)
//     }

//     const [selected, setSelected] = useState("0");
    // const womenNavItems = [
    //     {
    //         id: 1,
    //         sale: "LAUNCH SALE",
    //         group: "Women",
    //         cat: "Women-Launch-Sale",
    //     },
    //     {
    //         id: 2,
    //         title: "CLOTHING",
    //         group: "Women",
    //         cat: "Clothing",
    //     },
    //     {
    //         id: 3,
    //         title: "SHOES",
    //         group: "Women",
    //         cat: "Shoes",
    //     },
    //     {
    //         id: 4,
    //         title: "HANDBAGS",
    //         group: "Women",
    //         cat: "Handbags",
    //     },
    //     {
    //         id: 5,
    //         title: "ACCESSORIES",
    //         group: "Women",
    //         cat: "Accessories",
    //     },
    //     {
    //         id: 6,
    //         title: "BLAZERS",
    //         group: "Women",
    //         cat: "Jackets&Blazers",
    //     },
    //     {
    //         id: 7,
    //         title: "SPORTS",
    //         group: "Women",
    //         cat: "Active-wear",
    //     },
    //     {
    //         id: 8,
    //         title: "BRANDS",
    //         group: "Women",
    //         cat: "Brands",
    //     },
       
    // ];


//     return (
//         <div className='womenNav'>
//             <div className='womenNavWrapper'>

//                 <div className="womenNavLeft">

//                     {womenNavItems.map((item) => (
//                         <div className="item" key={item.id}>
//                         <Link to={`/${item.group}/${item.cat}`} key={item.id} style={{ textDecoration: 'none', color: 'inherit' }} onClick={scrollToTop}>
//                             <WomenList
//                                 id={item.title ? item.title : item.sale}
//                                 title={item.title}
//                                 sale={item.sale}
//                                 active={item.title ? selected === item.title : selected === item.sale}
//                                 setSelected={setSelected}
//                             />
//                         </Link>
//                         </div>
//                     ))}

                    

//                 </div>

                // {/* <div className="womenNavRight">
                //     <div className="searchWrapper">
                //         <input
                //             value={query}
                //             onChange={handleInputChange}
                //             placeholder='Search Products...'
                //             className='searchInput'
                //         />
                //         <div className="searchIcon">
                //             <MdSearch className='icon' />
                //         </div>
                //     </div>
                // </div> */}

//             </div>

//         </div>
//     )
// }

// export default WomenNav

