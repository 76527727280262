/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

//logos
import Zara from '../../assets/featuredBrands/zaralogo.png';
import hm from '../../assets/featuredBrands/hmlogo.png';
import Mango from '../../assets/featuredBrands/mangologo.png';
import gapLogo from '../../assets/featuredBrands/gapLogo.png';
import kappa from '../../assets/featuredBrands/kappaLogo.png';
import junior from '../../assets/featuredBrands/juniorLogo.png';



const KidsIndex = () => {

    const items1 = [Zara, Mango];
    const items3 = [junior, gapLogo];
    const items4 = [hm, kappa];

    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentIndex3, setCurrentIndex3] = useState(0);
    const [currentIndex4, setCurrentIndex4] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (currentIndex === items1.length - 1) {
                setCurrentIndex(0);
            }
            else {
                setCurrentIndex(currentIndex + 1);
            }
        }, 1000)

        return () => clearInterval(intervalId);
    }, [currentIndex, items1])

    useEffect(() => {
        const intervalId3 = setInterval(() => {
            if (currentIndex3 === items3.length - 1) {
                setCurrentIndex3(0);
            }
            else {
                setCurrentIndex3(currentIndex3 + 1);
            }
        }, 1000)

        return () => clearInterval(intervalId3);
    }, [currentIndex3, items3])

    useEffect(() => {
        const intervalId4 = setInterval(() => {
            if (currentIndex4 === items4.length - 1) {
                setCurrentIndex4(0);
            }
            else {
                setCurrentIndex4(currentIndex4 + 1);
            }
        }, 1000)

        return () => clearInterval(intervalId4);
    }, [currentIndex4, items4])

    return (
        <div className="heading">
            <h2>POPULAR GLOBAL BRANDS</h2>
            <div className="blinkingLogos">
                <img src={items1[currentIndex]} alt="" className='blinkImg' />
                <span className='separator1'>|</span>
                <img src={items3[currentIndex3]} alt="" className='blinkImg' />
                <span className='separator1'>|</span>
                <img src={items4[currentIndex4]} alt="" className='blinkImg' />
            </div>
        </div>
    )
}

export default KidsIndex