import React from 'react';
import './top-picks.scss';
import { Link } from 'react-router-dom';

import bags from './bag.png';
import footwear from './footwear.png';


const TopPicks = () => {

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    return (
        <div className='topPicks'>
             <Link to="/Women/Shoes/Women's-Shoes" style={{color: "inherit", textDecoration: "none"}} onClick={scrollToTop}>
                <div className="picksWrapper">
                    <div className="text">
                        <p>FOOTWEAR</p>
                        <button className='textBtn'>SHOP NOW</button>
                    </div>
                    <div className="image">
                        <img src={footwear} alt="" />
                    </div>
                </div>
            </Link>

            <Link to="/Women/Handbag/Handbags" style={{color: "inherit", textDecoration: "none"}}  onClick={scrollToTop}>
            <div className="picksWrapper">
                <div className="text">
                    <p>HANDBAGS</p>
                    <button className='textBtn'>SHOP NOW</button>
                </div>
                <div className="image">
                    <img src={bags} alt="" />
                </div>
            </div>
            </Link>

        </div>
    )
}

export default TopPicks