import React from 'react';
import './men.scss';
import man from '../../assets/slider/man2.png';
import  { FeaturedMenProducts } from '../../components/featured/FeaturedWomenProducts';
import  { MenFeaturedBrands } from '../../components/featured/FeaturedBrands';
import MenNav from '../../components/navbar/men/MenNav';
import { Link } from 'react-router-dom';
import LogoSlide from '../../promos/LogoSlide';
import ResponseNav from '../../components/res-nav/ResponseNav';
import { TunjooMan } from '../../components/tunjooLaunch/TunjooLaunch';
import MenTopPicks from '../../components/topPicks/MenTopPicks';



const Men = () => {
    return (
        <div className='mainMen'>

            <ResponseNav />
            <MenNav />

            <div className="mainImage">

                <div className="imgWrapper">
                    <img className='image' src={man} alt="" />
                </div>

                <div className="text">
                    <div className="textWrapper">
                        <span>THE CHOICE IS YOURS</span>

                        <div className="heading">
                            <h2>BE YOUR</h2>
                            <h3>OWN BOSS</h3>
                        </div>

                        <Link to={`/Men/Clothing/Men's-Clothing`} className='slide-link'>
                            <button className='homeBtn'>SHOP NOW</button>
                        </Link>

                    </div>

                </div>

            </div>

            <LogoSlide/>
            <MenFeaturedBrands />
            <TunjooMan />
            <MenTopPicks />
            <FeaturedMenProducts />

        </div>
    )
}

export default Men