/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import './brand.scss';
import ResponseNav from '../../components/res-nav/ResponseNav';
import { Link, useLocation } from 'react-router-dom';
import { allBbrands, allKbrands, allWbrands } from '../../dummydata';

//logos
import Zara from '../../assets/featuredBrands/zaralogo.png';
import Aldo from '../../assets/featuredBrands/aldologo.png';
import Steve from '../../assets/featuredBrands/stevelogo.png';
import Topshop from '../../assets/featuredBrands/topshoplogo.png';
import hm from '../../assets/featuredBrands/hmlogo.png';
import Mango from '../../assets/featuredBrands/mangologo.png';
import Nine from '../../assets/featuredBrands/ninelogo.png';
import newlook from '../../assets/featuredBrands/guesslogo.png';
import MenIndex from './MenIndex';
import KidsIndex from './KidsIndex';



const AllBrands = () => {

    const location = useLocation();
    const path = location.pathname.split("/")[1];


    const items1 = [Zara, Aldo];
    const items2 = [hm, Topshop];
    const items3 = [Steve, Mango];
    const items4 = [Nine, newlook];

    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentIndex2, setCurrentIndex2] = useState(0);
    const [currentIndex3, setCurrentIndex3] = useState(0);
    const [currentIndex4, setCurrentIndex4] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (currentIndex === items1.length - 1) {
                setCurrentIndex(0);
            }
            else {
                setCurrentIndex(currentIndex + 1);
            }
        }, 1000)

        return () => clearInterval(intervalId);
    }, [currentIndex, items1])

    useEffect(() => {
        const intervalId2 = setInterval(() => {
            if (currentIndex2 === items2.length - 1) {
                setCurrentIndex2(0);
            }
            else {
                setCurrentIndex2(currentIndex2 + 1);
            }
        }, 1000)

        return () => clearInterval(intervalId2);
    }, [currentIndex2, items2])

    useEffect(() => {
        const intervalId3 = setInterval(() => {
            if (currentIndex3 === items3.length - 1) {
                setCurrentIndex3(0);
            }
            else {
                setCurrentIndex3(currentIndex3 + 1);
            }
        }, 1000)

        return () => clearInterval(intervalId3);
    }, [currentIndex3, items3])

    useEffect(() => {
        const intervalId4 = setInterval(() => {
            if (currentIndex4 === items4.length - 1) {
                setCurrentIndex4(0);
            }
            else {
                setCurrentIndex4(currentIndex4 + 1);
            }
        }, 1000)

        return () => clearInterval(intervalId4);
    }, [currentIndex4, items4])


    return (
        <div className='allBrands'>
            <hr className='brandHr' />
            <ResponseNav />

            <div className="brandWrapper">

                {path === "Women" &&
                    <div className="heading">
                        <h2>POPULAR GLOBAL BRANDS</h2>
                        <div className="blinkingLogos">
                            <img src={items1[currentIndex]} alt="" className='blinkImg' />
                            <span className='separator1'>|</span>
                            <img src={items2[currentIndex2]} alt="" className='blinkImg'/>
                            <span className='separator1'>|</span>
                            <img src={items3[currentIndex3]} alt="" className='blinkImg'/>
                            <span className='separator1'>|</span>
                            <img src={items4[currentIndex4]} alt="" className='blinkImg'/>
                        </div>
                    </div>
                }
                {path === "Men" &&
                    <MenIndex/>
                }
                {path === "Kids" &&
                    <KidsIndex/>
                }

                {path === "Women" &&
                    <div className="listBrands">
                        {allWbrands.map((item) => (
                            <Link to={`/Women/brands/${item.brand}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                <div className="listItemWrap">
                                    <img src={item.image} alt="" />
                                    <span>{item.name}</span>
                                </div>
                            </Link>
                        ))}
                    </div>
                }

                {path === "Men" &&
                    <div className="listBrands">
                        {allBbrands.map((item) => (
                            <Link to={`/Men/brands/${item.brand}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                <div className="listItemWrap">
                                    <img src={item.image} alt="" />
                                    <span>{item.name}</span>
                                </div>
                            </Link>
                        ))}
                    </div>
                }

                {path === "Kids" &&
                    <div className="listBrands">
                        {allKbrands.map((item) => (
                            <Link to={`/Kids/brands/${item.brand}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                <div className="listItemWrap">
                                    <img src={item.image} alt="" />
                                    <span>{item.name}</span>
                                </div>
                            </Link>
                        ))}
                    </div>
                }





            </div>


        </div>
    )
}

export default AllBrands