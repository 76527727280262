import React from 'react';
import './productlist.scss';
import { Link, useLocation } from 'react-router-dom';
import Products from './Products';
import { useState } from 'react';
import { MdArrowRight } from 'react-icons/md';
import { useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import ListCard from '../../components/card/ListCard';
import SubCats from './subComps/SubCats';
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import KidsNav from '../../components/navbar/kids/KidsNav';
import MenNav from '../../components/navbar/men/MenNav';
import WomenNav from '../../components/navbar/women/WomenNav';
import ResponseNav from '../../components/res-nav/ResponseNav';
import MenSubCats from './subComps/MenSubCats';
import KidsSubCats from './subComps/KidsSubCats';

import { Pagination, Stack } from '@mui/material';



const MainProductList = () => {

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    // ----------- spliting categories -----------
    const location = useLocation();
    const cat = location.pathname.split("/")[3].replace(/-/g, " ");
    const itemCat = location.pathname.split("/")[2];
    const group = location.pathname.split("/")[1];


    // ----------- FETCH PRODUCTS FROM BACKEND -----------
    const [products, setProducts] = useState([]);

    useEffect(() => {
        const getProducts = async () => {
            try {
                const res = await axios.get(
                    cat
                        ? `https://tunjoo-server-36b3be406a89.herokuapp.com/api/products?category=${cat}`
                        : "https://tunjoo-server-36b3be406a89.herokuapp.com/api/products"
                );
                setProducts(res.data);
            } catch (err) { }
        };
        getProducts();
    }, [cat]);


    // // ----------- PAGE PAGINATION -----------
    // const [page, setPage] = useState(0);


    // ----------- Filter by Color & Size, Sort -----------
    // const [open, setOpen] = useState(false);
    const [openColor, setOpenColor] = useState(false);
    const [openSize, setOpenSize] = useState(false);

    const [selectedOption, setSelectedOption] = useState("newest");
    const options = [
        { value: '', label: 'Sort Products' },
        { value: 'newest', label: 'Newest' },
        { value: 'asc', label: 'Price (Low - High)' },
        { value: 'desc', label: 'Price (High - Low)' },
    ];

    const sort = selectedOption.value;

    // ------------ Button- mainCat Filtering -----------
    const [selectedCategory, setSelectedCategory] = useState(null);
    const handleClick = (event) => {
        setSelectedCategory(event.target.value);
    };

    function filteredData(products, selected) {
        let filteredProducts = products;

        // Applying selected filter
        if (selected) {
            filteredProducts = filteredProducts.filter(
                ({ brand, color, size, otherSizes, subCat, mainCat }) =>
                    brand === selected ||
                    color === selected ||
                    size === selected ||
                    subCat === selected ||
                    mainCat === selected ||
                    otherSizes === selected
            );
        }

        //Applying sorting filter
        if (sort === "newest") {
            filteredProducts = filteredProducts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        } else if (sort === "asc") {
            filteredProducts = filteredProducts.sort((a, b) => a.sellingPrice - b.sellingPrice)
        } else {
            filteredProducts = filteredProducts.sort((a, b) => b.sellingPrice - a.sellingPrice)
        }

        return filteredProducts.map(
            (item) => (
                <Link to={`/${item.group}/products/${item.category[0].replace(/\s+/g, '-')}/${item._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <ListCard
                        key={Math.random()}
                        item={item}
                    />
                </Link>
            )
        );

    }

    const result = filteredData(products, selectedCategory);

    //PAGINATION
    const [pageSize, setPageSize] = useState(16);
    const [page, setPage] = useState(1);
    const handlePage = (page) => setPage(page);
    const handlePageSizeChange = (event) => {
        setPageSize(event.target.value);
    };
    const totalPages = Math.ceil(result.length / pageSize);
    const pageContent = result.slice((page - 1) * pageSize, page * pageSize);


    return (
        <div className='products'>

            <ResponseNav />
            {group === "Women" ? <WomenNav /> : group === "Men" ? <MenNav /> : <KidsNav />}

            <ResponseNav />
            <div className="products-Wrapper">
                <div className="top">

                    <span className="routes">
                        Home  /  {group} /
                        <MdArrowRight className='routeIcon' />
                        {/* {cat.replace(/%20/g, " ")} */}
                        {cat.replace(/%7C/g, "&")}
                    </span>

                    <div className="pdtPageHeading">
                        <h2 className='pdtPageTitle'>{cat.replace(/%7C/g, "&")}</h2>

                        <div className="items">
                            {group === "Women" ?
                                <SubCats cat={cat} handleClick={handleClick} />
                                :
                                group === "Men" ?
                                    <MenSubCats cat={cat} handleClick={handleClick} />
                                    :
                                    <KidsSubCats cat={cat} handleClick={handleClick} />
                            }
                        </div>

                    </div>

                    <div className="filtersWrapper">
                        <div className="left">
                            <span className='refineTitle'>REFINE BY:</span>
                            <div className="selectFilters">
                                <div className="selectFilterItem" onClick={() => setOpenColor(!openColor)}>
                                    {openColor ? <p className='itemName1'>Color</p> : <p className='itemName2'>Color</p>}
                                    {openColor ? <RiArrowUpSLine className='dropIcon1' /> : <RiArrowDownSLine className='dropIcon2' />}
                                </div>
                                {openColor && (
                                    <div className="optionsWrapper">

                                        <div className="options">
                                            <label className="filterItem">
                                                <input onChange={handleClick} type="radio" value="" name="color" onClick={() => setOpenColor(!openColor)} />
                                                <span className="checkmark"></span>
                                                <p>All</p>
                                            </label>
                                            <label className="filterItem">
                                                <input onChange={handleClick} type="radio" value="White" name="color" onClick={() => setOpenColor(!openColor)} />
                                                <span className="checkmark"></span>
                                                <p>White</p>
                                            </label>
                                            <label className="filterItem">
                                                <input onChange={handleClick} type="radio" value="Black" name="color" onClick={() => setOpenColor(!openColor)} />
                                                <span className="checkmark"></span>
                                                <p>Black</p>
                                            </label>
                                            <label className="filterItem">
                                                <input onChange={handleClick} type="radio" value="Red" name="color" onClick={() => setOpenColor(!openColor)} />
                                                <span className="checkmark"></span>
                                                <p>Red</p>
                                            </label>
                                            <label className="filterItem">
                                                <input onChange={handleClick} type="radio" value="Pink" name="color" onClick={() => setOpenColor(!openColor)} />
                                                <span className="checkmark"></span>
                                                <p>Pink</p>
                                            </label>
                                            <label className="filterItem">
                                                <input onChange={handleClick} type="radio" value="Green" name="color" onClick={() => setOpenColor(!openColor)} />
                                                <span className="checkmark"></span>
                                                <p>Green</p>
                                            </label>
                                            <label className="filterItem">
                                                <input onChange={handleClick} type="radio" value="Orange" name="color" onClick={() => setOpenColor(!openColor)} />
                                                <span className="checkmark"></span>
                                                <p>Orange</p>
                                            </label>
                                            <label className="filterItem">
                                                <input onChange={handleClick} type="radio" value="Brown" name="color" onClick={() => setOpenColor(!openColor)} />
                                                <span className="checkmark"></span>
                                                <p>Brown</p>
                                            </label>
                                            <label className="filterItem">
                                                <input onChange={handleClick} type="radio" value="Blue" name="color" onClick={() => setOpenColor(!openColor)} />
                                                <span className="checkmark"></span>
                                                <p>Blue</p>
                                            </label>
                                            <label className="filterItem">
                                                <input onChange={handleClick} type="radio" value="Camel" name="color" onClick={() => setOpenColor(!openColor)} />
                                                <span className="checkmark"></span>
                                                <p>Camel/Khaki</p>
                                            </label>

                                        </div>
                                        <div className="options">

                                            <label className="filterItem">
                                                <input onChange={handleClick} type="radio" value="Cream" name="color" onClick={() => setOpenColor(!openColor)} />
                                                <span className="checkmark"></span>
                                                <p>Cream</p>
                                            </label>
                                            <label className="filterItem">
                                                <input onChange={handleClick} type="radio" value="Grey" name="color" onClick={() => setOpenColor(!openColor)} />
                                                <span className="checkmark"></span>
                                                <p>Grey</p>
                                            </label>
                                            <label className="filterItem">
                                                <input onChange={handleClick} type="radio" value="Neon" name="color" onClick={() => setOpenColor(!openColor)} />
                                                <span className="checkmark"></span>
                                                <p>Neon</p>
                                            </label>
                                            <label className="filterItem">
                                                <input onChange={handleClick} type="radio" value="Gold" name="color" onClick={() => setOpenColor(!openColor)} />
                                                <span className="checkmark"></span>
                                                <p>Gold</p>
                                            </label>
                                            <label className="filterItem">
                                                <input onChange={handleClick} type="radio" value="Multi" name="color" onClick={() => setOpenColor(!openColor)} />
                                                <span className="checkmark"></span>
                                                <p>Multi</p>
                                            </label>
                                            <label className="filterItem">
                                                <input onChange={handleClick} type="radio" value="Metallic" name="color" onClick={() => setOpenColor(!openColor)} />
                                                <span className="checkmark"></span>
                                                <p>Metallic</p>
                                            </label>
                                            <label className="filterItem">
                                                <input onChange={handleClick} type="radio" value="Yellow" name="color" onClick={() => setOpenColor(!openColor)} />
                                                <span className="checkmark"></span>
                                                <p>Yellow</p>
                                            </label>
                                            <label className="filterItem">
                                                <input onChange={handleClick} type="radio" value="Purple" name="color" onClick={() => setOpenColor(!openColor)} />
                                                <span className="checkmark"></span>
                                                <p>Purple</p>
                                            </label>
                                            <label className="filterItem">
                                                <input onChange={handleClick} type="radio" value="Beige" name="color" onClick={() => setOpenColor(!openColor)} />
                                                <span className="checkmark"></span>
                                                <p>Beige</p>
                                            </label>
                                            <label className="filterItem">
                                                <input onChange={handleClick} type="radio" value="Navy Blue" name="color" onClick={() => setOpenColor(!openColor)} />
                                                <span className="checkmark"></span>
                                                <p>Navy Blue</p>
                                            </label>

                                        </div>
                                    </div>
                                )}

                                <div className="selectFilterItem" onClick={() => setOpenSize(!openSize)}>
                                    {openSize ? <p className='itemName1'>Size</p> : <p className='itemName2'>Size</p>}
                                    {openSize ? <RiArrowUpSLine className='dropIcon1' /> : <RiArrowDownSLine className='dropIcon2' />}
                                </div>
                                {openSize && (
                                    <div className="optionsWrapper">

                                        {/* CLOTHING */}
                                        {itemCat === "Clothing" &&
                                            <>
                                                <div className="options">
                                                    <label className="filterItem">
                                                        <input onChange={handleClick} type="radio" value="" name="size" onClick={() => setOpenSize(!openSize)} />
                                                        <span className="checkmark"></span>
                                                        <p>All</p>
                                                    </label>

                                                    <label className="filterItem">
                                                        <input onChange={handleClick} type="radio" value="XS" name="size" onClick={() => setOpenSize(!openSize)} />
                                                        <span className="checkmark"></span>
                                                        <p>XS</p>
                                                    </label>
                                                    <label className="filterItem">
                                                        <input onChange={handleClick} type="radio" value="S" name="size" onClick={() => setOpenSize(!openSize)} />
                                                        <span className="checkmark"></span>
                                                        <p>S</p>
                                                    </label>
                                                    <label className="filterItem">
                                                        <input onChange={handleClick} type="radio" value="M" name="size" onClick={() => setOpenSize(!openSize)} />
                                                        <span className="checkmark"></span>
                                                        <p>M</p>
                                                    </label>

                                                </div>

                                                <div className="options">

                                                    <label className="filterItem">
                                                        <input onChange={handleClick} type="radio" value="L" name="size" onClick={() => setOpenSize(!openSize)} />
                                                        <span className="checkmark"></span>
                                                        <p>L</p>
                                                    </label>
                                                    <label className="filterItem">
                                                        <input onChange={handleClick} type="radio" value="XL" name="size" onClick={() => setOpenSize(!openSize)} />
                                                        <span className="checkmark"></span>
                                                        <p>XL</p>
                                                    </label>
                                                    <label className="filterItem">
                                                        <input onChange={handleClick} type="radio" value="XXL" name="size" onClick={() => setOpenSize(!openSize)} />
                                                        <span className="checkmark"></span>
                                                        <p>XXL</p>
                                                    </label>

                                                </div>
                                            </>
                                        }

                                        {/* SHOES */}
                                        {itemCat === "Shoes" &&
                                            <>
                                                <div className="options">
                                                    <label className="filterItem">
                                                        <input onChange={handleClick} type="radio" value="" name="size" onClick={() => setOpenSize(!openSize)} />
                                                        <span className="checkmark"></span>
                                                        <p>All</p>
                                                    </label>
                                                    <label className="filterItem">
                                                        <input onChange={handleClick} type="radio" value="37" name="size" onClick={() => setOpenSize(!openSize)} />
                                                        <span className="checkmark"></span>
                                                        <p>37</p>
                                                    </label>
                                                    <label className="filterItem">
                                                        <input onChange={handleClick} type="radio" value="38" name="size" onClick={() => setOpenSize(!openSize)} />
                                                        <span className="checkmark"></span>
                                                        <p>38</p>
                                                    </label>
                                                    <label className="filterItem">
                                                        <input onChange={handleClick} type="radio" value="39" name="size" onClick={() => setOpenSize(!openSize)} />
                                                        <span className="checkmark"></span>
                                                        <p>39</p>
                                                    </label>

                                                </div>

                                                <div className="options">
                                                    <label className="filterItem">
                                                        <input onChange={handleClick} type="radio" value="40" name="size" onClick={() => setOpenSize(!openSize)} />
                                                        <span className="checkmark"></span>
                                                        <p>40</p>
                                                    </label>
                                                    <label className="filterItem">
                                                        <input onChange={handleClick} type="radio" value="41" name="size" onClick={() => setOpenSize(!openSize)} />
                                                        <span className="checkmark"></span>
                                                        <p>41</p>
                                                    </label>
                                                    <label className="filterItem">
                                                        <input onChange={handleClick} type="radio" value="42" name="size" onClick={() => setOpenSize(!openSize)} />
                                                        <span className="checkmark"></span>
                                                        <p>42</p>
                                                    </label>

                                                </div>
                                            </>
                                        }


                                        {/* HANDBAG */}


                                        {/* ACCESSORIES */}
                                        {itemCat === "Accessorie" &&
                                            <>
                                                <div className="options">
                                                    <label className="filterItem">
                                                        <input onChange={handleClick} type="radio" value="" name="size" onClick={() => setOpenSize(!openSize)} />
                                                        <span className="checkmark"></span>
                                                        <p>All</p>
                                                    </label>

                                                    <label className="filterItem">
                                                        <input onChange={handleClick} type="radio" value="XS" name="size" onClick={() => setOpenSize(!openSize)} />
                                                        <span className="checkmark"></span>
                                                        <p>XS</p>
                                                    </label>
                                                    <label className="filterItem">
                                                        <input onChange={handleClick} type="radio" value="S" name="size" onClick={() => setOpenSize(!openSize)} />
                                                        <span className="checkmark"></span>
                                                        <p>S</p>
                                                    </label>
                                                    <label className="filterItem">
                                                        <input onChange={handleClick} type="radio" value="M" name="size" onClick={() => setOpenSize(!openSize)} />
                                                        <span className="checkmark"></span>
                                                        <p>M</p>
                                                    </label>

                                                </div>

                                                <div className="options">

                                                    <label className="filterItem">
                                                        <input onChange={handleClick} type="radio" value="L" name="size" onClick={() => setOpenSize(!openSize)} />
                                                        <span className="checkmark"></span>
                                                        <p>L</p>
                                                    </label>
                                                    <label className="filterItem">
                                                        <input onChange={handleClick} type="radio" value="XL" name="size" onClick={() => setOpenSize(!openSize)} />
                                                        <span className="checkmark"></span>
                                                        <p>XL</p>
                                                    </label>
                                                    <label className="filterItem">
                                                        <input onChange={handleClick} type="radio" value="XXL" name="size" onClick={() => setOpenSize(!openSize)} />
                                                        <span className="checkmark"></span>
                                                        <p>XXL</p>
                                                    </label>

                                                </div>
                                            </>
                                        }

                                    </div>
                                )}

                            </div>
                        </div>

                        <div className="center">
                            <p>{result.length}</p>
                            <p>{products.length > 1 ? "Items" : "Item"}</p>
                        </div>

                        <div class="right">

                            <Select
                                defaultValue={options[0]}
                                onChange={setSelectedOption}
                                options={options}
                                classNamePrefix='filter'
                                styles={{
                                    container: (base) => ({
                                        ...base,
                                        width: '160px',
                                        border:'none'
                                    }),
                                    singleValue: (base, state) => ({
                                        ...base,
                                        color: '#000000',
                                        fontSize: '12.5px'
                                    }),
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused ? 'lightgray' : 'lightgray',
                                        minHeight: '30px',
                                        height: '30px',
                                    }),
                                    option: (base, state) => ({
                                        ...base,
                                        border: '0.4px solid lightgray',
                                    }),
                                    indicatorsContainer: (provided, state) => ({
                                        ...provided,
                                        height: '30px',
                                        color: '#000000'
                                    }),

                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 3,
                                    colors: {
                                        ...theme.colors,
                                        primary25: 'rgba(197, 194, 191, 0.4)',
                                        primary: '#c7c6c6',
                                    },
                                })}

                            />

                        </div>

                    </div>

                    {/* <Products result={result} /> */}
                    <div name="page-size" id="page-size" onChange={handlePageSizeChange}>
                        <Products pageContent={pageContent} />
                    </div>

                    <div className="paginationWrapper">
                        <Stack spacing={2}>
                            <Pagination
                                page={page}
                                size="medium"
                                className='pagination'
                                count={totalPages}
                                // siblingCount={-4}
                                // boundaryCount={2}
                                onClick={scrollToTop}
                                onChange={(event, value) => handlePage(value)}
                                variant="outlined" shape="rounded"
                            ></Pagination>
                        </Stack>
                    </div>

                </div>
            </div>


        </div>
    )
}

export default MainProductList













// import React from 'react';
// import './productlist.scss';
// import { Link, useLocation } from 'react-router-dom';
// import Products from './Products';
// import { useState } from 'react';
// import { MdArrowRight } from 'react-icons/md';
// import { useEffect } from 'react';
// import axios from 'axios';
// import Select from 'react-select';
// import ListCard from '../../components/card/ListCard';
// import SubCats from './subComps/SubCats';
// import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
// import KidsNav from '../../components/navbar/kids/KidsNav';
// import MenNav from '../../components/navbar/men/MenNav';
// import WomenNav from '../../components/navbar/women/WomenNav';
// import ResponseNav from '../../components/res-nav/ResponseNav';
// import MenSubCats from './subComps/MenSubCats';
// import KidsSubCats from './subComps/KidsSubCats';

// import { Pagination, Stack } from '@mui/material';



// const MainProductList = () => {

//     const scrollToTop = () => {
//         window.scrollTo(0, 0)
//     }

//     // ----------- spliting categories -----------
//     const location = useLocation();
//     const cat = location.pathname.split("/")[2].replace(/-/g, " ");
//     const group = location.pathname.split("/")[1];


//     // ----------- FETCH PRODUCTS FROM BACKEND -----------
//     const [products, setProducts] = useState([]);

//     useEffect(() => {
//         const getProducts = async () => {
//             try {
//                 const res = await axios.get(
//                     cat
//                         ? `https://tunjoo-server-36b3be406a89.herokuapp.com/api/products?category=${cat}`
//                         : "https://tunjoo-server-36b3be406a89.herokuapp.com/api/products"
//                 );
//                 setProducts(res.data);
//             } catch (err) { }
//         };
//         getProducts();
//     }, [cat]);


//     // // ----------- PAGE PAGINATION -----------
//     // const [page, setPage] = useState(0);


//     // ----------- Filter by Color & Size, Sort -----------
//     const [open, setOpen] = useState(false);
//     const [openColor, setOpenColor] = useState(false);
//     const [openSize, setOpenSize] = useState(false);

//     const [selectedOption, setSelectedOption] = useState("newest");
//     const options = [
//         { value: '', label: 'Sort Products' },
//         { value: 'newest', label: 'Newest' },
//         { value: 'asc', label: 'Price (Low - High)' },
//         { value: 'desc', label: 'Price (High - Low)' },
//     ];

//     const sort = selectedOption.value;

//     // ------------ Button- mainCat Filtering -----------
//     const [selectedCategory, setSelectedCategory] = useState(null);
//     const handleClick = (event) => {
//         setSelectedCategory(event.target.value);
//     };

//     function filteredData(products, selected) {
//         let filteredProducts = products;

//         // Applying selected filter
//         if (selected) {
//             filteredProducts = filteredProducts.filter(
//                 ({ brand, color, size, otherSizes, subCat, mainCat }) =>
//                     brand === selected ||
//                     color === selected ||
//                     size === selected ||
//                     subCat === selected ||
//                     mainCat === selected ||
//                     otherSizes === selected
//             );
//         }

//         //Applying sorting filter
//         if (sort === "newest") {
//             filteredProducts = filteredProducts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
//         } else if (sort === "asc") {
//             filteredProducts = filteredProducts.sort((a, b) => a.sellingPrice - b.sellingPrice)
//         } else {
//             filteredProducts = filteredProducts.sort((a, b) => b.sellingPrice - a.sellingPrice)
//         }

//         return filteredProducts.map(
//             (item) => (
//                 <Link to={`/${item.group}/${item.mainCat}/${item._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
//                     <ListCard
//                         key={Math.random()}
//                         item={item}
//                     />
//                 </Link>
//             )
//         );

//     }

//     const result = filteredData(products, selectedCategory);

//     //PAGINATION
//     const [pageSize, setPageSize] = useState(16);
//     const [page, setPage] = useState(1);
//     const handlePage = (page) => setPage(page);
//     const handlePageSizeChange = (event) => {
//         setPageSize(event.target.value);
//     };
//     const totalPages = Math.ceil(result.length / pageSize);
//     const pageContent = result.slice((page - 1) * pageSize, page * pageSize);


//     return (
//         <div className='products'>

//             <ResponseNav />
//             {group === "Women" ? <WomenNav /> : group === "Men" ? <MenNav /> : <KidsNav />}

//             <ResponseNav />
//             <div className="products-Wrapper">
//                 <div className="top">

//                     <span className="routes">
//                         Home  /  {group} /
//                         <MdArrowRight className='routeIcon' />
//                         {/* {cat.replace(/%20/g, " ")} */}
//                         {cat.replace(/%7C/g, "&")}
//                     </span>

//                     <div className="heading">
//                         <h2>{cat.replace(/%7C/g, "&")}</h2>

//                         <div className="items">
//                             {group === "Women" ?
//                                 <SubCats cat={cat} handleClick={handleClick} />
//                                 :
//                                 group === "Men" ?
//                                     <MenSubCats cat={cat} handleClick={handleClick} />
//                                     :
//                                     <KidsSubCats cat={cat} handleClick={handleClick} />
//                             }
//                         </div>

//                     </div>

//                     <div className="filtersWrapper">
//                         <div className="left">
//                             <span>REFINE BY:</span>
//                             <div className="selectFilters">
//                                 <div className="selectFilterItem" onClick={() => setOpenColor(!openColor)}>
//                                     {openColor ? <p className='itemName1'>Color</p> : <p className='itemName2'>Color</p>}
//                                     {openColor ? <RiArrowUpSLine className='dropIcon1' /> : <RiArrowDownSLine className='dropIcon2' />}
//                                 </div>
//                                 {openColor && (
//                                     <div className="optionsWrapper">

//                                         <div className="options">
//                                             <label className="filterItem">
//                                                 <input onChange={handleClick} type="radio" value="" name="color" onClick={() => setOpenColor(!openColor)} />
//                                                 <span className="checkmark"></span>
//                                                 <p>All Colors</p>
//                                             </label>
//                                             <label className="filterItem">
//                                                 <input onChange={handleClick} type="radio" value="White" name="color"  onClick={() => setOpenColor(!openColor)} />
//                                                 <span className="checkmark"></span>
//                                                 <p>White</p>
//                                             </label>
//                                             <label className="filterItem">
//                                                 <input onChange={handleClick} type="radio" value="Black" name="color" onClick={() => setOpenColor(!openColor)} />
//                                                 <span className="checkmark"></span>
//                                                 <p>Black</p>
//                                             </label>
//                                             <label className="filterItem">
//                                                 <input onChange={handleClick} type="radio" value="Red" name="color"  onClick={() => setOpenColor(!openColor)}/>
//                                                 <span className="checkmark"></span>
//                                                 <p>Red</p>
//                                             </label>
//                                             <label className="filterItem">
//                                                 <input onChange={handleClick} type="radio" value="Pink" name="color"  onClick={() => setOpenColor(!openColor)}/>
//                                                 <span className="checkmark"></span>
//                                                 <p>Pink</p>
//                                             </label>
//                                             <label className="filterItem">
//                                                 <input onChange={handleClick} type="radio" value="Green" name="color"  onClick={() => setOpenColor(!openColor)}/>
//                                                 <span className="checkmark"></span>
//                                                 <p>Green</p>
//                                             </label>
//                                             <label className="filterItem">
//                                                 <input onChange={handleClick} type="radio" value="Orange" name="color"  onClick={() => setOpenColor(!openColor)}/>
//                                                 <span className="checkmark"></span>
//                                                 <p>Orange</p>
//                                             </label>
//                                             <label className="filterItem">
//                                                 <input onChange={handleClick} type="radio" value="Brown" name="color"  onClick={() => setOpenColor(!openColor)}/>
//                                                 <span className="checkmark"></span>
//                                                 <p>Brown</p>
//                                             </label>
//                                             <label className="filterItem">
//                                                 <input onChange={handleClick} type="radio" value="Blue" name="color"  onClick={() => setOpenColor(!openColor)}/>
//                                                 <span className="checkmark"></span>
//                                                 <p>Blue</p>
//                                             </label>
//                                             <label className="filterItem">
//                                                 <input onChange={handleClick} type="radio" value="Camel" name="color"  onClick={() => setOpenColor(!openColor)}/>
//                                                 <span className="checkmark"></span>
//                                                 <p>Camel</p>
//                                             </label>

//                                         </div>
//                                         <div className="options">

//                                             <label className="filterItem">
//                                                 <input onChange={handleClick} type="radio" value="Cream" name="color"  onClick={() => setOpenColor(!openColor)}/>
//                                                 <span className="checkmark"></span>
//                                                 <p>Cream</p>
//                                             </label>
//                                             <label className="filterItem">
//                                                 <input onChange={handleClick} type="radio" value="Grey" name="color"  onClick={() => setOpenColor(!openColor)}/>
//                                                 <span className="checkmark"></span>
//                                                 <p>Grey</p>
//                                             </label>
//                                             <label className="filterItem">
//                                                 <input onChange={handleClick} type="radio" value="Neon" name="color"  onClick={() => setOpenColor(!openColor)}/>
//                                                 <span className="checkmark"></span>
//                                                 <p>Neon</p>
//                                             </label>
//                                             <label className="filterItem">
//                                                 <input onChange={handleClick} type="radio" value="Gold" name="color"  onClick={() => setOpenColor(!openColor)}/>
//                                                 <span className="checkmark"></span>
//                                                 <p>Gold</p>
//                                             </label>
//                                             <label className="filterItem">
//                                                 <input onChange={handleClick} type="radio" value="Multi" name="color"  onClick={() => setOpenColor(!openColor)}/>
//                                                 <span className="checkmark"></span>
//                                                 <p>Multi</p>
//                                             </label>
//                                             <label className="filterItem">
//                                                 <input onChange={handleClick} type="radio" value="Metallic" name="color"  onClick={() => setOpenColor(!openColor)}/>
//                                                 <span className="checkmark"></span>
//                                                 <p>Metallic</p>
//                                             </label>
//                                             <label className="filterItem">
//                                                 <input onChange={handleClick} type="radio" value="Yellow" name="color"  onClick={() => setOpenColor(!openColor)}/>
//                                                 <span className="checkmark"></span>
//                                                 <p>Yellow</p>
//                                             </label>
//                                             <label className="filterItem">
//                                                 <input onChange={handleClick} type="radio" value="Purple" name="color"  onClick={() => setOpenColor(!openColor)}/>
//                                                 <span className="checkmark"></span>
//                                                 <p>Purple</p>
//                                             </label>
//                                             <label className="filterItem">
//                                                 <input onChange={handleClick} type="radio" value="Beige" name="color"  onClick={() => setOpenColor(!openColor)}/>
//                                                 <span className="checkmark"></span>
//                                                 <p>Beige</p>
//                                             </label>
//                                             <label className="filterItem">
//                                                 <input onChange={handleClick} type="radio" value="Navy Blue" name="color"  onClick={() => setOpenColor(!openColor)}/>
//                                                 <span className="checkmark"></span>
//                                                 <p>Navy Blue</p>
//                                             </label>

//                                         </div>
//                                     </div>
//                                 )}

//                                 <div className="selectFilterItem" onClick={() => setOpenSize(!openSize)}>
//                                     {openSize ? <p className='itemName1'>Size</p> : <p className='itemName2'>Size</p>}
//                                     {openSize ? <RiArrowUpSLine className='dropIcon1' /> : <RiArrowDownSLine className='dropIcon2' />}
//                                 </div>
//                                 {openSize && (
//                                     <div className="optionsWrapper">
//                                         <div className="options">

//                                             {cat === "Women's Shoes" || "Men's Shoes" ?
//                                                 <>
                                                    // <label className="filterItem">
                                                    //     <input onChange={handleClick} type="radio" value="" name="size"  onClick={() => setOpenSize(!openSize)}/>
                                                    //     <span className="checkmark"></span>
                                                    //     <p>All</p>
                                                    // </label>
                                                    // <label className="filterItem">
                                                    //     <input onChange={handleClick} type="radio" value="37" name="size" onClick={() => setOpenSize(!openSize)}/>
                                                    //     <span className="checkmark"></span>
                                                    //     <p>37</p>
                                                    // </label>
                                                    // <label className="filterItem">
                                                    //     <input onChange={handleClick} type="radio" value="38" name="size" onClick={() => setOpenSize(!openSize)}/>
                                                    //     <span className="checkmark"></span>
                                                    //     <p>38</p>
                                                    // </label>
                                                    // <label className="filterItem">
                                                    //     <input onChange={handleClick} type="radio" value="39" name="size" onClick={() => setOpenSize(!openSize)}/>
                                                    //     <span className="checkmark"></span>
                                                    //     <p>39</p>
                                                    // </label>

//                                                 </>

//                                                 :

//                                                 cat === "Handbags" ? ""

//                                                     :

//                                                     <>
//                                                         <label className="filterItem">
//                                                             <input onChange={handleClick} type="radio" value="" name="size" onClick={() => setOpenSize(!openSize)}/>
//                                                             <span className="checkmark"></span>
//                                                             <p>All</p>
//                                                         </label>

//                                                         <label className="filterItem">
//                                                             <input onChange={handleClick} type="radio" value="XS" name="size" onClick={() => setOpenSize(!openSize)}/>
//                                                             <span className="checkmark"></span>
//                                                             <p>XS</p>
//                                                         </label>
//                                                         <label className="filterItem">
//                                                             <input onChange={handleClick} type="radio" value="S" name="size" onClick={() => setOpenSize(!openSize)}/>
//                                                             <span className="checkmark"></span>
//                                                             <p>S</p>
//                                                         </label>
//                                                         <label className="filterItem">
//                                                             <input onChange={handleClick} type="radio" value="M" name="size" onClick={() => setOpenSize(!openSize)}/>
//                                                             <span className="checkmark"></span>
//                                                             <p>M</p>
//                                                         </label>

//                                                     </>
//                                             }

//                                         </div>

//                                         <div className="options">

//                                             {cat === "Shoes" ?
//                                                 <>

                                                    // <label className="filterItem">
                                                    //     <input onChange={handleClick} type="radio" value="40" name="size" onClick={() => setOpenSize(!openSize)}/>
                                                    //     <span className="checkmark"></span>
                                                    //     <p>40</p>
                                                    // </label>
                                                    // <label className="filterItem">
                                                    //     <input onChange={handleClick} type="radio" value="41" name="size" onClick={() => setOpenSize(!openSize)}/>
                                                    //     <span className="checkmark"></span>
                                                    //     <p>41</p>
                                                    // </label>
                                                    // <label className="filterItem">
                                                    //     <input onChange={handleClick} type="radio" value="42" name="size" onClick={() => setOpenSize(!openSize)}/>
                                                    //     <span className="checkmark"></span>
                                                    //     <p>42</p>
                                                    // </label>

//                                                 </>

//                                                 :

//                                                 cat === "Handbags" ? ""

//                                                     :

//                                                     <>

//                                                         <label className="filterItem">
//                                                             <input onChange={handleClick} type="radio" value="L" name="size" onClick={() => setOpenColor(!openSize)}/>
//                                                             <span className="checkmark"></span>
//                                                             <p>L</p>
//                                                         </label>
//                                                         <label className="filterItem">
//                                                             <input onChange={handleClick} type="radio" value="XL" name="size" onClick={() => setOpenColor(!openSize)}/>
//                                                             <span className="checkmark"></span>
//                                                             <p>XL</p>
//                                                         </label>
//                                                         <label className="filterItem">
//                                                             <input onChange={handleClick} type="radio" value="XXL" name="size" onClick={() => setOpenColor(!openSize)}/>
//                                                             <span className="checkmark"></span>
//                                                             <p>XXL</p>
//                                                         </label>

//                                                     </>
//                                             }

//                                         </div>
//                                     </div>
//                                 )}

//                             </div>
//                         </div>

//                         <div className="center">
//                             <p>{result.length}</p>
//                             <p>{products.length > 1 ? "Items" : "Item"}</p>
//                         </div>

//                         <div class="right">

//                             <Select
//                                 defaultValue={options[0]}
//                                 onChange={setSelectedOption}
//                                 options={options}
//                                 classNamePrefix='filter'
//                                 styles={{
//                                     container: (base) => ({
//                                         ...base,
//                                         width: '180px',
//                                     }),
//                                     singleValue: (base, state) => ({
//                                         ...base,
//                                         color: '#000000',
//                                         fontSize: '14px'
//                                     }),
//                                     control: (baseStyles, state) => ({
//                                         ...baseStyles,
//                                         borderColor: state.isFocused ? 'lightgray' : 'lightgray',
//                                         minHeight: '30px',
//                                         height: '30px',
//                                     }),
//                                     option: (base, state) => ({
//                                         ...base,
//                                         border: '0.4px solid lightgray',
//                                     }),
//                                     indicatorsContainer: (provided, state) => ({
//                                         ...provided,
//                                         height: '30px',
//                                         color: '#000000'
//                                     }),

//                                 }}
//                                 theme={(theme) => ({
//                                     ...theme,
//                                     borderRadius: 3,
//                                     colors: {
//                                         ...theme.colors,
//                                         primary25: 'rgba(197, 194, 191, 0.4)',
//                                         primary: '#c7c6c6',
//                                     },
//                                 })}

//                             />

//                         </div>

//                     </div>

//                     {/* <Products result={result} /> */}
//                     <div name="page-size" id="page-size" onChange={handlePageSizeChange}>
//                         <Products pageContent={pageContent} />
//                     </div>

//                     <div className="paginationWrapper">
//                         <Stack spacing={2}>
//                         <Pagination
//                             page={page}
//                             size="medium"
//                             className='pagination'
//                             count={totalPages}
//                             // siblingCount={-4}
//                             // boundaryCount={2}
//                             onClick={scrollToTop}
//                             onChange={(event, value) => handlePage(value)}
//                             variant="outlined" shape="rounded"
//                         ></Pagination>
//                         </Stack>
//                     </div>

//                 </div>
//             </div>


//         </div>
//     )
// }

// export default MainProductList

















