import React from "react";
import Button from './Button';
import "./subs.scss";

const SubCats = ({ cat, handleClick }) => {


    return (

        <div className="subCatsWrapper">
            {/* CLOTHINGS */}
            {cat === "Women's Clothing" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="VIEW ALL" />
                    <Button onClickHandler={handleClick} value="Women's Dresses and Jumpsuits" title="DRESSES & JUMPSUITS" />
                    <Button onClickHandler={handleClick} value="Women's Tops and Bodysuits" title="TOPS" />
                    <Button onClickHandler={handleClick} value="Women's Bottoms" title="BOTTOMS" />
                    <Button onClickHandler={handleClick} value="Women's Matching Sets" title="MATCHING SETS" />
                    <Button onClickHandler={handleClick} value="Women's Shirts and Tshirts" title="SHIRTS & TSHIRTS" />
                    <Button onClickHandler={handleClick} value="Women's Jackets and Blazers" title="JACKETS & BLAZERS" />
                </div>
            }
            {/* DRESSES */}
            {cat === "Women's Dresses and Jumpsuits" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="VIEW ALL" />
                    <Button onClickHandler={handleClick} value="Midi|Max" title="MIDI | MAX" />
                    <Button onClickHandler={handleClick} value="ShortDress" title="SHORT" />
                    <Button onClickHandler={handleClick} value="Women-Jumpsuits" title="JUMPSUITS" />
                </div>
            }
            {/* TOPS */}
            {cat === "Women's Tops and Bodysuits" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="VIEW ALL" />
                    <Button onClickHandler={handleClick} value="WomenBodysuits" title="BODYSUITS" />
                    <Button onClickHandler={handleClick} value="WomenTops" title="CROP TOPS" />
                </div>
            }
            {cat === "Women's Shirts and Tshirts" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="VIEW ALL" />
                    <Button onClickHandler={handleClick} value="WomenSatinShirt" title="SATIN" />
                    <Button onClickHandler={handleClick} value="WomenStripesShirt" title="STRIPES" />
                    <Button onClickHandler={handleClick} value="WomenTshirts" title="T-SHIRTS" />
                    <Button onClickHandler={handleClick} value="WomenPlainShirt" title="PLAIN SHIRTS" />
                </div>
            }
            {/* BOTTOMS */}
            {cat === "Women's Bottoms" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="VIEW ALL" />
                    <Button onClickHandler={handleClick} value="WomenTailored" title="TAILORED" />
                    <Button onClickHandler={handleClick} value="WomenSkirts" title="SKIRTS" />
                    <Button onClickHandler={handleClick} value="WomenShorts" title="SHORTS" />
                    <Button onClickHandler={handleClick} value="WomenCargo" title="CARGO" />
                    <Button onClickHandler={handleClick} value="WomenJeans" title="JEANS" />
                    <Button onClickHandler={handleClick} value="WomenJoggers" title="BASICS & SWEATS" />
                </div>
            }
            {/* SETS */}
            {cat === "Women's Matching Sets" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="VIEW ALL" />
                    <Button onClickHandler={handleClick} value="WomenTrouserSet" title="TROUSER-SET" />
                    <Button onClickHandler={handleClick} value="WomenSkirtSet" title="SKIRT-SET" />
                </div>
            }

            {/* JACKETS */}
            {cat === "Women's Jackets and Blazers" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="VIEW ALL" />
                    <Button onClickHandler={handleClick} value="WomenJackets" title="JACKETS" />
                    <Button onClickHandler={handleClick} value="WomenBlazers" title="BLAZERS" />
                </div>
            }

            {/* SHOES */}
            {cat === "Women's Shoes" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="VIEW ALL" />
                    <Button onClickHandler={handleClick} value="Heels" title="HEELS" />
                    <Button onClickHandler={handleClick} value="Women's Flat Shoes" title="FLAT SHOES" />
                    <Button onClickHandler={handleClick} value="Women's Sneakers" title="SNEAKERS" />
                </div>
            }
            {cat === "Heels" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="VIEW ALL" />
                    <Button onClickHandler={handleClick} value="BlockHeels" title="BLOCK HEEL" />
                    <Button onClickHandler={handleClick} value="HighHeel" title="HIGH-HEEL" />
                    <Button onClickHandler={handleClick} value="ShortMidHeel" title="SHORT | MID HEEL" />
                    <Button onClickHandler={handleClick} value="BootHeel" title="BOOTS" />
                </div>
            }
            {cat === "Women's Flat Shoes" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="VIEW ALL" />
                    <Button onClickHandler={handleClick} value="WomenLoafers" title="LOAFERS" />
                    <Button onClickHandler={handleClick} value="WomenBallerinas" title="BALLERINAS" />
                    <Button onClickHandler={handleClick} value="WomenFlatSandals" title="FLAT SANDALS" />
                </div>
            }
            {cat === "Women's Sneakers" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="VIEW ALL" />
                    <Button onClickHandler={handleClick} value="WomenTrainers" title="TRAINERS" />
                    <Button onClickHandler={handleClick} value="WomenSneakers" title="SNEAKERS" />
                </div>
            }

            {/* ACCESSORIES */}
            {cat === "Women's Accessories" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="VIEW ALL" />
                    <Button onClickHandler={handleClick} value="Women's Belts" title="BELTS" />
                    <Button onClickHandler={handleClick} value="Women's Wallets" title="WALLETS" />
                    <Button onClickHandler={handleClick} value="Women's Scarves" title="SCARVES" />
                </div>
            }
            {cat === "Women's Belts" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="VIEW ALL" />
                    <Button onClickHandler={handleClick} value="WomenNarrowBelts" title="NARROW BELT" />
                    <Button onClickHandler={handleClick} value="WomenLeatherBelt" title="LEATHER BELT" />
                    <Button onClickHandler={handleClick} value="WomenPlainBelt" title="PLAIN BELT" />
                </div>
            }
            {cat === "Women's Wallets" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="VIEW ALL" />
                    <Button onClickHandler={handleClick} value="WomenCardHolder" title="CARD HOLDER" />
                    <Button onClickHandler={handleClick} value="WomenWallets" title="WALLETS" />
                </div>
            }
            {cat === "Women's Scarves" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="VIEW ALL" />
                    <Button onClickHandler={handleClick} value="SatinScarf" title="SATIN" />
                    <Button onClickHandler={handleClick} value="CottonScarf" title="COTTON | POLYSTER" />
                </div>
            }

            {/* LAUNCH SALE */}
            {cat === "Women's Launch Sale" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="VIEW ALL" />
                    <Button onClickHandler={handleClick} value="Tunjoo Women-MatchingSets" title="TUNJOO MATCHING SETS" />
                    <Button onClickHandler={handleClick} value="Tunjoo Women-Tshirts" title="TUNJOO WOMEN'S TSHIRTS" />
                    <Button onClickHandler={handleClick} value="Tunjoo Women-HoodiesSweatshirts" title="TUNJOO HOODIES & SWEARTSHIRTS" />
                    <Button onClickHandler={handleClick} value="Tunjoo Women-AllDenim" title="TUNJOO WOMEN ALL DENIM" />
                </div>
            }

            {/* HANDBAGS */}
            {cat === "Handbags" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="VIEW ALL" />
                    <Button onClickHandler={handleClick} value="Party Purse" title="PARTY PURSE" />
                    <Button onClickHandler={handleClick} value="Mini Tote and CrossBag" title="MINI-TOTE | CROSS BAG" />
                    <Button onClickHandler={handleClick} value="ToteHandbag" title="TOTE HANDBAG"/>
                    </div>
            }
            {cat === "Party Purse" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="VIEW ALL" />
                    <Button onClickHandler={handleClick} value="Glitter" title="RHINESTONES"/>
                    <Button onClickHandler={handleClick} value="Velvet" title="VELVET" />
                    <Button onClickHandler={handleClick} value="Plain" title="PLAIN" />
                    </div>
            }
            {cat === "Mini Tote and CrossBag" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="VIEW ALL" />
                    <Button onClickHandler={handleClick} value="MiniTote" title="MINI TOTE"/>
                    <Button onClickHandler={handleClick} value="CrossBag" title="CROSS BAG" />
                    </div>
            }
            {cat === "Tote Handbag" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="WorkToteBag" title="WORK TOTE BAG"/>
                    <Button onClickHandler={handleClick} value="BrunchToteBag" title="BRUNCH TOTE BAG" />
                </div>
            }


            {/* ******** SALE ******** */}
            {/* GIRLS SALE */}
            {cat === "Girls' Sale" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="ALL PRODUCTS" />
                    <Button onClickHandler={handleClick} value="Girls-SaleMatchingSets" title="MATCHING SETS" />
                    <Button onClickHandler={handleClick} value="Grils-SaleHoodiesSweatshirts" title="HOODIES | SWEATSHIRTS" />
                </div>
            }

            {/* BOYS SALE */}
            {cat === "Boys' Sale" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="ALL PRODUCTS" />
                    <Button onClickHandler={handleClick} value="Boys-SaleMatchingSets" title="MATCHING SETS" />
                    <Button onClickHandler={handleClick} value="Boys-SaleHoodiesSweatshirts" title="HOODIES | SWEATSHIRTS" />
                </div>
            }

            {/* MEN'S SALE */}
            {cat === "Men's Sale" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="ALL PRODUCTS" />
                    <Button onClickHandler={handleClick} value="Men-SaleMatchingSets" title="MATCHING SETS" />
                    <Button onClickHandler={handleClick} value="Men-SaleHoodiesSweatshirts" title="HOODIES | SWEATSHIRTS" />
                </div>
            }


            {/* WOMEN'S SALE */}
            {cat === "Women's Sale" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="ALL PRODUCTS" />
                    <Button onClickHandler={handleClick} value="Women-SaleMatchingSets" title="MATCHING SETS" />
                    <Button onClickHandler={handleClick} value="Women-SaleHoodiesSweatshirts" title="HOODIES | SWEATSHIRTS" />
                    <Button onClickHandler={handleClick} value="Women-SaleHandbags" title="HANDBAGS" />
                </div>
            }





           
           



        </div>


    );
};

export default SubCats;

