import axios from "axios";

const base_url = "https://tunjoo-server-36b3be406a89.herokuapp.com"

const newRequest = axios.create({
  baseURL: base_url,
  withCredentials: true,
});

export default newRequest;

