import React, { useState } from 'react';
import './type.scss';
// import { RiMotorbikeFill } from "react-icons/ri";
// import moment from 'moment';
import { GiTakeMyMoney } from "react-icons/gi";
// import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { MdAddCircleOutline } from "react-icons/md";
import NewAddress from './addAddress/NewAddress';
import { IoIosUnlock } from "react-icons/io";
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';


const Express = ({ amount }) => {

    const currentUser = JSON.parse(localStorage.getItem("currentUser"));

    const [openAddressModal, setOpenAddressModal] = useState(false);
    /// Edit Profile modal
    const AddressModal = ({ openAddress, closeAddressModal }) => {
        if (!openAddress) return null;
        return (
            <div onClick={closeAddressModal} className='Overlay'>
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    className="Modal"
                >
                    <NewAddress />
                    <button className='closeBtn' onClick={closeAddressModal}>
                        <p>Close</p>
                    </button>
                </div>
            </div>
        );
    };


    // // ----------- Radio Filtering -----------
    const [selectedAddress, setSelectedAddress] = useState("DEFAULT");

    // // ----------- Flutterwave Payment Gateway -----------
    const fullname = currentUser.firstname + " " + currentUser.lastname;
    const config = {
        public_key: 'FLWPUBK-497d0bed055b6ee9ff10535318eff276-X',
        tx_ref: Date.now(),
        amount: amount,
        currency: "UGX",
        country: "UG",
        payment_options: "card, mobilemoneyuganda, ussd",

        // specified redirect URL
        redirect_url: "http://localhost:3000/response",

        // use customer details if user is not logged in, else add user_id to the request
        customer: {
            email: currentUser.email,
            phone_number: currentUser.phone,
            name: fullname,
        },
        callback: function (data) {
            console.log(data);
        },
        onclose: function () {
            // close modal
        },
        meta: {
            consumer_mac: selectedAddress,
        },
        customizations: {
            title: "TUNJOO.com",
            description: "High Fashion",
            logo: "https://res.cloudinary.com/tunjooadmin/image/upload/v1706613421/tunjooapp/Copy_of_tunjoo._b4bteh.png",
        },
    };

    const handleFlutterPayment = useFlutterwave(config);

    console.log(config.customer.email)
    console.log(currentUser)



    return (
        // <div className='deliveryType'>
        //    <div className="item">
        //       <RiMotorbikeFill className='typeIcon' />
        //       <span>Fast, Quick, Timely with in 24 hours</span>
        //    </div>
        // </div>

        <div className='deliveryType'>
            <div className="item1">
                <GiTakeMyMoney className='typeIcon' />
                <span>Standard Shipping</span>
            </div>
            <div className="item2">
                <span>You order will arrive within </span>
                <h4>24hours</h4>
                <span>from the time it was placed</span>
                {/* <h4>{addDays(2)}</h4> */}
            </div>
            <div className="addressInfo">
                <h3>Select your shipping address</h3>

                <div className="addresses">
                    <div className="left">
                        <p>Default Address:</p>
                        <button className='addressBtn1' onClick={(e) => setSelectedAddress("DEFAULT")}>
                            <span>{currentUser.firstname + " " + currentUser.lastname}</span>
                            <span>{currentUser.address}</span>
                            <span>{currentUser.city + ", "  + currentUser.country}</span>
                            <span>Mobile Number +{currentUser.phone}</span>
                        </button>
                    </div>
                    <div className="right">
                        {currentUser?.newAddress?.newStreet === ""
                            ?
                            <button className="add" onClick={() => setOpenAddressModal(true)}>
                                <MdAddCircleOutline className='addIcon' />
                                <p>Add Current Delivery Address</p>
                            </button>
                            :
                            <>

                                <p>Current Delivery Address:</p>

                                <button className='addressBtn1' onClick={(e) => setSelectedAddress("CURRENT")}>
                                    <span>{currentUser.firstname + " " + currentUser.lastname}</span>
                                    <span>{currentUser?.newAddress?.newStreet}</span>
                                    <span>{currentUser?.newAddress?.newCity + ", " + currentUser?.newAddress?.newCountry}</span>
                                    <span>Mobile Number +{currentUser.phone}</span>
                                </button>
                            </>
                        }
                    </div>

                </div>
            </div>

            <div className="paymentWrapper">
                <span>By continuing to checkout you agree to our <Link className="termsLink" to="/terms-and-conditions"> Terms and Conditions. </Link></span>

                {/* <button className={selectedAddress ? "payBtn1" : "payBtn2"} disabled={!selectedAddress}>
              <IoIosUnlock className="cartIcon" />
              <span>{selectedAddress ? "PROCEED TO PAY" : "SELECT DELIVERY ADDRESS"}</span>
          </button> */}

                <button
                    className={selectedAddress ? "payBtn1" : "payBtn2"} disabled={!selectedAddress}
                    onClick={() => {
                        handleFlutterPayment({
                            callback: (response) => {
                                console.log(response);
                                closePaymentModal() // this will close the modal programmatically
                            },
                            onClose: () => { },
                        });
                    }}
                >
                    <IoIosUnlock className="cartIcon" />
                    <span>{selectedAddress ? "PROCEED TO PAY" : "SELECT DELIVERY ADDRESS"}</span>
                </button>

            </div>


            <AddressModal
                openAddress={openAddressModal}
                closeAddressModal={() => setOpenAddressModal(false)}
            />

        </div>

    )
}

export default Express