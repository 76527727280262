import React from 'react';
import './adminComps.scss';

const AdminOrders = () => {
	return (
		<div className='adminOrders'>
			AdminOrders
		</div>
	)
}

export default AdminOrders