import React from 'react';
import './brandItem.scss';



const BrandProducts = ({result}) => {
 
    return (

        <div className='brandPdtWrapper'>

            {result}

        </div>

    )
}

export default BrandProducts















// import React, { useEffect, useState } from 'react';
// import './brandItem.scss';
// import BrandItem from './BrandItem';
// import newRequest from '../../../config/newRequest';
// import { Link } from 'react-router-dom';


// const BrandProducts = ({ brand, filters, sort, selectedCategory, selected}) => {
//     //FETCH PRODUCTS
//     const [products, setProducts] = useState([]);
//     const [filteredProducts, setFilteredProducts] = useState([]);

//     useEffect(() => {
//         const getProducts = async () => {
//             try {
//                 const res = await newRequest(`/api/products/productBrand?brand=${brand}`);
//                 setProducts(res.data);

//             } catch (err) { }
//         };
//         getProducts();

//     }, [brand]);

//     useEffect(() => {
//             setFilteredProducts(
//                 products.filter((item) =>
//                     Object.entries(filters).every(([key, value]) =>
//                         item[key].includes(value)
//                     )

//                 )
//             );

//     }, [products, brand, filters]);


//     // if (selected) {
//     //     filteredProducts = filteredProducts.filter(
//     //         ({ brand, color, size, subCat, mainCat }) =>
//     //             brand === selected ||
//     //             color === selected ||
//     //             size === selected ||
//     //             subCat === selected ||
//     //             mainCat === selected
//     //     );
//     // }

//     // useEffect(() => {
//     //     const filteredList = products.filter((product) => 
//     //     product.mainCat === selectedCategory );
//     //     setFilteredProducts(filteredList);
//     // },[products, selectedCategory])

   
//     useEffect(() => {
//         if ((sort === "newest")) {
//             setFilteredProducts((prev) =>
//                 [...prev].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
//             );
//         } else if (sort === "asc") {
//             setFilteredProducts((prev) =>
//                 [...prev].sort((a, b) => a.sellingPrice - b.sellingPrice)
//             );
//         }else {
//             setFilteredProducts((prev) =>
//                 [...prev].sort((a, b) => b.sellingPrice - a.sellingPrice)
//             );
//         }

//     }, [sort])


//     return (

//         <div className='brandPdtWrapper'>

//             {/* <p>{products.length}</p> */}

//             {filteredProducts?.map((item) => (
//                 <Link to={`/${item.group}/${item.mainCat}/${item._id}`} style={{textDecoration: 'none', color:'inherit'}}>
//                     <BrandItem item={item} key={Math.random()} />  
//                 </Link>
//             ))}

//         </div>

//     )
// }

// export default BrandProducts