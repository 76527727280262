import React, { useState } from 'react';
import '../responseNav.scss';
import { Link } from 'react-router-dom';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { FaHome } from "react-icons/fa";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";


const ResWomen = ({ sidebar, setSidebar }) => {

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    const clothingItems =[
        {
            id: 1,
            group: "Women",
            title: 'All Clothing',
            itemCat: "Clothing",
            cat: "Women's-Clothing",
            cName: 'dropdown-link2'
        },
        {
            id: 2,
            group: "Women",
            itemCat: "Clothing",
            title: 'Dresses & Jumpsuits',
            cat: "Women's-Dresses-and-Jumpsuits",
            cName: 'dropdown-link'
        },
        {
            id: 3,
            group: "Women",
            itemCat: "Clothing",
            title: 'Tops & Bodysuits',
            cat: "Women's-Tops-and-Bodysuits",
            cName: 'dropdown-link'
        },
        {
            id: 4,
            group: "Women",
            itemCat: "Clothing",
            title: 'Shirts & Tshirts',
            cat: "Women's-Shirts-and-Tshirts",
            cName: 'dropdown-link'
        },
        {
            id: 5,
            group: "Women",
            title: 'Bottoms',
            itemCat: "Clothing",
            cat: "Women's-Bottoms",
            cName: 'dropdown-link'
        },
        {
            id: 6,
            group: "Women",
            itemCat: "Clothing",
            title: 'Matching Sets',
            cat: "Women's-Matching-Sets",
            cName: 'dropdown-link'
        },
        {
            id: 7,
            group: "Women",
            itemCat: "Clothing",
            title: "Jackets & Blazers",
            cat: "Women's-Jackets-and-Blazers",
            cName: 'dropdown-link'
        },
    ]

    const shoeItems =[
        {
            id: 1,
            group: "Women",
            title: 'All Shoes',
            itemCat: "Shoes",
            cat: "Women's-Shoes",
            cName: 'dropdown-link2'
        },
        {
            id: 2,
            group: "Women",
            title: 'Heels',
            cat: "Heels",
            itemCat: "Shoes",
            cName: 'dropdown-link'
        },
        {
            id: 3,
            group: "Women",
            itemCat: "Shoes",
            title: "Women's Flat Shoes",
            cat: "Women's-Flat-Shoes",
            cName: 'dropdown-link'
        },
        {
            id: 4,
            group: "Women",
            title: 'Sneakers',
            itemCat: "Shoes",
            cat: "Women's-Sneakers",
            cName: 'dropdown-link'
        },
    ]

    const accItems = [
        {
            id: 1,
            group: "Women",
            itemCat: "Accessories",
            title: 'All Accessories',
            cat: "Women's-Accessories",
            cName: 'dropdown-link2'
        },
        {
            id: 2,
            group: "Women",
            title: 'Belts',
            itemCat: "Accessories",
            cat: "Women's-Belts",
            cName: 'dropdown-link'
        },
        {
            id: 3,
            group: "Women",
            title: 'Wallets',
            itemCat: "Accessories",
            cat: "Women's-Wallets",
            cName: 'dropdown-link'
        },
        {
            id: 5,
            group: "Women",
            title: 'Scarves',
            itemCat: "Accessories",
            cat: "Women's-Scarves",
            cName: 'dropdown-link'
        },
    ]

    const bagItems = [
        {
            id: 1,
            group: "Women",
            title: 'All Bags',
            cat: 'Handbags',
            itemCat: "Handbag",
            cName: 'dropdown-link2'
        },
        {
            id: 2,
            group: "Women",
            title: 'Party Purse',
            cat: 'Party-Purse',
            itemCat: "Handbag",
            cName: 'dropdown-link'
        },
        {
            id: 3,
            group: "Women",
            itemCat: "Handbag",
            title: 'Mini-Tote | Cross Bag',
            cat: 'Mini-Tote-and-CrossBag',
            cName: 'dropdown-link'
        },
        {
            id: 4,
            group: "Women",
            itemCat: "Handbag",
            title: 'Tote Handbag',
            cat: 'Tote-Handbag',
            cName: 'dropdown-link'
        },
    ]

    const brandItems = [
        {
            id: 1,
            group: "Women",
            title: 'All Brands',
            cat: 'All-Brands',
            cName: 'dropdown-link2'
        },
        {
            id: 2,
            group: "Women",
            title: 'Zara',
            itemCat: "brands",
            cat: 'ZARA-WOMAN',
            cName: 'dropdown-link'
        },
        {
            id: 3,
            group: "Women",
            title: 'H&M',
            itemCat: "brands",
            cat: 'H&M-WOMAN',
            cName: 'dropdown-link'
        },
        {
            id: 4,
            group: "Women",
            title: 'Aldo',
            itemCat: "brands",
            cat: 'ALDO-WOMAN',
            cName: 'dropdown-link'
        },
        {
            id: 5,
            group: "Women",
            title: 'Guess',
            itemCat: "brands",
            cat: 'GUESS-WOMAN',
            cName: 'dropdown-link'
        },
        {
            id: 6,
            group: "Women",
            title: 'Steve Madden',
            itemCat: "brands",
            cat: 'STEVE-MADDEN-WOMAN',
            cName: 'dropdown-link'
        },
        {
            id: 7,
            group: "Women",
            title: 'Nine West',
            itemCat: "brands",
            cat: 'NINE-WEST',
            cName: 'dropdown-link'
        },
        {
            id: 8,
            group: "Women",
            title: 'Mango',
            itemCat: "brands",
            cat: 'MANGO-WOMAN',
            cName: 'dropdown-link',
        },
        {
            id: 9,
            group: "Women",
            itemCat: "brands",
            title: 'Beverly Hills Polo Club',
            cat: 'BEVERLY-HILLS-POLO-CLUB',
            cName: 'dropdown-link',
        },
    ]



    const [openItem, setOpenItem] = useState(false);
    const [openShoes, setOpenShoes] = useState(false);
    const [openBags, setOpenBags] = useState(false);
    const [openAcc, setOpenAcc] = useState(false);
    const [openBrands, setOpenBrands] = useState(false);

    return (

        <ul className='resLinksWrapper'>


            <li onClick={sidebar}>
                <Link to="/" className='women-home-item' onClick={scrollToTop}>
                    <FaHome className='sideHomeIcon' />
                    <span>WOMEN'S HOME PAGE</span>
                </Link>
            </li>

            {/* CLOTHING */}
            <li className="sideLinks" onClick={() => setOpenItem(!openItem)}>
                <span>CLOTHING</span>
                {openItem ? <IoIosArrowUp className='dropIcon' /> : <IoIosArrowDown className='dropIcon' />}
            </li>
            {openItem && (
                <ul className="options22" onClick={() => setOpenItem(!openItem)}>

                   {clothingItems.map((item) =>(
                        <li onClick={() => setSidebar(false)}>
                            <Link to={`/${item.group}/${item.itemCat}/${item.cat}`} className="sideLink2" onClick={scrollToTop}>
                                <p>{item.title}</p>
                                <MdKeyboardArrowRight className='dropIcon' />
                            </Link>
                        </li>
                    ))}


                </ul>
            )}

            {/* SHOES */}
            <li className="sideLinks" onClick={() => setOpenShoes(!openShoes)}>
                <span>SHOES</span>
                {openShoes ? <IoIosArrowUp className='dropIcon' /> : <IoIosArrowDown className='dropIcon' />}
            </li>
            {openShoes && (
                <ul className="options22" onClick={() => setOpenShoes(!openShoes)}>

                   {shoeItems.map((item) =>(
                        <li onClick={() => setSidebar(false)}>
                            <Link to={`/${item.group}/${item.itemCat}/${item.cat}`} className="sideLink2" onClick={scrollToTop}>
                                <p>{item.title}</p>
                                <MdKeyboardArrowRight className='dropIcon' />
                            </Link>
                        </li>
                    ))}


                </ul>
            )}

            {/* BAGS */}
            <li className="sideLinks" onClick={() => setOpenBags(!openBags)}>
                <span>HANDBAGS</span>
                {openBags ? <IoIosArrowUp className='dropIcon' /> : <IoIosArrowDown className='dropIcon' />}
            </li>
            {openBags && (
                <ul className="options22" onClick={() => setOpenBags(!openBags)}>

                   {bagItems.map((item) =>(
                        <li onClick={() => setSidebar(false)}>
                            <Link to={`/${item.group}/${item.itemCat}/${item.cat}`} className="sideLink2" onClick={scrollToTop}>
                                <p>{item.title}</p>
                                <MdKeyboardArrowRight className='dropIcon' />
                            </Link>
                        </li>
                    ))}


                </ul>
            )}

            {/* ACCESSORIES */}
            <li className="sideLinks" onClick={() => setOpenAcc(!openAcc)}>
                <span>ACCESSORIES</span>
                {openAcc ? <IoIosArrowUp className='dropIcon' /> : <IoIosArrowDown className='dropIcon' />}
            </li>
            {openAcc && (
                <ul className="options22" onClick={() => setOpenAcc(!openAcc)}>

                   {accItems.map((item) =>(
                        <li onClick={() => setSidebar(false)}>
                            <Link to={`/${item.group}/${item.itemCat}/${item.cat}`} className="sideLink2" onClick={scrollToTop}>
                                <p>{item.title}</p>
                                <MdKeyboardArrowRight className='dropIcon' />
                            </Link>
                        </li>
                    ))}


                </ul>
            )}

            {/* BRANDS */}
            <li className="sideLinks" onClick={() => setOpenBrands(!openBrands)}>
                <span>BRANDS</span>
                {openBrands ? <IoIosArrowUp className='dropIcon' /> : <IoIosArrowDown className='dropIcon' />}
            </li>
            {openBrands && (
                <ul className="options22" onClick={() => setOpenBrands(!openBrands)}>

                   {brandItems.map((item) =>(
                        <li onClick={() => setSidebar(false)}>
                            <Link to={`/${item.group}/${item.itemCat}/${item.cat}`} className="sideLink2" onClick={scrollToTop}>
                                <p>{item.title}</p>
                                <MdKeyboardArrowRight className='dropIcon' />
                            </Link>
                        </li>
                    ))}


                </ul>
            )}


        </ul>

    )
}

export default ResWomen










// import React from 'react';
// import '../responseNav.scss';
// import { sideWomenItems } from '../../../nav-data';
// import { Link, NavLink } from 'react-router-dom';
// import { MdKeyboardArrowRight } from 'react-icons/md';
// import { FaHome } from "react-icons/fa";

// const ResWomen = ({ sidebar, setSidebar }) => {

//     const scrollToTop = () => {
//         window.scrollTo(0, 0)
//     }

//     return (

//         <ul className='resLinksWrapper'>


//             <li onClick={sidebar}>
//                 <Link to="/" className='women-home-item' onClick={scrollToTop}>
//                     <FaHome className='sideHomeIcon' />
//                     <span>WOMEN'S HOME PAGE</span>
//                 </Link>
//             </li>


//             {sideWomenItems.map((item) => (

//                 <div onClick={() => setSidebar(false)}>
//                     <NavLink
                        // to={`/${item.group}/${item.itemCat}/${item.cat}`}
                        // className="sideLinks"
                        // key={item.id}
                        // onClick={scrollToTop}
//                     >
//                         {item.title ? <p>{item.title}</p> : <h4>{item.sale}</h4>}
//                         <MdKeyboardArrowRight />
//                     </NavLink>

//                 </div>

//             ))}
//         </ul>

//     )
// }

// export default ResWomen