import React, { useEffect, useState } from 'react';
import './cart.scss';
import { Link } from 'react-router-dom';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { HiPlusSm, HiMinusSm } from 'react-icons/hi';
// import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
// import WomenNav from '../components/navbar/women/WomenNav';
import { MdPhone, MdOutlineMail } from "react-icons/md";
import { GiReturnArrow } from "react-icons/gi";
import { decreaseItem, increaseItem, removeItem } from '../redux/cartSlice';
import { useDispatch, useSelector } from 'react-redux';
// import { cartTotalSelector } from '../redux/cartSlice';
import { IoIosArrowDropleftCircle } from "react-icons/io";
import axios from 'axios';
import newRequest from '../config/newRequest';
import ResponseNav from '../components/res-nav/ResponseNav';
// import MenNav from '../components/navbar/men/MenNav';
// import KidsNav from '../components/navbar/kids/KidsNav';

const UserCart = () => {


    // const location = useLocation();
    // const group = location.pathname.split("/")[2];

    // ----------- FOR  USERS -----------
    // const currentUser = useSelector((state) => state.user.currentUser);
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    // const savedCart = JSON.parse(localStorage.getItem("savedCart"));


    const [userCart, setUserCart] = useState({});
    useEffect(() => {
        const getUserCart = async () => {
            try {
                const res = await axios.get(`https://tunjoo-server-36b3be406a89.herokuapp.com/api/carts/find/${currentUser?._id}`);
                setUserCart(res.data);
                localStorage.setItem("savedCart", JSON.stringify(res.data));
            } catch (err) { }
        };
        getUserCart();
    }, [currentUser?._id]);

    const handleIncreaseQuantity = async (id) => {
        try {
            const res = await newRequest.put(`/api/carts/increaseProduct`, {
                userId: currentUser._id,
                itemId: id,
            });
            window.location.reload();
            localStorage.setItem("userCart", JSON.stringify(res.data));
        } catch (err) { }

    };

    const handleDecreaseQuantity = async (id) => {
        try {
            const res = await newRequest.put(`/api/carts/decreaseProduct`, {
                userId: currentUser._id,
                itemId: id,
            });
            window.location.reload();
            // localStorage.setItem("userCart", JSON.stringify(res.data));
            return res.data;
        } catch (err) { }

    };

    const handleRemoveItem = async (id) => {
        try {
            const res = await newRequest.put(`/api/carts/deleteItem`, {
                userId: currentUser._id,
                itemId: id,
            });
            window.location.reload();
            localStorage.setItem("userCart", JSON.stringify(res.data));
        } catch (err) { }

    };

    // ----------- FOR NON USERS -----------
    const cart = useSelector((state) => state.cart);
    const cartItems = useSelector((state) => state.cart.cartItems);
    // const cartTotal = useSelector(cartTotalSelector);
    const dispatch = useDispatch();

    const subTotal = () => {
        let total = 0;
        cartItems.forEach((item) => {
            total += item.quantity * (item.discountPrice ? item.discountPrice : item.sellingPrice);
        });
        return total.toLocaleString(undefined, { minimumFractionDigits: 2 })
    };



    return (

        <>
            {currentUser ?
                <div className='cart'>

                    <ResponseNav />

                    <hr className='cartHr'/>

                    <div className="cartCover">

                        <h1>MY BAG</h1>
                        {/* <p>Continue Shopping</p> */}

                        {userCart?.products?.length === 0 ? (
                            <div className="emptyWrapper">
                                <div className="emptyText">
                                    <h3>Your cart is currently empty</h3>
                                    <Link className='emptylink' to="/Women/Clothing/Women's-Clothing">
                                        <div className="shopping">
                                            <IoIosArrowDropleftCircle className='emptyIcon' />
                                            <p>Start Shopping</p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        ) : (

                            <div className="bottom">

                                <div className="info">

                                    <div className="productHeading">
                                        <div className='productTitle'>PRODUCT</div>
                                        <div className='productEdit'>QUANTITY</div>
                                        <div className='productPrice'>TOTAL</div>
                                    </div>

                                    {userCart?.products?.map((product, index) => (
                                        <div className="productItem">
                                            <div className="product">
                                                <div className="productDetail">

                                                    <img src={product?.image} alt="" />

                                                    <div className="details">

                                                        <div className="detailsTop">
                                                            <span className='productName'>{product?.brand}</span>

                                                            <div className="pdtDetailsItem1">
                                                                <p>{product?.productCode}</p>
                                                                <p>{product?.productName}</p>
                                                            </div>

                                                            <div className="pdtDetailsItem2">
                                                                <p>{product?.productSize}</p>
                                                                <span className='divider' />
                                                                <p>{product?.color}</p>
                                                            </div>
                                                        </div>

                                                        {/* <div className='pdtDelete' onClick={() => dispatch(removeItem(product.index))}> */}
                                                        <div className='pdtDelete' onClick={() => handleRemoveItem(product.itemId)}>
                                                            <RiDeleteBin6Line className='cartDelIcon' />
                                                            <p>Remove</p>
                                                        </div>

                                                    </div>

                                                </div>


                                                <div className="qttyDetails">
                                                    <div className="qttyContainer">
                                                        <div className="qttyPrice">
                                                            {product?.discountPrice ?
                                                                <div className="qttyWrapper1">
                                                                    <p>UGX</p>
                                                                    <h4 className='pdtPrice'>{product?.productPrice?.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</h4>
                                                                </div>
                                                                :
                                                                <div className="qttyWrapper2">
                                                                    <p>UGX</p>
                                                                    <h4 className='pdtPrice'>{product?.productPrice?.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</h4>
                                                                </div>
                                                            }
                                                        </div>

                                                        <div className="quantity">
                                                            <button onClick={() => handleDecreaseQuantity(product?.itemId)}>
                                                                <HiMinusSm className='qttyIcon' />
                                                            </button>
                                                            <span>{product?.productQuantity}</span>
                                                            <button onClick={() => handleIncreaseQuantity(product?.itemId)}>
                                                                <HiPlusSm className='qttyIcon' />
                                                            </button>
                                                        </div>

                                                        {/* //////////////////////////// */}

                                                        {/* <div className='pdtDelete'>
                                            <RiDeleteBin6Line />
                                            Remove
                                        </div> */}
                                                    </div>

                                                </div>

                                                <div className="priceDetail">
                                                    {/* <div className="amtContainer"> */}
                                                    <p>UGX</p>
                                                    <h4>{product?.productTotal?.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</h4>
                                                    {/* </div> */}
                                                </div>

                                            </div>

                                            <div className="mobileTotal">
                                                <h2>Total Price</h2>
                                                <h2>UGX: {product?.productTotal?.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</h2>
                                            </div>

                                        </div>
                                    ))}

                                </div>

                                {/* toLocaleString(undefined, { minimumFractionDigits: 2 }) */}

                                <div className="summary">
                                    <h2 className='summaryTitle'>ORDER SUMMARY</h2>

                                    <div className="summaryItem">
                                        <span>Subtotal:</span>
                                        <div className="summaryItemWrapper">
                                            <p>UGX</p>
                                            {/* <h4>{totalPrice()}</h4> */}
                                            <h4>{userCart?.totalAmount?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</h4>
                                        </div>
                                    </div>

                                    <Link to="/quickCheckout">
                                        <button className='summaryBtn'>
                                            PROCEED TO CHECKOUT
                                        </button>
                                    </Link>

                                    <div className="paymentIcons">
                                        <span className='paymentTitle'>WE ACCEPT:</span>
                                        <div className="paymentDiv">
                                            <img
                                                src="/images/visa.png"
                                                alt=""
                                                className='image1'
                                            />
                                            <img
                                                src="/images/mastercard.png"
                                                alt=""
                                                className='image1'
                                            />
                                            <img
                                                src="/images/mtn.png"
                                                alt=""
                                                className='image1'
                                            />
                                            <img
                                                src="images/airtel.png"
                                                alt=""
                                                className='image2'
                                            />

                                        </div>
                                    </div>



                                </div>

                            </div>

                        )}

                        <div className="cartFooter">
                            <h3>Need help? We're here for you.</h3>
                            <div className="cartFooterWrapper">
                                <div className="item">
                                    <MdPhone className='itemIcon' />
                                    <span>Call Customer Care for support at  +256 775326157</span>
                                </div>
                                <div className="item">
                                    <MdOutlineMail className='itemIcon' />
                                    <span>Write to us; support@tunjoo.com</span>
                                </div>
                                <div className="item">
                                    <GiReturnArrow className='itemIcon' />
                                    <span>Read our
                                        <Link to="/tunjoo-return-policy">Return Policy</Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                :

                //NO LOGGEDIN USER

                <div className='cart'>

                    <ResponseNav />

                    <hr  className='cartHr'/>

                    <div className="cartCover">

                        <h1>MY BAG</h1>

                        {cart.cartItems?.length === 0 ? (
                            <div className="emptyWrapper">
                                <div className="emptyText">
                                    <h3>Your cart is currently empty</h3>
                                    <Link className='emptylink' to="/Women/Clothing/Women's-Clothing">
                                        <div className="shopping">
                                            <IoIosArrowDropleftCircle className='emptyIcon' />
                                            <p>Start Shopping</p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        ) : (

                            <div className="bottom">

                                <div className="info">

                                    <div className="productHeading">
                                        <div className='productTitle'>PRODUCT</div>
                                        <div className='productEdit'>QUANTITY</div>
                                        <div className='productPrice'>TOTAL</div>
                                    </div>

                                    {cartItems?.map((product, index) => (
                                        <div className="productItem">
                                            <div className="product">
                                                <div className="productDetail">

                                                    <img src={product?.photos[0]} alt="" />

                                                    <div className="details">

                                                        <div className="detailsTop">
                                                            <span className='productName'>{product?.brand}</span>

                                                            <div className="pdtDetailsItem1">
                                                                <p>{product?.productCode}</p>
                                                                <p>{product?.productName}</p>
                                                            </div>

                                                            <div className="pdtDetailsItem2">
                                                                <p>{product?.size}</p>
                                                                <span className='divider' />
                                                                <p>{product?.color}</p>
                                                            </div>
                                                        </div>

                                                        {/* <div className='pdtDelete' onClick={() => dispatch(removeItem(product.index))}> */}
                                                        <div className='pdtDelete' onClick={() => dispatch(removeItem(product._id + product.size))}>
                                                            <RiDeleteBin6Line className='cartDelIcon' />
                                                            <p>Remove</p>
                                                        </div>

                                                    </div>

                                                </div>


                                                <div className="qttyDetails">
                                                    <div className="qttyContainer">
                                                        <div className="qttyPrice">
                                                            {product.discountPrice ?
                                                                <div className="qttyWrapper1">
                                                                    <p>UGX</p>
                                                                    <h4 className='pdtPrice'>{product.discountPrice.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</h4>
                                                                </div>
                                                                :
                                                                <div className="qttyWrapper2">
                                                                    <p>UGX</p>
                                                                    <h4 className='pdtPrice'>{product.sellingPrice.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</h4>
                                                                </div>
                                                            }
                                                        </div>

                                                        <div className="quantity">
                                                            <button onClick={() => dispatch(decreaseItem(product._id + product.size))}>
                                                                <HiMinusSm className='qttyIcon' />
                                                            </button>
                                                            <span>{product.quantity}</span>
                                                            <button onClick={() => dispatch(increaseItem(product._id + product.size))}>
                                                                <HiPlusSm className='qttyIcon' />
                                                            </button>
                                                        </div>

                                                    </div>

                                                </div>

                                                <div className="priceDetail">
                                                    {/* <div className="amtContainer"> */}
                                                    <p>UGX</p>
                                                    <h4>{product.discountPrice ? (product.discountPrice * product.quantity)?.toLocaleString(navigator.language, { minimumFractionDigits: 0 }) : (product.sellingPrice * product.quantity)?.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</h4>
                                                    {/* </div> */}
                                                </div>

                                            </div>

                                            <div className="mobileTotal">
                                                <h2>Total Price</h2>
                                                <h2>UGX: {product?.discountPrice ? (product.discountPrice * product.quantity)?.toLocaleString(navigator.language, { minimumFractionDigits: 0 }) : (product.sellingPrice * product.quantity)?.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</h2>
                                            </div>

                                        </div>
                                    ))}

                                </div>

                                {/* toLocaleString(undefined, { minimumFractionDigits: 2 }) */}

                                <div className="summary">
                                    <h2 className='summaryTitle'>ORDER SUMMARY</h2>
                                    {/* <div className="summaryItem">
                        <span>Sub Total</span>
                        <span>{subTotal()}</span>
                    </div> */}
                                    {/* <div className="summaryItem">
                        <span>Delivery Cost</span>
                        <span>{shippingCost.toLocaleString(undefined, { minimumFractionDigits: 2 })}</span>
                    </div>
                    <div className="summaryItem">
                        <span>Product Discount</span>
                        <span>0.00</span>
                    </div> */}
                                    <div className="summaryItem">
                                        <span>Subtotal:</span>
                                        <div className="summaryItemWrapper">
                                            <p>UGX</p>
                                            <h4>{subTotal()}</h4>
                                            {/* <h4>{cartTotal.toLocaleString(undefined, { minimumFractionDigits: 2 })}</h4> */}
                                        </div>
                                    </div>

                                    <Link to="/login">
                                        <button className='summaryBtn'>
                                            LOGIN TO CHECKOUT
                                        </button>
                                    </Link>

                                    <div className="paymentIcons">
                                        <span className='paymentTitle'>WE ACCEPT:</span>
                                        <div className="paymentDiv">
                                            <img
                                                src="/images/visa.png"
                                                alt=""
                                                className='image1'
                                            />
                                            <img
                                                src="/images/mastercard.png"
                                                alt=""
                                                className='image1'
                                            />
                                            <img
                                                src="/images/mtn.png"
                                                alt=""
                                                className='image1'
                                            />
                                            <img
                                                src="images/airtel.png"
                                                alt=""
                                                className='image2'
                                            />

                                        </div>
                                    </div>



                                </div>

                            </div>

                        )}

                        <div className="cartFooter">
                            <h3>Need help? We're here for you.</h3>
                            <div className="cartFooterWrapper">
                                <div className="item">
                                    <MdPhone className='itemIcon' />
                                    <span>Call Customer Care for support at  +256 775326157</span>
                                </div>
                                <div className="item">
                                    <MdOutlineMail className='itemIcon' />
                                    <span>Write to us; support@tunjoo.com</span>
                                </div>
                                <div className="item">
                                    <GiReturnArrow className='itemIcon' />
                                    <span>Read our
                                        <Link to="/tunjoo-return-policy">Return Policy</Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            }


        </>

    )
}

export default UserCart