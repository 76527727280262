import React, { useState } from 'react';
import './register.scss';
import { FaSmileBeam } from "react-icons/fa";
import WomenNav from '../../components/navbar/women/WomenNav'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {  useForm } from "react-hook-form";

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Spinner from './Spinner';
import GeneralLoader from '../../components/loader/GeneralLoader';
import ResponseNav from '../../components/res-nav/ResponseNav';



const Register = () => {

    const [user, setUser] = useState({
        firstname: "",
        lastname: "",
        email: "",
        address: "",
        city: "",
        country: "",
        password: "",
        confirmPassword: "",
    });

    const [err, setError] = useState(null);
    const { register, handleSubmit } = useForm();
    const navigate = useNavigate();

    // ------------ PHONE COUNTRY CODE -----------
    const [phone, setPhone] = useState("");
    const [valid, setValid] = useState(true);

    console.log(phone)

    const handlePhoneNumber = (value) => {
        setPhone(value);
        setValid(validatePhoneNumber(value));
    };

    const validatePhoneNumber = (phone) => {
        const phoneNumberPattern = /^\d{12}$/; // Validates a 10-digit phone number
        return phoneNumberPattern.test(phone);
    };


    // ------------ SHOW OR HIDE PASSWORD -----------
    const [isReveal, setIsReveal] = useState(false);
    const togglePassword = () => {
        setIsReveal(!isReveal);
    }

    const handleChange = (e) => {
        setUser((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    // ------------ SUBMIT DETAILS -----------
    const [isLoading, setIsLoading] = useState(false);
    const handleRegister = async (e) => {

        setIsLoading(true)
        try {
            await axios.post("https://tunjoo-server-36b3be406a89.herokuapp.com/api/auth/register", {
                ...user,
                phone: phone
            });
            setIsLoading(false);
            navigate("/login")
        } catch (err) {
            setError(err.response.data);
        }
    };

    return (
        <>
        {isLoading && <GeneralLoader/>}
        <div className='register'>
            <WomenNav />
            <ResponseNav/>
            <div className="registerWrapper">
                <div className="top">
                    <span>So you're new here...</span>
                    <FaSmileBeam className="topIcon" />
                </div>

                <div className="registerDesc">
                    <p>Create an account and start shopping.</p>
                </div>

                <form className="registerFormWrapper " onSubmit={handleSubmit(handleRegister)}>

                    <div className="formItem">
                        <input
                            name="firstname"
                            type="text"
                            required
                            placeholder="First Name"
                            onChange={handleChange}
                        />

                        <input
                            name="lastname"
                            type="text"
                            required
                            placeholder="Last Name"
                            onChange={handleChange}
                        />
                    </div>

                    <div className="formItem">
                        <input
                            // name="email"
                            // type="text"
                            // required
                            // placeholder="Email"
                            // onChange={handleChange}
                            placeholder="Email"
                            {...register('email', {
                                required: 'Email is required',
                                pattern: {
                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: 'Please enter a valid email',
                                },
                            })}
                            type="email"
                            required
                            onChange={handleChange}
                        />

                        <div className="phoneContainer">
                            <PhoneInput
                                country={'ug'}
                                value={phone}
                                // regions={'africa'}
                                onlyCountries={['ug', 'ke', 'ss', 'rw']}
                                dropdownStyle={{ width: '210px' }}
                                buttonStyle={{ borderRadius: '0px', border: 'none', backgroundColor: 'rgba(83,88,95, 0.2)' }}
                                containerStyle={{ width: '100%', display: 'flex' }}
                                inputStyle={{ borderRadius: 'none', marginLeft: '28px', width: '145px', height: '39px', border: 'none' }}
                                onChange={handlePhoneNumber}
                                inputProps={{
                                    required: true,
                                }}
                            />
                        </div>
                    </div>
                    {!valid && <p className='phoneValid'>Please enter a valid 9-digit Phone number.</p>}

                    <div className="formItem">
                        <input
                            name="address"
                            type="text"
                            required
                            placeholder="Street Address"
                            onChange={handleChange}
                        />

                        <input
                            name="city"
                            type="text"
                            required
                            placeholder="City"
                            onChange={handleChange}
                        />
                    </div>

                    <div className="formItem">
                        <div className="password">
                            <input
                                placeholder='Country'
                                type="text"
                                required
                                name="country"
                                className='passwordInput'
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div className="formItem">
                        <div className="password">
                            <input
                                placeholder='******'
                                type={isReveal ? "text" : "password"}
                                minLength="6"
                                required
                                name="password"
                                className='passwordInput'
                                onChange={handleChange}
                            />
                            <label onClick={togglePassword}>{isReveal ? "Hide" : "Show"}</label>
                        </div>
                    </div>

                    <p className='passValid'>*Password MUST be atleast 6 characters long*</p>

                    <div className="formItem">
                        <button type="submit" className='registerBtn'>
                            {isLoading ?
                                <Spinner loading={isLoading} />
                                :
                                "SUBMIT"
                            }
                        </button>
                    </div>

                    {err && <p style={{color: 'red', fontSize:'11px'}}>Something went wrong</p>}

                    <div className="registerFooter">
                        <p>Already have an Account? <Link className="registerLoginLink" to="/login" onClick={scrollToTop}>LOGIN</Link></p>
                        <span>By providing your details, you agree with our <Link className="registerLink" to="/privacy-policy"> Privacy Policy</Link> and <Link className="registerLink" to="/terms-and-conditions"> Terms and Conditions. </Link>
                            You agree to be contacted by Tunjoo by email, text and post.
                            We will treat your data with care and respect and never sell them to other
                            companies for marketing..
                        </span>
                    </div>


                </form>


            </div>
        </div>
        </>
    )
}

export default Register







// import React, { useEffect, useRef, useState } from 'react';
// import './register.scss';
// import { FaSmileBeam } from "react-icons/fa";
// import WomenNav from '../../components/navbar/WomenNav'
// import { Link, useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import { register, useForm } from "react-hook-form";

// //API URL
// // const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
// // export const API_URL =`${BACKEND_URL}/api/auth/`;


// const Register = () => {

//     const [user, setUser] = useState({
//         firstname: "",
//         lastname: "",
//         // phone: "",
//         email: "",
//         address: "",
//         city: "",
//         country: "",
//         password: "",
//         confirmPassword: "",
//     });

//     const [loading, setLoading] = useState(false);
//     const [err, setError] = useState(null);
//     const { register, watch, handleSubmit } = useForm();
//     const navigate = useNavigate();

//     // ------------ PHONE COUNTRY CODE -----------
//     const [phone, setPhone] = useState("");
//     const [valid, setValid] = useState(true);
//     const [visible, setVisible] = useState(false);

//     const handlePhoneNumber = (event) => {
//         const input = event.target.value;
//         setPhone(input);
//         setValid(validatePhoneNumber(input));
//     };

//     const validatePhoneNumber = (phone) => {
//         const phoneNumberPattern = /^\d{10}$/; // Validates a 10-digit phone number
//         return phoneNumberPattern.test(phone);
//     };


//     // ------------ SHOW OR HIDE PASSWORD -----------
//     const [isReveal, setIsReveal] = useState(false);
//     const togglePassword = () => {
//         setIsReveal(!isReveal);
//     }

//     const handleChange = (e) => {
//         setUser((prev) => {
//             return { ...prev, [e.target.name]: e.target.value };
//         });
//     };

//     const scrollToTop = () => {
//         window.scrollTo(0, 0)
//     }

//     // ------------ SUBMIT DETAILS -----------
//     const handleRegister = async (e) => {

//         setLoading(true);
//         try {
//             await axios.post("https://tunjoo-server-36b3be406a89.herokuapp.com/api/auth/register", {
//                 ...user,
//             });
//             setLoading(false);
//             navigate("/login")
//         } catch (err) {
//             setError(err.response.data);
//         }
//     };

//     return (
//         <div className='register'>
//             <WomenNav />
//             <div className="registerWrapper">
//                 <div className="top">
//                     <span>So you're new here...</span>
//                     <FaSmileBeam className="topIcon" />
//                 </div>

//                 <div className="registerDesc">
//                     <p>Create an account and start shopping.</p>
//                 </div>

//                 <form className="registerFormWrapper " onSubmit={handleSubmit(handleRegister)}>

//                     <div className="formItem">
//                         <input
//                             name="firstname"
//                             type="text"
//                             required
//                             placeholder="First Name"
//                             onChange={handleChange}
//                         />

//                         <input
//                             name="lastname"
//                             type="text"
//                             required
//                             placeholder="Last Name"
//                             onChange={handleChange}
//                         />
//                     </div>

//                     <div className="formItem">
//                         <input
//                             // name="email"
//                             // type="text"
//                             // required
//                             // placeholder="Email"
//                             // onChange={handleChange}
//                             placeholder="Email"
//                             {...register('email', {
//                                 required: 'Email is required',
//                                 pattern: {
//                                     value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
//                                     message: 'Please enter a valid email',
//                                 },
//                             })}
//                             type="email"
//                             required
//                             onChange={handleChange}
//                         />

//                         <input
//                             name="phone"
//                             type="text"
//                             required
//                             placeholder="Phone: +256..."
//                             // onChange={handleChange}
//                             onChange={handlePhoneNumber}
//                         />
//                     </div>
//                     {!valid && <p className='phoneValid'>Please enter a valid 9-digit Phone number.</p>}

//                     <div className="formItem">
//                         <input
//                             name="address"
//                             type="text"
//                             required
//                             placeholder="Street Address"
//                             onChange={handleChange}
//                         />

//                         <input
//                             name="city"
//                             type="text"
//                             required
//                             placeholder="City"
//                             onChange={handleChange}
//                         />
//                     </div>

//                     <div className="formItem">
//                         <div className="password">
//                             <input
//                                 placeholder='Country'
//                                 type="text"
//                                 required
//                                 name="country"
//                                 className='passwordInput'
//                                 onChange={handleChange}
//                             />
//                         </div>

//                     </div>
//                     <div className="formItem">
//                         <div className="password">
//                             <input
//                                 placeholder='Password'
//                                 type={isReveal ? "text" : "password"}
//                                 minLength="6"
//                                 required
//                                 name="password"
//                                 className='passwordInput'
//                                 onChange={handleChange}
//                             />
//                             <label onClick={togglePassword}>{isReveal ? "Hide" : "Show"}</label>
//                         </div>

//                     </div>
//                     <div className="formItem">
//                         <button type="submit" className='registerBtn'>SUBMIT</button>
//                     </div>

//                     <div className="registerFooter">
//                         <p>Already have an Account? <Link className="registerLoginLink" to="/login" onClick={scrollToTop}>LOGIN</Link></p>
//                         <span>By providing your details, you agree with our <Link className="registerLink" to="/privacy-policy"> Privacy Policy</Link> and <Link className="registerLink" to="/terms-and-conditions"> Terms and Conditions. </Link>
//                             You agree to be contacted by Tunjoo by email, text and post.
//                             We will treat your data with care and respect and never sell them to other
//                             companies for marketing..
//                         </span>
//                     </div>


//                 </form>

//             </div>
//         </div>
//     )
// }

// export default Register





// useEffect =(() => {
    //     fetch("https://rescountries.eu/rest/v2/all")

    // }, [])