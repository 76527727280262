import React from 'react';
import './list.scss';

const NavList = ({id, active, setSelected}) => {
    return (
        <li
            className={active ? "nav-listItem active" : "nav-listItem"}
            onClick={() => setSelected(id)}
        >
            {id}
        </li>
    )
}

export default NavList