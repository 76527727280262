import React from 'react';
import './navigation.scss';
import MenuItem from './MenuItem';
import tunjooLogo from '../../assets/tunjooLogo.png';
import routes from "../../adminRoutes";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { Link, useNavigate } from 'react-router-dom';
// import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from "react-icons/io";
// import { useDispatch, useSelector } from 'react-redux';
// import { logoutUser } from '../../redux/userRedux';
import axios from 'axios';



const Navigation = () => {

    // const [active, setActive] = useState(true);
    // const dispatch = useDispatch();
    const navigate = useNavigate();
    // const [sidebar, setSidebar] = useState(false);
    // const showSidebar = () => setSidebar(!sidebar);
    // const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    // const currentUser = JSON.parse(localStorage.getItem("currentUser"));

    // const scrollToTop = () => {
    //     window.scrollTo(0, 0)
    // }

    // const handleLogout = () => {
    //     dispatch(logoutUser(currentUser))
    //     navigate("/");
    // }
    

    const handleLogout = async () => {
        try {
            await axios.post("https://tunjoo-server-36b3be406a89.herokuapp.com/api/auth/logout");
            window.localStorage.clear();
            navigate("/login");
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div>
            <div className='drawer'>

                {/* <IoIosArrowDropleftCircle className="menuIcon" onClick={showSidebar}/> */}
            
                <div className="navLogoWrapper">
                    <Link to="/admin">
                        <img src={tunjooLogo} className="navigationLogo" alt="tunjooLogo" />
                    </Link>
                </div>

                <div className="listItemWrapper">
                    <div className="itemsWrapper">
                        {routes.map((route, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <MenuItem
                                        label={route.label}
                                        icon={route.icon}
                                        path={route.path}
                                    />
                                </React.Fragment>

                            )

                        })}
                    </div>

                    <div className="adminFooter">
                        <div className="adminFooterItem" onClick={handleLogout}>
                            <RiLogoutCircleRLine className='adminFooterIcon' />
                            <span className='adminFooterLabel'>Sign Out</span>
                        </div>


                    </div>

                </div>
            </div>
        </div>
    )
}

export default Navigation