import React, { useEffect, useState } from 'react';
import './single.scss';
import { BsCart3 } from 'react-icons/bs';
import { HiPlusSm, HiMinusSm } from 'react-icons/hi';
import { FaHeartCirclePlus, FaHeartCircleCheck } from "react-icons/fa6";
import WomenNav from '../../components/navbar/women/WomenNav';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
// import { useNavigate } from "react-router-dom";
import { BsPlus } from "react-icons/bs";
import { HiOutlineMinusSmall } from "react-icons/hi2";
import { useDispatch } from 'react-redux';
import { addToCart } from '../../redux/cartSlice';
// import { addToList } from '../../redux/userSlice';

// import { cartTotalSelector } from '../../redux/cartSlice';

//TOAST
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { addToWishlist } from '../../redux/wishlistRedux';
// import { userRequest } from '../../requestMethod';
import newRequest from '../../config/newRequest';
import MenNav from '../../components/navbar/men/MenNav';
import KidsNav from '../../components/navbar/kids/KidsNav';
import ResponseNav from '../../components/res-nav/ResponseNav';
// import { addToWishlist } from '../../redux/wishlistRedux';



const SingleProduct = () => {

    // const { currentUser } = useSelector((state) => state.user);
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));

    const location = useLocation();
    const dispatch = useDispatch();
    const path1 = location.pathname.split("/")[1];
    const path2 = location.pathname.split("/")[3].replace(/-/g, " ");
    const itemID = location.pathname.split("/")[4];
    // const navigate = useNavigate();

    const [quantity, setQuantity] = useState(1);

    // ------------ FETCH SINGLE PRODUCT -----------
    const [product, setProduct] = useState({});
    // const [loading, setLoading] = useState(false);
    // const [err, setError] = useState(null);
    const [openDelivery, setOpenDelivery] = useState(false);
    const [openReturns, setOpenReturns] = useState(false)

    useEffect(() => {
        // setLoading(true);
        const fetchProduct = async () => {
            const res = await axios.get("https://tunjoo-server-36b3be406a89.herokuapp.com/api/products/single/" + itemID)
            // setLoading(false);
            setProduct(res.data);
        }
        fetchProduct();
    }, [itemID]);


    // ------------ FETCH SIMILAR PRODUCTS -----------
    const [similarProducts, setSimilarProducts] = useState([]);
    const code = product?.productCode;

    useEffect(() => {
        const getProducts = async () => {
            try {
                const res = await axios.get(`https://tunjoo-server-36b3be406a89.herokuapp.com/api/products/similarColor?productCode=${code}`);
                setSimilarProducts(res.data);
            } catch (err) { }
        };
        getProducts();
    }, [code]);

    // function handleClick(id) {
    //     navigate(`/${path1}/${path2}/${id}`);
    // }

    // ------------ SELECT IMAGES -----------
    const [selected, setSelected] = useState(0);

    // ------------ SELECT SIZE -----------
    const [size, setSize] = useState(null);

    // ------------ SCROLL TO TOP -----------
    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    // ------------ ADD TO CART -----------
    // const cartTotal = useSelector(cartTotalSelector);

    const pdtPrice = product.discountPrice ? product.discountPrice : product.sellingPrice;
    const pdtTotal = pdtPrice * quantity;

    // const handleAddToUserCart = async (e) => {
    //     try {
    //         const res = await axios.post(`https://tunjoo-server-36b3be406a89.herokuapp.com/api/carts/addProduct`, {
    //             userId: currentUser._id,
                // productId: product._id, 
                // itemId: product._id + size, 
                // productQuantity: quantity, 
                // productSize: size,
                // productPrice: pdtPrice,
                // productTotal: pdtTotal,
                // productName: product.productName, 
                // brand: product.brand,
                // color: product.color,
                // image: product.photos[0],
    //         });
    //         localStorage.setItem("userCart", JSON.stringify(res.data));
    //         toast.success(`${product.productName} Successfully Added To Your Cart!`)
    //     } catch (err) {}
        
    // };

    const handleAddToUserCart = async (e) => {
        e.preventDefault();
        try {
            const res = await newRequest.post("api/carts/addProduct",
                { 
                    userId: currentUser._id,
                    productId: product._id, 
                    itemId: product._id + size, 
                    productQuantity: quantity, 
                    productSize: size,
                    productPrice: pdtPrice,
                    productTotal: pdtTotal,
                    productName: product.productName, 
                    brand: product.brand,
                    color: product.color,
                    image: product.photos[0],
                },
            );
            localStorage.setItem("userCart", JSON.stringify(res.data));
            toast.success(`${product.productName} Successfully Added To Your Cart!`)
        } catch (err) {
            // setError(err.response.data)
        }
    };

    const handleAddToCart = async (e) => {
        dispatch(
            addToCart({ ...product, quantity, size })
        );  
    };

    // const handleAddToCart = async (e) => {
    //     try {
            
    //         const res = await axios.post(`https://tunjoo-server-36b3be406a89.herokuapp.com/api/carts/addProduct`, {
    //             userId: currentUser._id,
    //             productId: product._id, 
    //             itemId: product._id + size, 
    //             productQuantity: quantity, 
    //             productSize: size,
    //             productPrice: pdtPrice,
    //             productTotal: pdtTotal,
    //             productName: product.productName, 
    //             brand: product.brand,
    //             color: product.color,
    //             image: product.photos[0],
    //         });
    //         localStorage.setItem("userCart", JSON.stringify(res.data));
    //         dispatch(
    //             addToCart({ ...product, quantity, size })
    //         );
    
    //     } catch (err) {}
        
    // };

    // ------------ ADD TO WISHLIST -----------
    const handleAddToWishlist = async (e) => {
        // try {
        //     await axios.put(`https://tunjoo-server-36b3be406a89.herokuapp.com/api/users/${product._id}/like`, {
        //         userId: currentUser._id,
        //     });
        //     dispatch(addToList(product._id))
        //     toast.success("Product added to Wishlist!")
        // } catch (err) {}

    }


    // const handleAddToWishlist = async (e) => {
    //     try {
    //         await axios.put(`https://tunjoo-server-36b3be406a89.herokuapp.com/api/users/${product._id}/like`, {
    //             userId: currentUser._id,
    //         });
    //         dispatch(addToWishlist(product._id))
    //     } catch (err) {
    //         console.log(err);
    //     }

    // }


    return (
        <div className='single'>
           {path1 === "Women" ? <WomenNav  /> : path1 === "Men" ? <MenNav/> : <KidsNav/> }
           <ResponseNav/>

            <div className="top">
                {/* <span>Home /{product.cat} / {product.desc}</span> */}
                <span>Home / {path1} / {path2} / {product._id}</span>
            </div>


            <div className="bottom">
                <div className="left">
                    {product?.photos?.length > 0 && (
                        <>
                            <div className="images">

                                <div className="imgWrapper">
                                    <img className="leftImg" src={product?.photos[0]} alt="" onClick={(e) => setSelected(0)} />
                                </div>
                                <div className="imgWrapper">
                                    <img className="leftImg" src={product?.photos[1]} alt="" onClick={(e) => setSelected(1)} />
                                </div>
                                <div className="imgWrapper">
                                    <img className="leftImg" src={product?.photos[2]} alt="" onClick={(e) => setSelected(2)} />
                                </div>
                                <div className="imgWrapper">
                                    <img className="leftImg" src={product?.photos[3]} alt="" onClick={(e) => setSelected(3)} />
                                </div>

                            </div>


                            <div className="mainImage">
                                <div className="imageWrapper">
                                    <img className="rightImg" src={product?.photos[selected]} alt="" />
                                </div>
                            </div>

                        </>

                    )}

                </div>

                <div className="right">
                    <div className="topRight">
                        <h2>{product?.productName}</h2>

                        {product?.discountPrice > 0 ?
                            <div className="discountWrapper">
                                <div className="discountItem2">
                                    <p>UGX</p>
                                    <span>{product?.discountPrice?.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</span>
                                </div>
                                <div className="discountItem1">
                                    <p>UGX</p>
                                    <span>{product?.sellingPrice?.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</span>
                                </div>
                            </div>

                            :
                            <div className="priceWrapper">
                                <span className='price1'>UGX</span>
                                <span className='price3'>{product?.sellingPrice?.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</span>
                            </div>

                        }

                    </div>

                    <hr />

                    <div className="filterColor">
                        <span>COLOR:</span>
                        <h3 className='color'>{product?.color}</h3>
                    </div>

                    <div className="similarColors">
                        {similarProducts.map((item) =>
                            product?._id !== item?._id && (
                                <div className="imgWrapper" key={item?._id}>
                                    <Link className="link" to={`/${path1}/${path2}/${item?._id}`} onClick={scrollToTop}>
                                        <img className="rightImg" src={item?.photos[0]} alt="" />
                                    </Link>
                                </div>
                            )
                        )}
                    </div>

                    <div className="filterSize">
                        <span>SIZE: </span>
                        {path2 === "Women's Clothing" &&
                            <h3 className='selectedSize'>
                                {(size === "S" && "SMALL") ||
                                    (size === "XS" && "EXTRA SMALL") ||
                                    (size === "M" && "MEDIUM") ||
                                    (size === "L" && "LARGE") ||
                                    (size === "XL" && "EXTRA LARGE") ||
                                    (size === "XXL" && "DOUBLE EXTRA LARGE")
                                }
                            </h3>
                        }
                        {path2 === "Men's Clothing" &&
                            <h3 className='selectedSize'>
                                {(size === "S" && "SMALL") ||
                                    (size === "XS" && "EXTRA SMALL") ||
                                    (size === "M" && "MEDIUM") ||
                                    (size === "L" && "LARGE") ||
                                    (size === "XL" && "EXTRA LARGE") ||
                                    (size === "XXL" && "DOUBLE EXTRA LARGE")
                                }
                            </h3>
                        }

                        {path2 === "Men's Bottoms" &&
                            <h3 className='selectedSize'>
                                {(size === "S" && "SMALL") ||
                                    (size === "XS" && "EXTRA SMALL") ||
                                    (size === "M" && "MEDIUM") ||
                                    (size === "L" && "LARGE") ||
                                    (size === "XL" && "EXTRA LARGE") ||
                                    (size === "XXL" && "DOUBLE EXTRA LARGE")
                                }
                            </h3>
                        }

                        {/* {path2 === "Bottoms" &&
                            <h3 className='selectedSize'>
                                {(size === "S" && "SMALL") ||
                                    (size === "XS" && "EXTRA SMALL") ||
                                    (size === "M" && "MEDIUM") ||
                                    (size === "L" && "LARGE") ||
                                    (size === "XL" && "EXTRA LARGE") ||
                                    (size === "XXL" && "DOUBLE EXTRA LARGE")
                                }
                            </h3>
                        } */}

                        {path2 === "Women's Shoes" &&
                            <h3 className='selectedSize'>
                                {(size === "37" && "37") ||
                                    (size === "38" && "38") ||
                                    (size === "39" && "39") ||
                                    (size === "40" && "40") ||
                                    (size === "41" && "41") ||
                                    (size === "42" && "42")
                                }
                            </h3>
                        }
                        {path2 === "Men's Shoes" &&
                            <h3 className='selectedSize'>
                                {(size === "41" && "41") ||
                                    (size === "42" && "42") ||
                                    (size === "43" && "43") ||
                                    (size === "44" && "44") ||
                                    (size === "45" && "45") ||
                                    (size === "46" && "46")
                                }
                            </h3>
                        }

                        {path2 === "Handbags" &&
                            <h3 className='selectedSize'>
                                {(size === "ONE SIZE" && "ONE SIZE")}
                            </h3>
                        }


                    </div>

                    {path2 === "Women's Shoes" ?
                        <div className="sizeWrapper">

                            {product?.otherSizes?.includes("37")
                                ?
                                <button className="size" onClick={(e) => setSize("37")}>37</button>
                                :
                                <div className="noSize">37</div>
                            }
                            {product?.otherSizes?.includes("38")
                                ?
                                <button className="size" onClick={(e) => setSize("38")}>38</button>
                                :
                                <div className="noSize">38</div>
                            }
                            {product?.otherSizes?.includes("39")
                                ?
                                <button className="size" onClick={(e) => setSize("39")}>39</button>
                                :
                                <div className="noSize">39</div>
                            }
                            {product?.otherSizes?.includes("40")
                                ?
                                <button className="size" onClick={(e) => setSize("40")}>40</button>
                                :
                                <div className="noSize">40</div>
                            }
                            {product?.otherSizes?.includes("41")
                                ?
                                <button className="size" onClick={(e) => setSize("41")}>41</button>
                                :
                                <div className="noSize">41</div>
                            }
                            {product?.otherSizes?.includes("42")
                                ?
                                <button className="size" onClick={(e) => setSize("42")}>42</button>
                                :
                                <div className="noSize">42</div>
                            }
                        </div>

                        :

                        path2 === "Men's Shoes" ?
                        <div className="sizeWrapper">

                            {product?.otherSizes?.includes("41")
                                ?
                                <button className="size" onClick={(e) => setSize("41")}>41</button>
                                :
                                <div className="noSize">41</div>
                            }
                            {product?.otherSizes?.includes("42")
                                ?
                                <button className="size" onClick={(e) => setSize("42")}>42</button>
                                :
                                <div className="noSize">42</div>
                            }
                            {product?.otherSizes?.includes("43")
                                ?
                                <button className="size" onClick={(e) => setSize("43")}>43</button>
                                :
                                <div className="noSize">43</div>
                            }
                            {product?.otherSizes?.includes("44")
                                ?
                                <button className="size" onClick={(e) => setSize("44")}>44</button>
                                :
                                <div className="noSize">44</div>
                            }
                            {product?.otherSizes?.includes("45")
                                ?
                                <button className="size" onClick={(e) => setSize("45")}>45</button>
                                :
                                <div className="noSize">45</div>
                            }
                            {product?.otherSizes?.includes("46")
                                ?
                                <button className="size" onClick={(e) => setSize("46")}>46</button>
                                :
                                <div className="noSize">46</div>
                            }
                        </div>

                        :

                        path2 === "Handbags" ?

                            <div className="sizeWrapper">

                                <button className="size1" onClick={(e) => setSize("ONE SIZE")}>ONE SIZE</button>

                            </div>
                            :
                            <div className="sizeWrapper">

                                {product?.otherSizes?.includes("XS")
                                    ?
                                    <button className="size" onClick={(e) => setSize("S")}>XS</button>
                                    :
                                    <div className="noSize">XS</div>
                                }
                                {product?.otherSizes?.includes("S")
                                    ?
                                    <button className="size" onClick={(e) => setSize("S")}>S</button>
                                    :
                                    <div className="noSize">S</div>
                                }
                                {product?.otherSizes?.includes("M")
                                    ?
                                    <button className="size" onClick={(e) => setSize("M")}>M</button>
                                    :
                                    <div className="noSize">M</div>
                                }
                                {product?.otherSizes?.includes("L")
                                    ?
                                    <button className="size" onClick={(e) => setSize("L")}>L</button>
                                    :
                                    <div className="noSize">L</div>
                                }
                                {product?.otherSizes?.includes("XL")
                                    ?
                                    <button className="size" onClick={(e) => setSize("XL")}>XL</button>
                                    :
                                    <div className="noSize">XL</div>
                                }
                                {product?.otherSizes?.includes("XXL")
                                    ?
                                    <button className="size" onClick={(e) => setSize("XXL")}>XXL</button>
                                    :
                                    <div className="noSize">XXL</div>
                                }
                            </div>
                    }

                    <div className="actions">
                        <span className='selectQuantity'>QUANTITY</span>

                        <div className="quantity">
                            <button onClick={() => setQuantity((prev) => (prev === 1 ? 1 : prev - 1))}>
                                <HiMinusSm className='qttyIcon' />
                            </button>
                            <span>{quantity}</span>
                            <button onClick={() => setQuantity((prev) => prev + 1)}>
                                <HiPlusSm className='qttyIcon' />
                            </button>
                        </div>

                        <div className="addButtons">

                            <button className={size ? "cartBtn1" : "cartBtn2"} disabled={!size} onClick={currentUser ? handleAddToUserCart : handleAddToCart}>
                                <BsCart3 className="cartIcon" />
                                <span>{size ? "ADD TO CART" : "SELECT SIZE"}</span>
                            </button>

                            {/* {product.likes?.includes(currentUser._id) ? */}
                            {currentUser?.favorites?.includes(product?._id) ?
                                <button className="likedBtn">
                                    <FaHeartCircleCheck className="likeIcon" />
                                    <span>Already on Wishlist</span>
                                </button>
                                :
                                <button className={size ? "likeBtn1" : "likeBtn2"} onClick={handleAddToWishlist}>
                                    <FaHeartCirclePlus className="likeIcon" />
                                    <span>Add to Wishlist</span>
                                </button>

                            }

                        </div>

                    </div>

                    <hr className='divider' />

                    <div className="pdtDetails">
                        <h4>PRODUCT DETAILS</h4>
                        <p className='desc'>{product?.desc}</p>
                        {/* <p className='code'>Product Code:{product.productCode} {product?._id?.substring(0, 7)}</p> */}
                        <div className="codeWrapper">
                            <p className='codeTitle'>Product Code:</p>
                            <p className='code'>{product?.productCode + "-"}{product?._id?.substr(product?._id?.length - 7)}</p>
                        </div>

                    </div>

                    <hr className='divider' />

                    <div className="policies" onClick={() => setOpenDelivery(!openDelivery)}>
                        <div className="polociesTop">
                            <h4>DELIVERY</h4>
                            {openDelivery ? <HiOutlineMinusSmall className='dropIcon' /> : <BsPlus className='dropIcon' />}
                        </div>
                        {openDelivery &&
                            <ul className="deliveryWrapper">
                                <li>Free next day delivery on orders over UGX 400,000.</li>
                                <li>Standard Delivery: 1-3 Business Days at UGX 10,000 around Kampala.</li>
                                <li>Express Delivery: Same day Delivery at UGX 15,000 around Kampala.</li>
                                <li>Additional charges may apply outside Kampala.</li>
                                <li>Please note estimated delivery times include processing
                                    time at the warehouse and are calculated from the date the order is placed.
                                </li>
                            </ul>
                        }
                    </div>

                    <hr className='divider' />

                    <div className="policies" onClick={() => setOpenReturns(!openReturns)}>
                        <div className="polociesTop">
                            <h4>RETURNS</h4>
                            {openReturns ? <HiOutlineMinusSmall className='dropIcon' /> : <BsPlus className='dropIcon' />}
                        </div>
                        {openReturns &&
                            <ul className="deliveryWrapper">
                                <li>All orders (subject to our returns policy) can be returned up to 2 days after the date of purchase.</li>
                                <li>Please note, we cannot offer refunds. However you can exchange the product in the same price range.</li>
                                <li>
                                    <p>Click</p>
                                    <Link to="/tunjoo-return-policy" className='returnLink'>
                                        <p className='returnLink'>here</p>
                                    </Link>
                                    <p>to view our full Returns Policy.</p>
                                </li>
                            </ul>
                        }
                    </div>

                    <hr className='divider' />


                </div>
            </div>


        </div>
    )
}

export default SingleProduct