import React from 'react';
import './adminComps.scss';

const AdminWishlist = () => {
	return (
		<div className='adminWish'>
			AdminWishlist
		</div>
	)
}

export default AdminWishlist