import React, { useEffect, useState } from 'react';
import './navbar.scss';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { TfiSearch } from "react-icons/tfi";
// import { PiHeartLight } from "react-icons/pi";
import { BsHandbag } from 'react-icons/bs';
import { SlHeart } from "react-icons/sl";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import NavList from './NavList';
import { VscAccount } from "react-icons/vsc";
import newRequest from '../../config/newRequest';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { cartQuantitySelector } from '../../redux/cartSlice';



const Navbar = () => {

    const navigate = useNavigate();

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    //difeereciate between customer page and main page
    const location = useLocation();
    const path = location.pathname.split("/")[1];

    const [dropdown, setDropdown] = useState(false);

    // const { currentUser } = useSelector((state) => state.user);
    const cartQuantity = useSelector(cartQuantitySelector);
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));

    // const userCart = JSON.parse(localStorage.getItem("userCart"));

    // const savedCart = JSON.parse(localStorage.getItem("savedCart"));

    // console.log(currentUser.cart.userId)

    // // FETCH USER CART
    const [userCart, setUserCart] = useState({});
    useEffect(() => {
        const getUserCart = async () => {
            try {
                const res = await newRequest.get(`https://tunjoo-server-36b3be406a89.herokuapp.com/api/carts/find/${currentUser?._id}`);
                setUserCart(res.data);
                localStorage.setItem("userCart", JSON.stringify(res.data));
            } catch (err) { }
        };
        getUserCart();
    }, [currentUser?._id]);

    const handleLogout = async () => {
        try {
            await axios.post("https://tunjoo-server-36b3be406a89.herokuapp.com/api/auth/logout");
            window.localStorage.clear();
            navigate("/");
        } catch (err) {
            console.log(err);
        }
    };


    const [selected, setSelected] = useState("WOMEN");

    const list = [
        {
            id: 1,
            title: "WOMEN",
            path: "/"
        },
        {
            id: 2,
            title: "MEN",
            path: "/Men"
        },
        {
            id: 3,
            title: "KIDS",
            path: "/Kids"
        },


    ]



    return (
        <div className={path === 'admin' ? "promoNav" : "navbar"}>

            <div className='navbarWrapper'>

                <div className="left" onClick={scrollToTop}>
                    <Link to="/" className='navbarLink'>
                        <span className='navLogo'>TUNJOO.</span>
                    </Link>
                </div>

                <ul className='navCenter'>

                    {list.map((item) => (
                        <Link to={item.path} key={item.id} style={{ textDecoration: 'none', color: 'inherit' }} onClick={scrollToTop}>
                            <NavList
                                id={item.title}
                                active={selected === item.title}
                                setSelected={setSelected}
                                className={selected ? "listItem active" : "listItem"}
                            />
                        </Link>
                    ))}

                    {currentUser && currentUser.isAdmin &&
                        <Link className='link' to="/admin/dashboard">
                            <span className='dash'>Admin Dashboard</span>
                        </Link>

                    }

                </ul>

                <div className='right'>

                    <div className="icons">

                        <div
                            className="activeUserWrapper"
                            onMouseEnter={() => setDropdown(true)}
                            onMouseLeave={() => setDropdown(false)}
                        >
                            <VscAccount className='icon' />
                            <span>{currentUser ? currentUser.firstname : "Sign In/Sign Up"}</span>
                            <MdKeyboardArrowDown className='secIcon' />

                            {dropdown && (
                                <div className="options">

                                    <li className="menuItem2">
                                        <Link className='link' to="/login" onClick={scrollToTop}>
                                            <div className='optionBtn1'>Sign In</div>
                                        </Link>
                                        <Link className='link' to="/register" onClick={scrollToTop}>
                                            <div className='optionBtn2'>Sign Up</div>
                                        </Link>
                                    </li>
                                    <hr />

                                    <li className="menuItem">
                                        <Link to={currentUser ? `/my-account/info/${currentUser._id}` : "/login"} className='optionsLink' onClick={scrollToTop}>
                                            <span>My Account</span>
                                        </Link>
                                    </li>

                                    {/* <>
                                        <hr />
                                        <li className="menuItem3" >
                                            <div onClick={scrollToTop}>
                                                <h3>Sign Out</h3>
                                            </div>
                                        </li>
                                    </> */}

                                    {currentUser &&
                                        <>
                                            <hr />
                                            <li className="menuItem3" onClick={handleLogout}>
                                                <div onClick={scrollToTop}>
                                                    <h3>Sign Out</h3>
                                                </div>
                                            </li>
                                        </>

                                    }


                                </div>
                            )}
                        </div>

                        <div className="iconWrapper">

                            <div className="cartIcon" >
                                <TfiSearch className='icon' />
                            </div>

                            <div className="likeIcon" >
                                <SlHeart className='icon' />
                                <span>{currentUser?.favorites?.length || 3}</span>
                            </div>


                            {/* <div className="cartIcon" >
                                <BsHandbag className='icon' />
                                <div className="cartAmtWrapper">
                                    <span>{5}</span>
                                </div>
                            </div> */}

                            {currentUser ?
                                <Link to="/cart" className='link' onClick={scrollToTop}>
                                    <div className="cartIcon" >
                                        <BsHandbag className='icon' />
                                        <div className="cartAmtWrapper">
                                            <span>{userCart?.totalQty || "0"}</span>
                                        </div>
                                    </div>
                                </Link>
                                :
                                <Link to="/cart" className='link' onClick={scrollToTop}>
                                    <div className="cartIcon" >
                                        <BsHandbag className='icon' />
                                        <div className="cartAmtWrapper">
                                            <span>{cartQuantity}</span>
                                        </div>
                                    </div>
                                </Link>
                            }



                        </div>

                    </div>

                </div>

            </div>


        </div>
    )
}

export default Navbar










// import React, { useEffect, useState } from 'react';
// import './navbar.scss';
// import { MdOutlineFavoriteBorder, MdPersonOutline, MdSearch, MdKeyboardArrowDown } from 'react-icons/md';
// import { BsHandbag } from 'react-icons/bs';
// import { SlHeart } from "react-icons/sl";
// import { Link, useLocation, useNavigate } from 'react-router-dom';
// import NavList from './NavList';
// import { VscAccount } from "react-icons/vsc";
// import axios from 'axios';
// import { useDispatch, useSelector } from 'react-redux';
// import { logoutUser } from '../../redux/userSlice';
// import { cartQuantitySelector} from '../../redux/cartSlice';
// import newRequest from '../../config/newRequest';



// const Navbar = () => {

//     const navigate = useNavigate();
//     const dispatch = useDispatch();

//     const scrollToTop = () => {
//         window.scrollTo(0, 0)
//     }

//     //difeereciate between customer page and main page
//     const location = useLocation();
//     const path = location.pathname.split("/")[1];

//     const [dropdown, setDropdown] = useState(false);

//     // const { currentUser } = useSelector((state) => state.user);
//     const currentUser = JSON.parse(localStorage.getItem("currentUser"));
//     const quantity = useSelector(state => state.cart.quantity);
//     const cartItems = useSelector((state) => state.cart.cartItems);
//     const cart = useSelector((state) => state.cart);

//     // const userCart = JSON.parse(localStorage.getItem("userCart"));

//     // const savedCart = JSON.parse(localStorage.getItem("savedCart"));
    
//     // let userCart;
//     const cartQuantity = useSelector(cartQuantitySelector);

//     // console.log(currentUser.cart.userId)

    // // // FETCH USER CART
    // const [userCart, setUserCart] = useState({});
    // useEffect(() => {
    //     const getUserCart = async () => {
    //         try {
    //             const res = await newRequest.get(`https://tunjoo-server-36b3be406a89.herokuapp.com/api/carts/find/${currentUser?._id}`);
    //             setUserCart(res.data);
    //             localStorage.setItem("userCart", JSON.stringify(res.data));
    //         } catch (err) { }
    //     };
    //     getUserCart();
    // }, [currentUser?._id]);

    // const handleLogout = async () => {
    //     try {
    //         await axios.post("https://tunjoo-server-36b3be406a89.herokuapp.com/api/auth/logout");
    //         window.localStorage.clear();
    //         navigate("/");
    //     } catch (err) {
    //         console.log(err);
    //     }
    // };

//     //
//     const [favorites, setFavorites] = useState([]);

//     // //GET WISHLIST
//     // useEffect(() => {
//     //     const getFavorites = async () => {
//     //         try {
//     //             const likedList = await axios.get(`https://tunjoo-server-36b3be406a89.herokuapp.com/api/users/${currentUser._id}/favorites`);
//     //             setFavorites(likedList.data);
//     //         } catch (err) {
//     //             // console.log(err);
//     //         }
//     //     };
//     //     getFavorites();
//     // }, [currentUser]);


//     const [selected, setSelected] = useState("WOMEN");

//     const list = [
//         {
//             id: 1,
//             title: "WOMEN",
//             path: "/"
//         },
//         {
//             id: 2,
//             title: "MEN",
//             path: "/men"
//         },
//         {
//             id: 3,
//             title: "KIDS",
//             path: "/kids"
//         },
        
//     ]



//     return (
//         <div className={path === 'admin' ? "promoNav" : "navbar"}>

//             <div className='navbarWrapper'>

//                 <ul className='left'>
//                     {list.map((item) => (
//                         <Link to={item.path} key={item.id} style={{ textDecoration: 'none', color: 'inherit' }} onClick={scrollToTop}>
//                             <NavList
//                                 id={item.title}
//                                 active={selected === item.title}
//                                 setSelected={setSelected}
//                                 className={selected ? "listItem active" : "listItem"}
//                             />
//                         </Link>
//                     ))}
                    // {currentUser && currentUser.isAdmin &&
                    //     <Link className='link' to="/admin/dashboard">
                    //         <span className='dash'>Admin Dashboard</span>
                    //     </Link>

                    // }
//                 </ul>

//                 <div className='navCenter'>
//                     <Link className='link' to="/" onClick={scrollToTop}>
//                         <div className="mainLogo">
//                             <span className='navLogo'>TUNJOO.</span>
//                         </div>
//                     </Link>
//                 </div>

//                 <div className='right'>

//                     <div className="icons">

//                         {currentUser && currentUser.isAdmin === true &&
//                             <div className="adminWrapper">
//                                 <p className='adminTitle'>isAdmin:</p>
//                                 <p className='adminName'>ACTIVE</p>
//                             </div>
//                         }

//                         <div
//                             className="activeUserWrapper"
//                             onMouseEnter={() => setDropdown(true)}
//                             onMouseLeave={() => setDropdown(false)}
//                         >
//                             {/* <VscAccount className='icon' /> */}
//                             {/* <span>{user ? "Hi, " + currentUser.firstname : "Sign In / Sign Up"}</span> */}
//                             {currentUser ?
//                                 <h3>{"Hi, " + currentUser.firstname }</h3>
//                                 :
//                                 <span>Sign In / Sign Up</span>
//                             }
//                             <MdKeyboardArrowDown className='secIcon' />

//                             {dropdown && (
//                                 <div className="options">
//                                     {!currentUser &&
//                                         <>
//                                             <li className="menuItem2">
//                                                 <Link className='link' to="/login" onClick={scrollToTop}>
//                                                     <div className='optionBtn1'>Sign In</div>
//                                                 </Link>
//                                                 <Link className='link' to="/register" onClick={scrollToTop}>
//                                                     <div className='optionBtn2'>Sign Up</div>
//                                                 </Link>
//                                             </li>
//                                             <hr />
//                                         </>
//                                     }
//                                     <li className="menuItem">
//                                         <Link to={currentUser ? `/my-account/info/${currentUser._id}` : "/login"} className='optionsLink' onClick={scrollToTop}>
//                                             <span>My Account</span>
//                                         </Link>
//                                     </li>


                                    // {currentUser &&
                                    //     <>
                                    //         <hr />
                                    //         <li className="menuItem3" onClick={handleLogout}>
                                    //             <div onClick={scrollToTop}>
                                    //             <h3>Sign Out</h3>
                                    //             </div>
                                    //         </li>
                                    //     </>

                                    // }


//                                 </div>
//                             )}
//                         </div>

//                         <div className="iconWrapper">
//                             <Link to={currentUser ? "/my-account/my-wishlist" : "/login"} className='link' onClick={scrollToTop}>
//                                 <div className="likeIcon" >
//                                     <SlHeart className='icon' />
//                                     <span>{currentUser?.favorites?.length || 0}</span>
//                                     {/* <span>{favorites?.length}</span> */}
//                                 </div>
//                             </Link>

//                             {/* <Link to="/cart" className='link' onClick={scrollToTop}>
//                                     <div className="cartIcon" >
//                                         <BsHandbag className='icon' />
//                                         <span>{cartItems.length}</span>
//                                     </div>
//                                 </Link> */}

                            // {currentUser ?
                            //     <Link to="/cart" className='link' onClick={scrollToTop}>
                            //         <div className="cartIcon" >
                            //             <BsHandbag className='icon' />
                            //             <div className="cartAmtWrapper">
                            //             <span>{userCart?.totalQty || "0"}</span>
                            //             </div>
                            //         </div>
                            //     </Link>
                            //     :
                            //     <Link to="/cart" className='link' onClick={scrollToTop}>
                            //         <div className="cartIcon" >
                            //             <BsHandbag className='icon' />
                            //             <div className="cartAmtWrapper">
                            //             <span>{cartQuantity}</span>
                            //             </div>
                            //         </div>
                            //     </Link>
                            // }

//                         </div>

//                     </div>

//                 </div>

//             </div>

//             {/* {open && <CartCard />} */}

//         </div>
//     )
// }

// export default Navbar










