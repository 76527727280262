import React, { useRef, useState } from 'react';
import "./reset.scss";
import PasswordSuccess from './PasswordSuccess';
import { useNavigate, useLocation, Link } from 'react-router-dom';
// import newRequest from '../config/newRequest';
import axios from 'axios';
// import { publicRequest } from "../../../requestMethod";
import WomenNav from '../../../components/navbar/women/WomenNav';

function ResetPassword() {

    const password = useRef();
    const confirmPassword = useRef();
    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname.split("/")[2];
    const [showModal, setShowModal] = useState();
    const [error, setError] = useState(null);

    const [isReveal, setIsReveal] = useState(false);

    const togglePassword = () => {
        setIsReveal(!isReveal);
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (confirmPassword.current.value !== password.current.value) {
            confirmPassword.current.setCustomValidity("Passwords don't match!");
        } else {
            try {
                const user = {
                    password: password.current.value,
                };

                // const res = await axios.post("https://tunjoo-server-36b3be406a89.herokuapp.com/api/auth/reset-password/" + path, user);
                const res = await axios.put("https://tunjoo-server-36b3be406a89.herokuapp.com/api/auth/reset-password/" + path, user);

                if (res.data) {
                    setShowModal({
                        title: "Yaaay! You Did It...",
                        message: res.data.message,
                    });
                    return;
                }
                navigate("/login");
            } catch (err) {
                setError(err.response.data);
            }
        }
    };

    const modalHandler = () => {
        setShowModal(null);
    };


    return (
        <div className='reset-login'>

            <WomenNav />

            {showModal && (
                <PasswordSuccess
                    title={showModal.title}
                    message={showModal.message}
                    onShowModal={modalHandler}
                />
            )}


            <div className='form'>

                <h3>RESET YOUR PASSWORD</h3>

                <form onSubmit={handleSubmit}>

                    <div className="password">
                        <input
                            placeholder='New Password'
                            type={isReveal ? "text" : "password"}
                            minLength="6"
                            required
                            name="password"
                            ref={password}
                        />
                        <label onClick={togglePassword}>{isReveal ? "Hide" : "Show"}</label>
                    </div>

                    <div className="password">
                        <input
                            placeholder='Confirm New Password'
                            type={isReveal ? "text" : "password"}
                            required
                            name="email"
                            ref={confirmPassword}

                        />
                        
                    </div>


                    <button type="submit">
                        <p>RESET PASSWORD</p>
                    </button>

                    <span style={{ color: 'red', marginTop: 20 }}>{error && error}</span>
                    {error &&
                        <Link to="/forgot-password" className='link'>
                            <span style={{ color: '#ba68c8', fontSize: 14, textDecoration: 'underline' }}>Try Again: Forgot Password?</span>
                        </Link>
                    }


                </form>

            </div>

        </div>
    )
}

export default ResetPassword