import React from 'react';
import './policies.scss';

const TermsConditions = () => {
    return (
        <div className='terms'>
            TermsConditions
            TermsConditions
            TermsConditions
            TermsConditions
            TermsConditions
            TermsConditions
            TermsConditions
            TermsConditions
        </div>
    )
}

export default TermsConditions