import React, { useEffect } from 'react';
import './adminComps.scss';
import { DataGrid } from '@mui/x-data-grid';
import { Link, useLocation } from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios';


const AdminProducts = () => {
	const location = useLocation();
	const path = location.pathname.split("/")[1];

	const [products, setProducts] = useState([]);

	useEffect(() => {
		const fetchProducts = async () => {
			const res = await axios.get("https://tunjoo-server-36b3be406a89.herokuapp.com/api/products")
			setProducts(res.data)
		}
		fetchProducts();
	}, []);

	// const handleDelete = async (id) => {
	//       try {
	//             await axios.delete(`https://tunjoo-server-36b3be406a89.herokuapp.com/api/users/del/${id}`);
	//             setUsers(users.filter((item) => item._id !== id));
	//       } catch (err) { }
	// };


	const columns = [
		{
			field: "id",
			headerName: "#",
			width: 40,
		},
		{
			field: "product",
			headerName: "Product",
			width: 170,
			renderCell: (params) => {
				return (
					<div className="cellWidthImg">
						<img className="cellImg"
							src={params.row.photos[0] || "https://i.ibb.co/MBtjqXQ/no-avatar.gif"}
							alt="avatar"
						/>
						{params.row.productCode}
					</div>
				);
			},
		},
		{ field: "group", headerName: "Group", width: 80 },
		{ field: "brand", headerName: "Brand", width: 80 },
		{ field: "category", headerName: "Category", width: 80 },
		{ field: "mainCat", headerName: "mainCat", width: 100 },
		{ field: "subCat", headerName: "subCat", width: 100 },
		{
			field: "buyingPrice",
			headerName: "Buying Price",
			width: 110,
			renderCell: (params) => {
				return (
					<div className="buyingPrice">
						{params.row.buyingPrice?.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}
					</div>
				);
			},
		},
		{
			field: "discountPrice",
			headerName: "Discount Price",
			width: 110,
			renderCell: (params) => {
				return (
					<div className="discountPrice">
						{params.row.discountPrice?.toLocaleString(navigator.language, { minimumFractionDigits: 0 }) || "0"}
					</div>
				);
			},
		},
		{
			field: "sellingPrice",
			headerName: "Selling Price",
			width: 110,
			renderCell: (params) => {
				return (
					<div className="sellingPrice">
						{params.row.sellingPrice?.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}
					</div>
				);
			},
		},
		{ field: "inStock", headerName: "inStock", width: 100 },
		{ field: "sold", headerName: "sold", width: 100 },
		{
			field: "profit",
			headerName: "Profit",
			width: 110,
			renderCell: (params) => {
				return (
					<div className="profit">
						{params.row.profit?.toLocaleString(navigator.language, { minimumFractionDigits: 0 }) || "0"}
					</div>
				);
			},
		},


		{
			field: "action",
			headerName: "Action",
			width: 130,
			renderCell: (params) => {
				return (
					<div className="cellAction">

						<Link to={{ pathname: `/${path}/` + params.row._id, user: params.row }} style={{ textDecoration: "none", color: 'darkgreen' }}>
							<div className="viewButton">View</div>
						</Link>

						{/* <div
                                          className="deleteButton"
                                          onClick={() => handleDelete(params.row._id)}
                                    >
                                          Delete
                                    </div> */}
					</div>

				);
			},
		},
	];

	return (
		// <div className='adminProducts'>

			<div className="productsContainer">

			<div className='pdtDatatable'>
				<div className="pdtTableTitle" style={{ textTransform: 'capitalize' }}>
					{path}
					<Link to={`/${path}/new`} style={{ textDecoration: 'none' }} className="link">
						Add New
					</Link>
				</div>


				<DataGrid
					className='datagrid'
					rows={products.map((item, index) => { return { id: index + 1, ...item } })}
					columns={columns}
					pageSize={9}
					rowsPerPageOptions={[9]}
					// checkboxSelection
					getRowId={(row) => row._id}
				/>
			</div>

			</div>

		// </div>
	)
}

export default AdminProducts