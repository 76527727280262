import React, { useEffect, useState } from 'react';
import './shipping.scss';
import { Link } from 'react-router-dom';
import { FaWhatsappSquare } from "react-icons/fa";
import { FaSquarePhone } from "react-icons/fa6";
import Standard from './Standard';
import Express from './Express';
import ShippingInfoList from './ShippingInfoList';
import axios from 'axios';
// import { useSelector } from 'react-redux';
// import { IoIosUnlock } from "react-icons/io";


const QuickCheckout = () => {

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    // const currentUser = useSelector((state) => state.user.currentUser);
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const [userCart, setUserCart] = useState({});
    useEffect(() => {
        const getUserCart = async () => {
            try {
                const res = await axios.get(`https://tunjoo-server-36b3be406a89.herokuapp.com/api/carts/find/${currentUser?._id}`);
                setUserCart(res.data);
            } catch (err) { }
        };
        getUserCart();
    }, [currentUser?._id]);
    

    const [selected, setSelected] = useState("Standard Delivery");
    
    const list = [
        {
            id: 1,
            shipping: 10000,
            title: "Standard Delivery",
            desc: "1-3 Business Days around Kampala"
        },
        {
            id: 2,
            shipping: 15000,
            title: "Express Delivery",
            desc: "Same Day Delivery around Kampala"
        },
    ]

    const shippingCost = () => {
        let price = 0;
        if(userCart.totalAmount > 400000){
            price = 0;
        }else if (userCart.totalAmount < 400000 && selected === "Standard Delivery") {
            price = 500;
        }else if (userCart.totalAmount < 400000 && selected === "Express Delivery") {
            price = 15000;
        }
        return price
    };

    let shipping = shippingCost();
    let amount = userCart.totalAmount;

    function myFunction(a, b) {
        return a + b;
    }
    let result = myFunction(shipping, amount);

    const renderCurrentSelection = () => {
        switch (selected) {
            case "Standard Delivery":
                return <Standard amount={result}/>;
            case "Express Delivery":
                return <Express />;
            default:
                return <Standard />;
        }
    }


    return (
        <div className='shippingPage'>

            <div className="shippingHeader">
                <div className='checkoutTop'>
                    <Link className='link' to="/" onClick={scrollToTop}>
                        <div className="checkoutLogoWrapper">
                            <span className='checkoutLogo'>TUNJOO.</span>
                        </div>
                    </Link>
                </div>

                <div className="topContact">
                    <div className="topItem">
                        <div className="item">
                            <FaSquarePhone className='topIcon' />
                            <span>Call +256 775326157</span>
                        </div>
                        <div className="item">
                            <FaWhatsappSquare className='topIcon' />
                            <span>Chat with us now</span>
                            <p>(online)</p>
                        </div>
                    </div>

                    <span className='time'>7 days a week, 7AM - 10PM</span>
                </div>

            </div>

            <div className="chekoutShippingWrapper">

                <div className="shippingLeft">

                    <div className="leftItem">
                        <h3>Select Delivery Method</h3>
                        <ul className="deliveryMethod">
                            {list.map((item) => (
                                <ShippingInfoList
                                    id={item.title}
                                    value={item.shipping}
                                    desc={item.desc}
                                    active={selected === item.title}
                                    setSelected={setSelected}
                                    className={selected ? "shipping-typeItem active" : "shipping-typeItem"}
                                />
                            ))}

                        </ul>


                    </div>

                    <div className="detailsWrapper">
                        {renderCurrentSelection()}
                    </div>

                </div>

                <div className="shippingRight">
                    <div className="top">
                        <h3>Your Order Summary</h3>
                        <h4>Total Items ({userCart.totalQty})</h4>
                    </div>
                    <hr />

                    <div className="productsWrapper">
                        {userCart?.products?.map((product) => (
                            <>
                                <div className="productDetail">

                                    <img src={product?.image} alt="" />

                                    <div className="details">
                                        <span className='productBrand'>{product?.brand}</span>
                                        <span className='productName'>{product?.productName}</span>

                                        <div className="detailsItem">
                                            <p>Color:</p>
                                            <p>{product?.color}</p>
                                        </div>
                                        <div className="detailsItem">
                                            <p>Size:</p>
                                            <p>{product?.productSize}</p>
                                        </div>
                                        <div className="detailsItem">
                                            <p>Qty:</p>
                                            <p>{product?.productQuantity}</p>
                                        </div>

                                        <div className="priceWrapper">

                                            <div className="priceItem1">
                                                <span>Price:</span>
                                                <div className="discountItem">
                                                    <p>UGX</p>
                                                    <span>{product?.productPrice}</span>
                                                </div>
                                            </div>
                                            <div className="priceItem2">
                                                <span>Total:</span>
                                                <div className="discountItem">
                                                    <p>UGX</p>
                                                    <span>{product?.productTotal}</span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                                <hr />
                            </>
                        ))}
                    </div>

                    <div className="totalWrapper">
                        <div className="totalItem">
                            <span>Subtotal</span>
                            <h4>{userCart.totalAmount?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</h4>
                        </div>
                        <hr />

                        <div className="totalItem">
                            <span>Shipping</span>
                            <h4>{shippingCost()?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</h4>
                        </div>
                        <hr />

                        <div className="totalItem2">
                            <span>TOTAL</span>
                            <h4>{result?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</h4>
                        </div>

                    </div>

                </div>

            </div>

        </div>
    )
}

export default QuickCheckout