import React from 'react';
import './slide.scss';
// import { Link } from 'react-router-dom';
import { brandLogos } from '../dummydata';
// import ProductCard from '../card/ProductCard';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';


const LogoSlide = () => {

    const slideItems = brandLogos.map((item) => {

        return (
            <div className="logoSlideItems">
                <div className='item' item={item} key={item.id}>
                    <img src={item.logo} alt="logo" />
                </div>
            </div>
        )
    });

    const responsive = {
        0: { items: 5},
        576: { items: 6},
        720: { items: 7},
        1024: { items: 8},
        1100: {items: 10}
    };


    return (
        <div className="logoSlideCarousel">
            <AliceCarousel
                infinite
                autoPlay
                interval={null}
                // mouseTracking
                items={slideItems}
                disableDotsControls
                disableButtonsControls
                responsive={responsive}
                autoPlayInterval={50}
                animationDuration={1500}
            />
        </div>

    )
}

export default LogoSlide