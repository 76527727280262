import React, { useEffect, useState } from 'react';
import './single.scss';
import newRequest from '../../config/newRequest';
import { useLocation } from 'react-router-dom';
import axios from 'axios';


const SingleAdminProduct = () => {

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    const location = useLocation();
    const itemID = location.pathname.split("/")[2];
    // const currentUser = JSON.parse(localStorage.getItem("currentUser"));

    // ------------ FETCH SINGLE PRODUCT -----------
    const [product, setProduct] = useState({});
    // const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        // setLoading(true);
        const fetchProduct = async () => {
            const res = await axios.get("https://tunjoo-server-36b3be406a89.herokuapp.com/api/products/single/" + itemID)
            // setLoading(false);
            setProduct(res.data);
        }
        fetchProduct();
    }, [itemID]);


    //EDIT / UPDATE USER
    const [category, setCategory] = useState([]);
    const [otherSizes, setOtherSizes] = useState([]);

    const [newProduct, setNewProduct] = useState({
        productName: product.productName,
        productCode: product.productCode,
        category: product.category,
        mainCat: product.mainCat,
        subCat: product.subCat,
        brand: product.brand,
        group: product.group,
        inStock: product.inStock,
        sold: product.sold,
        size: product.size,
        color: product.color,
        otherSizes: product.otherSizes,
        buyingPrice: product.buyingPrice,
        sellingPrice: product.sellingPrice,
        discountPrice: product.discountPrice,
        isWtrending: false,
        isMtrending: false,
    });

    console.log(product)
    console.log(newProduct)


    const handleChange = (e) => {
        setNewProduct((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleOtherSizes = (e) => {
        setOtherSizes(e.target.value.split(","));
    };

    const handleCat = (e) => {
        setCategory(e.target.value.split(","));
    };

    const handleWtrending = (e) => {
        setNewProduct((prev) => {
            return { ...prev, isWtrending: e.target.checked };
        });
    };
    const handleMtrending = (e) => {
        setNewProduct((prev) => {
            return { ...prev, isMtrending: e.target.checked };
        });
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const updatedProduct = {
                ...newProduct,
                category: category,
                otherSizes: otherSizes,
            };

            await newRequest.put("/api/products/update/" + product._id, updatedProduct);
            window.location.reload();
        } catch (err) {
            return (err);
        }

    }



    return (
        <div className='editMember'>

            <div className="wrapper">

                <div className="singleLeft">
                    <h2 className="title">Product Details:</h2>


                    <div className="item">
                        {product?.photos?.length > 0 && (
                            <div className="imgWrapper">
                                <img src={product?.photos[0]} alt="" />
                            </div>
                        )}


                        <div className="itemDetails">

                            <h3 className="detailsTitle">{product.productName}</h3>

                            <div className="detailsItem">
                                <span className="itemKey">Product Code:</span>
                                <span className="itemValue">{product.productCode}</span>
                            </div>

                            <div className="detailsItem">
                                <span className="itemKey">Category:</span>
                                <span className="itemValue">{product.category + ","}</span>
                            </div>

                            <div className="detailsItem">
                                <span className="itemKey">MainCat:</span>
                                <span className="itemValue">{product.mainCat}</span>
                            </div>

                            <div className="detailsItem">
                                <span className="itemKey">DicountPrice:</span>
                                <span className="itemValue3">{product.discountPrice?.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</span>
                            </div>

                            <div className="itemDetailsWrapper">
                                <div className="detailsItem">
                                    <span className="itemKey">Buying Price:</span>
                                    <span className="itemValue">{product.buyingPrice}</span>
                                </div>

                                <div className="detailsItem">
                                    <span className="itemKey">Selling Price:</span>
                                    <span className="itemValue2">{product.sellingPrice}</span>
                                </div>
                            </div>

                            <div className="itemDetailsWrapper">
                                <div className="detailsItem">
                                    <span className="itemKey">SubCat:</span>
                                    <span className="itemValue">{product.subCat}</span>
                                </div>

                                <div className="detailsItem">
                                    <span className="itemKey">Brand:</span>
                                    <span className="itemValue4">{product.brand}</span>
                                </div>

                            </div>

                            <div className="itemDetailsWrapper">
                                <div className="detailsItem">
                                    <span className="itemKey">Group:</span>
                                    <span className="itemValue">{product.group}</span>
                                </div>

                                <div className="detailsItem">
                                    <span className="itemKey">inStock:</span>
                                    <span className="itemValue">{product.inStock}</span>
                                </div>

                            </div>

                            <div className="itemDetailsWrapper">
                                <div className="detailsItem">
                                    <span className="itemKey">Items Sold:</span>
                                    <span className="itemValue">{product.sold}</span>
                                </div>

                                <div className="detailsItem">
                                    <span className="itemKey">MainSize:</span>
                                    <span className="itemValue">{product.size}</span>
                                </div>

                            </div>

                            <div className="itemDetailsWrapper">
                                <div className="detailsItem">
                                    <span className="itemKey">Color:</span>
                                    <span className="itemValue">{product.color}</span>
                                </div>

                                <div className="detailsItem">
                                    <span className="itemKey">Other Colors:</span>
                                    <span className="itemValue">{product.otherSizes + ","}</span>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>


                <div className="singleRight">
                    <h2 className="title">Edit User Info:</h2>

                    <form onSubmit={handleSubmit}>

                        <div className="inputWrapper">
                            <input
                                type="text"
                                name="productCode"
                                value={newProduct.productCode}
                                placeholder="Product Code"
                                onChange={handleChange}
                            />
                            <input
                                type="text"
                                name="productName"
                                value={newProduct.productName}
                                placeholder="Product Name"
                                onChange={handleChange}
                            />
                        </div>

                        <div className="inputWrapper">
                            <input
                                type="text"
                                name="category"
                                value={newProduct.category}
                                placeholder="Category"
                                onChange={handleCat}
                            />
                            <input
                                type="text"
                                name="mainCat"
                                value={newProduct.mainCat}
                                placeholder="Main Cat"
                                onChange={handleChange}
                            />
                        </div>

                        <div className="inputWrapper">
                            <input
                                type="text"
                                name="subCat"
                                value={newProduct.subCat}
                                placeholder="Sub Cat"
                                onChange={handleChange}
                            />
                            <input
                                type="text"
                                name="brand"
                                value={newProduct.brand}
                                placeholder="Brand"
                                onChange={handleChange}
                            />
                        </div>

                        <div className="inputWrapper">
                            <input
                                type="text"
                                name="group"
                                value={newProduct.group}
                                placeholder="Group"
                                onChange={handleChange}
                            />
                            <input
                                type="text"
                                name="inStock"
                                value={newProduct.inStock}
                                placeholder="inStock"
                                onChange={handleChange}
                            />
                        </div>

                        <div className="inputWrapper">
                            <input
                                type="text"
                                name="sold"
                                value={newProduct.sold}
                                placeholder="Sold"
                                onChange={handleChange}
                            />
                            <input
                                type="text"
                                name="size"
                                value={newProduct.size}
                                placeholder="Size"
                                onChange={handleChange}
                            />
                        </div>

                        <div className="inputWrapper">
                            <input
                                type="text"
                                name="color"
                                value={newProduct.color}
                                placeholder="Color"
                                onChange={handleChange}
                            />
                            <input
                                type="text"
                                name="otherSizes"
                                value={newProduct.otherSizes}
                                placeholder="Other Sizes"
                                onChange={handleOtherSizes}
                            />
                        </div>

                        <div className="inputWrapper">
                            <input
                                type="text"
                                name="buyingPrice"
                                value={newProduct.buyingPrice}
                                placeholder="Buying Price"
                                onChange={handleChange}
                            />
                            <input
                                type="text"
                                name="sellingPrice"
                                value={newProduct.sellingPrice}
                                placeholder="Selling Price"
                                onChange={handleChange}
                            />
                        </div>

                        <div className="inputWrapper">
                            <input
                                type="text"
                                name="discountPrice"
                                value={newProduct.discountPrice}
                                placeholder="Discount Price"
                                onChange={handleChange}
                            />

                        </div>

                        <div className="toggleWrapper">
                            <div className="toggle">
                                <label htmlFor="" className='toggleText'>Activate Women Trending</label>
                                <label className="switch">
                                    <input type="checkbox" onChange={handleWtrending} />
                                    <span className="slider round"></span>
                                </label>
                            </div>

                            <div className="toggle">
                                <label htmlFor="" className='toggleText'>Activate MEN Trending</label>
                                <label className="switch">
                                    <input type="checkbox" onChange={handleMtrending} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>

                        <button type='submit' className="formButton" onClick={scrollToTop}>Update</button>

                    </form>

                </div>

            </div>

        </div>
    )
}

export default SingleAdminProduct