import React from 'react';
import './tunjoo.scss';
import woman from './tunjoo.png';
import tunjooMan from './tunjooMan.png';
import tunjooKids from './tunjooKids1.png';
import { Link } from 'react-router-dom';
// import { IoIosArrowRoundForward } from "react-icons/io";

const TunjooLaunch = () => {

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    return (
        <div className='tunjoo'>

            <div className="imgWrapper">
                <img className='image' src={woman} alt="" />
            </div>

            <div className="text">
                <div className="textWrapper">
                    {/* <span>REINVENT YOURSELF</span> */}

                    <div className="heading">
                        {/* <h2>REDEFINING</h2>
                        <h3>WORKWEAR</h3> */}
                        <h2>BLAZER</h2>
                        <h3>SPOTLIGHT</h3>
                        {/* <span>CUSTOM-MADE TUNJOO APPAREL</span> */}
                    </div>

                    <Link to={`/Women/Clothing/Women's-Jackets-and-Blazers`} className='slide-link' onClick={scrollToTop}>
                        <button className='homeBtn'>
                            SHOP NOW
                        </button>
                    </Link>

                </div>

            </div>

        </div>
    )
}

export default TunjooLaunch


export const TunjooMan = () => {

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    return(
        <div className='tunjooMan'>

            <div className="imgWrapper">
                <img className='image' src={tunjooMan} alt="" />
            </div>

            <div className="text">
                <div className="textWrapper">

                    <div className="heading">
                        <h2>TIMELESS</h2>
                        <h3>POLO SHIRTS</h3>
                    </div>

                    <Link to={`/Men/Clothing/Men's-TShirts-and-Polo`} className='slide-link' onClick={scrollToTop}>
                        <button className='homeBtn'>
                            SHOP NOW
                        </button>
                    </Link>

                </div>

            </div>

        </div>
    )
}


export const TunjooKids = () => {
    return(
        <div className='tunjooKids'>

            <div className="imgWrapper">
                <img className='image' src={tunjooKids} alt="" />
            </div>

            <div className="text">
                <div className="textWrapper">

                   
                    <div className="heading">
                        <h2>DELIGHTFUL</h2>
                        <h3>ACCESSORIES</h3>
                    </div>

                    <Link className='slide-link'>
                        <button className='homeBtn'>
                            SHOP NOW
                        </button>
                    </Link>

                </div>

            </div>

        </div>
    )
}