import React, { useEffect, useState } from 'react';
import './kidsNav.scss';
import KidsList from './KidsList';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { MdSearch } from 'react-icons/md';
import kgirls from './kgirls.png';
import kboys from './kboys.png';
import kacc from './kacc.png';
import ksale from './ksale.png';
import kshoes from './kshoes.png';
import kbrands from './kbrands.png';

const KidsNav = () => {
    // const scrollToTop = () => {
    //     window.scrollTo(0, 0)
    // }

    // ----------- Input Filter -----------
    const [query, setQuery] = useState("");
    const handleInputChange = (event) => {
        setQuery(event.target.value);
    };

    // ----------- FETCH PRODUCTS FROM BACKEND -----------
    const [products, setProducts] = useState([]);

    useEffect(() => {
        const getProducts = async () => {
            try {
                const res = await axios.get("https://tunjoo-server-36b3be406a89.herokuapp.com/api/products");
                setProducts(res.data);
            } catch (err) { }
        };
        getProducts();
    }, []);


    const filteredItems = products.filter(
        (product) => product.productName?.toLowerCase().indexOf(query?.toLowerCase()) !== -1
    );


    const girlsNavItems = [
        {
            id: 1,
            sale: "LAUNCH SALE",
            group: "Kids",
            cat: "Kids's-Launch-Sale",
            image: ksale,
            children: [
                {
                    id: 1,
                    title: 'All Clothing',
                    group: "Kids",
                    cat: "Sale/Kids'-Sale",
                    cName: 'dropdown-link3'
                },
                {
                    id: 2,
                    title: "Girls' Sale",
                    group: "Kids",
                    cat: "Sale/Girls'-Sale",
                    cName: 'dropdown-link3'
                },
                {
                    id: 2,
                    title: "Boys' Sale",
                    group: "Kids",
                    cat: "Sale/Boys'-Sale",
                    cName: 'dropdown-link3'
                },
            ]
        },
        {
            id: 2,
            title: "BOYS",
            group: "Boys",
            itemCat: "Clothing",
            cat: "Boys's-Clothing",
            image: kboys,
            children: [
                {
                    id: 1,
                    group: "Kids",
                    itemCat: "Clothing",
                    title: 'All Clothing',
                    cat: "Boys'-Clothing",
                    cName: 'dropdown-link2'
                },
                {
                    id: 2,
                    group: "Kids",
                    itemCat: "Clothing",
                    title: 'T-Shirts | Polo Shirts',
                    cat: "Boys'-TShirts-and-Polo-Shirts",
                    cName: 'dropdown-link'
                },
                {
                    id: 3,
                    group: "Kids",
                    itemCat: "Clothing",
                    title: 'Shirts | Overshirts',
                    cat: "Boys'-Shirts-and-Overshirts",
                    cName: 'dropdown-link'
                },
                {
                    id: 4,
                    group: "Kids",
                    itemCat: "Clothing",
                    title: 'Knitwear | Sweaters',
                    cat: "Boys'-Knitwear-and-Sweaters",
                    cName: 'dropdown-link'
                },
                {
                    id: 5,
                    group: "Kids",
                    itemCat: "Clothing",
                    title: 'Hoodies | Sweatshirts',
                    cat: "Boys'-Hoodies-and-Sweatshirts",
                    cName: 'dropdown-link'
                },
                {
                    id: 6,
                    group: "Kids",
                    title: 'Bottoms',
                    itemCat: "Clothing",
                    cat: "Boys'-Bottoms",
                    cName: 'dropdown-link'
                },
                {
                    id: 7,
                    group: "Kids",
                    itemCat: "Clothing",
                    title: 'Matching Sets',
                    cat: "Boys'-Matching-Sets",
                    cName: 'dropdown-link'
                },
                {
                    id: 8,
                    group: "Kids",
                    title: 'Jackets',
                    itemCat: "Clothing",
                    cat: "Boys'-Jackets",
                    cName: 'dropdown-link'
                },

            ]
        },
        {
            id: 3,
            title: "SHOES",
            cat: "Shoes",
            group: "Kids",
            itemCat: "Shoes",
            image: kshoes,
            children: [
                {
                    id: 1,
                    group: "Kids",
                    title: 'All Shoes',
                    cat: "Kids'-Shoes",
                    itemCat: "Shoes",
                    cName: 'dropdown-link2'
                },
                {
                    id: 2,
                    group: "Kids",
                    itemCat: "Shoes",
                    title: "Girls' Shoes",
                    cat: "Girls'-Shoes",
                    cName: 'dropdown-link'
                },
                {
                    id: 3,
                    group: "Kids",
                    itemCat: "Shoes",
                    title: "Boys'Shoes",
                    cat: "Boys'-Shoes",
                    cName: 'dropdown-link'
                },

            ]
        },
        {
            id: 4,
            title: "GIRLS",
            group: "Girls",
            itemCat: "Clothing",
            cat: "Girls'-Clothing",
            image: kgirls,
            children: [
                {
                    id: 1,
                    group: "Kids",
                    itemCat: "Clothing",
                    title: 'All Clothing',
                    cat: "Girls'-Clothing",
                    cName: 'dropdown-link2'
                },
                {
                    id: 2,
                    group: "Kids",
                    itemCat: "Clothing",
                    title: 'Dresses | Jumpsuits',
                    cat: "Girls'-Dresses-and-Jumpsuits",
                    cName: 'dropdown-link'
                },
                {
                    id: 3,
                    group: "Kids",
                    itemCat: "Clothing",
                    title: 'Tops & T-Shirts',
                    cat: "Girls'-Tops",
                    cName: 'dropdown-link'
                },
                {
                    id: 4,
                    group: "Kids",
                    itemCat: "Clothing",
                    title: 'Hoodies | Sweatshirts',
                    cat: "Girls'-Hoodies-and-Sweatshirts",
                    cName: 'dropdown-link'
                },
                {
                    id: 5,
                    group: "Kids",
                    title: 'Bottoms',
                    itemCat: "Clothing",
                    cat: "Girls'-Bottoms",
                    cName: 'dropdown-link'
                },
                {
                    id: 6,
                    group: "Kids",
                    itemCat: "Clothing",
                    title: 'Matching Sets',
                    cat: "Girls'-Matching-Sets",
                    cName: 'dropdown-link'
                },
                {
                    id: 7,
                    group: "Kids",
                    itemCat: "Clothing",
                    title: "Jackets | Sweaters",
                    cat: "Girls'-Jackets-and-Sweaters",
                    cName: 'dropdown-link'
                },

            ]
        },
        {
            id: 5,
            title: "ACCESSORIES",
            group: "Girls",
            itemCat: "Accessories",
            cat: "Kids' Accessories",
            image: kacc,
            children: [
                {
                    id: 1,
                    group: "Kids",
                    title: 'All Shoes',
                    itemCat: "Accessories",
                    cat: "Kids'-Accessories",
                    cName: 'dropdown-link2'
                },
                {
                    id: 2,
                    group: "Kids",
                    title: "Girls' Bags",
                    cat: "Girls'-Bags",
                    itemCat: "Accessories",
                    cName: 'dropdown-link'
                },
                {
                    id: 3,
                    group: "Kids",
                    title: "Boys'Bags",
                    cat: "Boys'-Bags",
                    itemCat: "Accessories",
                    cName: 'dropdown-link'
                },
                {
                    id: 4,
                    group: "Kids",
                    title: "Socks & Belts",
                    cat: "Kids'-Socks-and-Belts",
                    itemCat: "Accessories",
                    cName: 'dropdown-link'
                },
               
                
            ]
        },
        {
            id: 6,
            title: "BRANDS",
            group: "Kids",
            cat: "All-Brands",
            image: kbrands,
            children: [
                {
                    id: 1,
                    group: "Kids",
                    title: 'All Brands',
                    cat: 'All-Brands',
                    itemCat: "brands",
                    cName: 'dropdown-link2'
                },
                {
                    id: 2,
                    group: "Kids",
                    title: 'Zara',
                    itemCat: "brands",
                    cat: 'ZARA-KIDS',
                    cName: 'dropdown-link'
                },
                {
                    id: 3,
                    group: "Kids",
                    title: 'H&M',
                    cat: 'H&M-KIDS',
                    itemCat: "brands",
                    cName: 'dropdown-link'
                },
                {
                    id: 4,
                    group: "Kids",
                    title: 'Gap',
                    cat: 'GAP-KIDS',
                    itemCat: "brands",
                    cName: 'dropdown-link'
                },
                {
                    id: 5,
                    group: "Kids",
                    title: 'Juniors',
                    cat: 'JUNIORS',
                    itemCat: "brands",
                    cName: 'dropdown-link'
                },
                {
                    id: 6,
                    group: "Kids",
                    title: 'Kappa',
                    itemCat: "brands",
                    cat: 'KAPPA-KIDS',
                    cName: 'dropdown-link'
                },
               

            ]

        },
       
    ];


    return (
        <div className='kidsNav'>
            <div className="kidsNavLeft">

                <div className="navbar-links">
                    <ul className="navList">
                        {girlsNavItems.map((item) => (
                            <KidsList key={item.id} item={item} />
                        ))}
                    </ul>
                </div>

            </div>

            <div className="kidsNavRight">
                <div className="searchWrapper">
                    <input
                        type="text"
                        value={query}
                        onChange={handleInputChange}
                        placeholder='Search Products...'
                        className='searchInput'
                    />
                    <div className="navSearchItem">
                        <MdSearch className='navSearchIcon' />
                    </div>

                    {query && (
                        <div className="navSearchOptions">
                            {filteredItems.map((item) => (
                                <div key={item._id}>
                                    <Link className='optionsLink' to={`/${item.group}/${item.mainCat}/${item._id}`}>
                                        <div className="searchItemWrapper" key={item._id}>
                                            <img src={item?.photos[0]} className='searchImg' alt='' />
                                            <div className="searchText">
                                                <span>{item.productName}</span>
                                                <p>in {item.group} - {item.mainCat}</p>
                                            </div>
                                        </div>

                                    </Link>
                                </div>


                            ))}

                        </div>
                    )} 

                </div>
            </div>



        </div>
    )
}

export default KidsNav