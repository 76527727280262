import React, { useState } from 'react';
import "./reset.scss";
import { Link } from 'react-router-dom';
import ResetModal from './ResetModal';
import WomenNav from '../../../components/navbar/women/WomenNav';
import { publicRequest } from "../../../requestMethod";



function ForgotPassword() {

    const [email, setEmail] = useState("");
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState();

    
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const res = await publicRequest.post("/auth/forgot-password",
                { email: email },
            );
            if (res.data) {
                setShowModal({
                    title: "We Got You!",
                    message: res.data.message,
                });
                return;
            }
        } catch (err) {
            setError(err.response.data);
        }
    };

    const modalHandler = () => {
        setShowModal(null);
    };



    return (
        <div className='reset-login'>

            <WomenNav/>

            {showModal && (
                    <ResetModal
                        title={showModal.title}
                        message={showModal.message}
                        onShowModal={modalHandler}
                    />
            )}

            <div className='form'>

                <h4>ENTER YOUR EMAIL TO RESET PASSWORD</h4>

                <form onSubmit={handleSubmit}>
                    <input
                        placeholder='Email'
                        type="email"
                        required
                        name="email"
                        onChange={e => setEmail(e.target.value)}

                    />

                    <button  type="submit">
                        <p>SUBMIT</p>
                    </button>

                    <span 
                    style={{
                        color:'red', 
                        fontSize: '13px', 
                        fontWeight: 300,
                        fontFamily: "'Roboto', sans-serif"
                    }}>
                        {error && error}
                    </span>


                </form>

            </div>

            <div className="bottom">
            
                <div className="bottomItem">
                    <span>DON'T HAVE AN ACCOUNT?</span>
                    <Link className='link' to="/register" >
                        <p>REGISTER</p>
                    </Link>
                </div>

            </div>

        </div>
    )
}

export default ForgotPassword