import React from 'react';
import './notfound.scss';

const NotFound = () => {
    return (
        <div className="not-found">
            <h2>404</h2>
            <p>Page not found</p>
        </div>
    )
}

export default NotFound

