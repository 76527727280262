import React from 'react';
import './card.scss';
// import { useLocation } from 'react-router-dom';
// const ListCard = ({ desc, photos, brand, sellingPrice, discountPrice }) => {
const ListCard = ({ item }) => {

    // const location = useLocation();
    // const path1 = location.pathname.split("/")[1];
    // const path2 = location.pathname.split("/")[2];

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }


    return (
        // <Link className="link" to={`/${path1}/${path2}/${item._id}`} onClick={scrollToTop}>
            <div className='listCard' onClick={scrollToTop}>
                <div className="image">
                    <img src={item.photos[0]} alt="" className='mainImg' />
                    <img src={item.photos[1]} alt="" className='secImg' />
                </div>

                <div className="detailsWrapper">
                    <div className="titles">
                        <h3>{item.brand}</h3>
                        <span>{item.productName}</span>
                    </div>

                        {item?.discountPrice > 0 ?
                            <div className="discountWrapper">
                                <div className="discountItem2">
                                    <p>UGX</p>
                                    <span>{item?.discountPrice?.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</span>
                                </div>
                                <div className="discountItem1">
                                    <p>UGX</p>
                                    <span>{item?.sellingPrice?.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</span>
                                </div>
                            </div>

                            :
                            <div className="priceWrapper">
                                <span className='price1'>UGX</span>
                                <span className='price3'>{item?.sellingPrice?.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</span>
                            </div>

                        }


                </div>
            </div>
        // </Link>
    )
}

export default ListCard







// import React from 'react';
// import './card.scss';
// import { Link, useLocation } from 'react-router-dom';

// const ListCard = ({ item }) => {

//     const { symbol } = PriceState();
//     const location = useLocation();
//     const path1 = location.pathname.split("/")[1];
//     const path2 = location.pathname.split("/")[2];

//     const scrollToTop = () => {
//         window.scrollTo(0, 0)
//     }


//     return (
//         <Link className="link" to={`/${path1}/${path2}/${item.desc}`} onClick={scrollToTop}>
//             <div className='listCard'>
//                 <div className="image">
//                     <img src={item?.photos[0]} alt="" className='mainImg' />
//                     <img src={item.photos[1]} alt="" className='secImg' />
//                 </div>
//                 <div className="titles">
//                     <h3>{item.brand}</h3>
//                     <span>{item.desc}</span>
//                 </div>
//                 {item.discountPrice ?
//                     <div className="prices2">
//                         <h4>{symbol}{item.sellingPrice?.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</h4>
//                         <h4>{symbol}{item.discountPrice?.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</h4>
//                     </div>
//                     :
//                     <div className="prices">
//                         <h4>{symbol}</h4>
//                         <h4>{item.sellingPrice?.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</h4>
//                     </div>
//                 }
//             </div>
//         </Link>
//     )
// }

// export default ListCard