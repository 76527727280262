// ------------ ADMIN ICONS -----------
import { MdDashboard } from "react-icons/md";
import {  SlHeart } from "react-icons/sl";
import { TbTruckDelivery } from "react-icons/tb";
import { IoPeopleOutline } from "react-icons/io5";
import { HiOutlineShoppingBag } from "react-icons/hi"
import { GrTransaction } from "react-icons/gr";

// ------------ ADMIN COMPONENTS -----------
import Dashboard from "./admin/adminComps/Dashboard";
import AdminOrders from "./admin/adminComps/AdminOrders";
import AdminWishlist from "./admin/adminComps/AdminWishlist";
import Products from "./admin/adminComps/AdminProducts";
import Users from "./admin/adminComps/Users";


const routes = [
    {
      label: "Dashboard",
      path: "/admin/dashboard",
      icon: <MdDashboard/>,
      component: <Dashboard/>,
    },
    {
      label: "Users",
      path: "/admin/users",
      icon: <IoPeopleOutline/>,
      component: <Users/>,
    },
    {
      label: "Products",
      path: "/admin/products",
      icon: <HiOutlineShoppingBag/>,
      component: <Products/>,
    },
    {
      label: "Orders",
      path: "/admin/orders",
      icon: <TbTruckDelivery/>,
      component: <AdminOrders/>,
    },
    {
      label: "Wishlist",
      path: "/admin/wishlist",
      icon: <SlHeart/>,
      component: <AdminWishlist/>,
    },
    {
      label: "Transactions",
      path: "/admin/transactions",
      icon: <GrTransaction/>,
      component: <AdminWishlist/>,
    },
    // {
    //   label: "Sign Out",
    //   path: "/sign-out",
    //   icon: <RiLogoutCircleRLine/>,
    // },
];
  
  export default routes;