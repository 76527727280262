import React from 'react';
import './policies.scss';

const PrivacyPolicy = () => {
    return (
        <div className='privacy'>
            PrivacyPolicy
        </div>
    )
}

export default PrivacyPolicy