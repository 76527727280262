import React, { useState } from 'react';
import '../responseNav.scss';
import { Link } from 'react-router-dom';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { FaHome } from "react-icons/fa";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const ResKids = ({ sidebar, setSidebar }) => {

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }


    const boysClothingItems =[
        {
            id: 1,
            group: "Kids",
            itemCat: "Clothing",
            title: 'All Clothing',
            cat: "Boys'-Clothing",
            cName: 'dropdown-link2'
        },
        {
            id: 2,
            group: "Kids",
            itemCat: "Clothing",
            title: 'T-Shirts | Polo Shirts',
            cat: "Boys'-TShirts-and-Polo-Shirts",
            cName: 'dropdown-link'
        },
        {
            id: 3,
            group: "Kids",
            itemCat: "Clothing",
            title: 'Shirts | Overshirts',
            cat: "Boys'-Shirts-and-Overshirts",
            cName: 'dropdown-link'
        },
        {
            id: 4,
            group: "Kids",
            itemCat: "Clothing",
            title: 'Knitwear | Sweaters',
            cat: "Boys'-Knitwear-and-Sweaters",
            cName: 'dropdown-link'
        },
        {
            id: 5,
            group: "Kids",
            itemCat: "Clothing",
            title: 'Hoodies | Sweatshirts',
            cat: "Boys'-Hoodies-and-Sweatshirts",
            cName: 'dropdown-link'
        },
        {
            id: 6,
            group: "Kids",
            title: 'Bottoms',
            itemCat: "Clothing",
            cat: "Boys'-Bottoms",
            cName: 'dropdown-link'
        },
        {
            id: 7,
            group: "Kids",
            itemCat: "Clothing",
            title: 'Matching Sets',
            cat: "Boys'-Matching-Sets",
            cName: 'dropdown-link'
        },
        {
            id: 8,
            group: "Kids",
            title: 'Jackets',
            itemCat: "Clothing",
            cat: "Boys'-Jackets",
            cName: 'dropdown-link'
        },
    ]

    const girlsClothing = [
        {
            id: 1,
            group: "Kids",
            itemCat: "Clothing",
            title: 'All Clothing',
            cat: "Girls'-Clothing",
            cName: 'dropdown-link2'
        },
        {
            id: 2,
            group: "Kids",
            itemCat: "Clothing",
            title: 'Dresses | Jumpsuits',
            cat: "Girls'-Dresses-and-Jumpsuits",
            cName: 'dropdown-link'
        },
        {
            id: 3,
            group: "Kids",
            itemCat: "Clothing",
            title: 'Tops & T-Shirts',
            cat: "Girls'-Tops",
            cName: 'dropdown-link'
        },
        {
            id: 4,
            group: "Kids",
            itemCat: "Clothing",
            title: 'Hoodies | Sweatshirts',
            cat: "Girls'-Hoodies-and-Sweatshirts",
            cName: 'dropdown-link'
        },
        {
            id: 5,
            group: "Kids",
            title: 'Bottoms',
            itemCat: "Clothing",
            cat: "Girls'-Bottoms",
            cName: 'dropdown-link'
        },
        {
            id: 6,
            group: "Kids",
            itemCat: "Clothing",
            title: 'Matching Sets',
            cat: "Girls'-Matching-Sets",
            cName: 'dropdown-link'
        },
        {
            id: 7,
            group: "Kids",
            itemCat: "Clothing",
            title: "Jackets | Sweaters",
            cat: "Girls'-Jackets-and-Sweaters",
            cName: 'dropdown-link'
        },
    ]

    const shoeItems =[
        {
            id: 1,
            group: "Kids",
            title: 'All Shoes',
            cat: "Kids'-Shoes",
            itemCat: "Shoes",
            cName: 'dropdown-link2'
        },
        {
            id: 2,
            group: "Kids",
            itemCat: "Shoes",
            title: "Girls' Shoes",
            cat: "Girls'-Shoes",
            cName: 'dropdown-link'
        },
        {
            id: 3,
            group: "Kids",
            itemCat: "Shoes",
            title: "Boys'Shoes",
            cat: "Boys'-Shoes",
            cName: 'dropdown-link'
        },
    ]

    const accItems = [
        {
            id: 1,
            group: "Kids",
            title: 'All Shoes',
            itemCat: "Accessories",
            cat: "Kids'-Accessories",
            cName: 'dropdown-link2'
        },
        {
            id: 2,
            group: "Kids",
            title: "Girls' Bags",
            cat: "Girls'-Bags",
            itemCat: "Accessories",
            cName: 'dropdown-link'
        },
        {
            id: 3,
            group: "Kids",
            title: "Boys'Bags",
            cat: "Boys'-Bags",
            itemCat: "Accessories",
            cName: 'dropdown-link'
        },
        {
            id: 4,
            group: "Kids",
            title: "Socks & Belts",
            cat: "Kids'-Socks-and-Belts",
            itemCat: "Accessories",
            cName: 'dropdown-link'
        },
    ]


    const brandItems = [
        {
            id: 1,
            group: "Kids",
            title: 'All Brands',
            cat: 'All-Brands',
            itemCat: "brands",
            cName: 'dropdown-link2'
        },
        {
            id: 2,
            group: "Kids",
            title: 'Zara',
            cat: 'ZARA-KIDS',
            itemCat: "brands",
            cName: 'dropdown-link'
        },
        {
            id: 3,
            group: "Kids",
            title: 'H&M',
            cat: 'H&M-KIDS',
            itemCat: "brands",
            cName: 'dropdown-link'
        },
        {
            id: 4,
            group: "Kids",
            title: 'Gap',
            cat: 'GAP-KIDS',
            itemCat: "brands",
            cName: 'dropdown-link'
        },
        {
            id: 5,
            group: "Kids",
            title: 'Juniors',
            cat: 'JUNIORS',
            itemCat: "brands",
            cName: 'dropdown-link'
        },
        {
            id: 6,
            group: "Kids",
            title: 'Kappa',
            itemCat: "brands",
            cat: 'KAPPA-KIDS',
            cName: 'dropdown-link'
        },
    ]

    const [openBoys, setOpenBoys] = useState(false);
    const [openShoes, setOpenShoes] = useState(false);
    const [openGirls, setOpenGirls] = useState(false);
    const [openAcc, setOpenAcc] = useState(false);
    const [openBrands, setOpenBrands] = useState(false);


    return (

        <div className="resLists">

            <ul className='resLinksWrapper'>

                <li onClick={sidebar}>
                <Link to="/kids" className='kids-home-item' onClick={scrollToTop}>
                    <FaHome className='sideHomeIcon' />
                    <span>KIDS'S HOME PAGE</span>
                </Link>
            </li>

            {/* CLOTHING */}
            <li className="sideLinks" onClick={() => setOpenBoys(!openBoys)}>
                <span>BOY'S CLOTHING</span>
                {openBoys ? <IoIosArrowUp className='dropIcon' /> : <IoIosArrowDown className='dropIcon' />}
            </li>
            {openBoys && (
                <ul className="options22" onClick={() => setOpenBoys(!openBoys)}>

                   {boysClothingItems.map((item) =>(
                        <li onClick={() => setSidebar(false)}>
                            <Link to={`/${item.group}/${item.itemCat}/${item.cat}`} className="sideLink2" onClick={scrollToTop}>
                                <p>{item.title}</p>
                                <MdKeyboardArrowRight className='dropIcon' />
                            </Link>
                        </li>
                    ))}


                </ul>
            )}

            {/* GIRLS CLOTHING */}
            <li className="sideLinks" onClick={() => setOpenGirls(!openGirls)}>
                <span>GIRL'S CLOTHING</span>
                {openGirls ? <IoIosArrowUp className='dropIcon' /> : <IoIosArrowDown className='dropIcon' />}
            </li>
            {openGirls && (
                <ul className="options22" onClick={() => setOpenGirls(!openGirls)}>

                   {girlsClothing.map((item) =>(
                        <li onClick={() => setSidebar(false)}>
                            <Link to={`/${item.group}/${item.itemCat}/${item.cat}`} className="sideLink2" onClick={scrollToTop}>
                                <p>{item.title}</p>
                                <MdKeyboardArrowRight className='dropIcon' />
                            </Link>
                        </li>
                    ))}


                </ul>
            )}


            {/* SHOES */}
            <li className="sideLinks" onClick={() => setOpenShoes(!openShoes)}>
                <span>SHOES</span>
                {openShoes ? <IoIosArrowUp className='dropIcon' /> : <IoIosArrowDown className='dropIcon' />}
            </li>
            {openShoes && (
                <ul className="options22" onClick={() => setOpenShoes(!openShoes)}>

                   {shoeItems.map((item) =>(
                        <li onClick={() => setSidebar(false)}>
                            <Link to={`/${item.group}/${item.itemCat}/${item.cat}`} className="sideLink2" onClick={scrollToTop}>
                                <p>{item.title}</p>
                                <MdKeyboardArrowRight className='dropIcon' />
                            </Link>
                        </li>
                    ))}


                </ul>
            )}

            {/* ACCESSORIES */}
            <li className="sideLinks" onClick={() => setOpenAcc(!openAcc)}>
                <span>ACCESSORIES</span>
                {openAcc ? <IoIosArrowUp className='dropIcon' /> : <IoIosArrowDown className='dropIcon' />}
            </li>
            {openAcc && (
                <ul className="options22" onClick={() => setOpenAcc(!openAcc)}>

                   {accItems.map((item) =>(
                        <li onClick={() => setSidebar(false)}>
                            <Link to={`/${item.group}/${item.itemCat}/${item.cat}`} className="sideLink2" onClick={scrollToTop}>
                                <p>{item.title}</p>
                                <MdKeyboardArrowRight className='dropIcon' />
                            </Link>
                        </li>
                    ))}


                </ul>
            )}

            {/* BRANDS */}
            <li className="sideLinks" onClick={() => setOpenBrands(!openBrands)}>
                <span>BRANDS</span>
                {openBrands ? <IoIosArrowUp className='dropIcon' /> : <IoIosArrowDown className='dropIcon' />}
            </li>
            {openBrands && (
                <ul className="options22" onClick={() => setOpenBrands(!openBrands)}>

                   {brandItems.map((item) =>(
                        <li onClick={() => setSidebar(false)}>
                            <Link to={`/${item.group}/${item.itemCat}/${item.cat}`} className="sideLink2" onClick={scrollToTop}>
                                <p>{item.title}</p>
                                <MdKeyboardArrowRight className='dropIcon' />
                            </Link>
                        </li>
                    ))}


                </ul>
            )}

            </ul>


        </div>
    )

}

export default ResKids