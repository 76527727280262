const Spinner = ({ loading }) => {
    if (!loading) {
        return null;
    }

    return (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
    );
};


export default Spinner