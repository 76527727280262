import React from 'react';
import './accounthome.scss';
// import { useSelector } from 'react-redux';
import logo from '../../assets/tunjooLogo.png';


//icons
import { FaMoneyBills } from "react-icons/fa6";
import { BsSendArrowUpFill } from "react-icons/bs";
import { FaGift } from "react-icons/fa6";

const Wallet = () => {

    const currentUser = JSON.parse(localStorage.getItem("currentUser"));

    return (
        <div className='accWallet'>

            <div className="accTop">
                <div className="left">
                    <p>Hi...</p>
                    <h3>{currentUser.firstname + " " + currentUser.lastname}</h3>

                    <hr className='accDivider' />

                    <div className="accBalance">
                        <h2>Account Balance</h2>
                        <div className="amount">
                            <span>UGX</span>
                            <h2>300,000</h2>
                        </div>
                    </div>

                    <div className="accBtns">
                        <button className='depositBtn'>
                            <FaMoneyBills className='accBtnIcon'/>
                            <h4>Deposit Money</h4>
                        </button>
                        <button className='transferBtn'>
                            <BsSendArrowUpFill className='accBtnIcon'/>
                            <h4>Transfer</h4>
                        </button>
                    </div>
                </div>

                <div className="right">
                    <div className="rightTop">
                        <img src={logo} alt="" />
                        <span className='accText'>Wallet</span>
                    </div>

                    <div className="rightBottom">
                        <h4>Cash back upto 20%</h4>
                        <FaGift className='giftIcon'/>
                    </div>

                    <div className="accFooter">
                        <p>Use your Tunjoo Wallet at checkout and get upto 20% cashback.</p>
                    </div>
                </div>
            </div>

            <hr className='accDivider2' />

            <div className="accBottom">
                <h3>Wallet Transactions</h3>

            </div>

        </div>
    )
}

export default Wallet