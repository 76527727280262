import React from 'react';
import Button from './Button';
import "./subs.scss";

const MenSubCats = ({ cat, handleClick }) => {
    return (
        <div className="subCatsWrapper">
            {/* CLOTHINGS */}

              {cat === "Men's Clothing" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="ALL PRODUCTS" />
                    <Button onClickHandler={handleClick} value="Men's Shirts and Overshirts" title="SHIRTS | OVERSHIRTS" />
                    <Button onClickHandler={handleClick} value="Men's Sweaters and Cardigans" title="SWEATERS | CARDIGANS" />
                    <Button onClickHandler={handleClick} value="Men's TShirts and Polo" title="T-SHIRTS | POLO SHIRTS " />
                    <Button onClickHandler={handleClick} value="Men's Bottoms" title="BOTTOMS" />
                    <Button onClickHandler={handleClick} value="Men's Matchingsets and Tracksuits" title="MATCHING SETS | TRACKSUITS" />
                    <Button onClickHandler={handleClick} value="Men's Jackets" title="JACKETS" />
                    <Button onClickHandler={handleClick} value="Men's Hoodies and Sweatshirts" title="HOODIES | SWEATSHIRTS" />
                </div>
            }
            {cat === "Men's Jackets" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="ALL PRODUCTS" />
                    <Button onClickHandler={handleClick} value="MenDenimJacket" title="DENIM" />
                    <Button onClickHandler={handleClick} value="MenBomberJacket" title="BOMBER" />
                    <Button onClickHandler={handleClick} value="MenLeatherJacket" title="LEATHER" />
                    <Button onClickHandler={handleClick} value="MenGillets" title="GILLETS" />
                    <Button onClickHandler={handleClick} value="MenTexturedandCottonJacket" title="TEXTURED | COTTON" />
                </div>
            }
            {cat === "Men's Tracksuits" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="VIEW ALL" />
                    <Button onClickHandler={handleClick} value="Men-CuffedHemTracksuit" title="CUFFED HEM" />
                    <Button onClickHandler={handleClick} value="Men-FlareHemTracksuit" title="FLARE HEM" />
                </div>
            }
            {/* BOTTOMS */}
            {cat === "Men's Bottoms" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="ALL PRODUCTS" />
                    <Button onClickHandler={handleClick} value="MenLinenTrousers" title="LINEN | COTTON" />
                    <Button onClickHandler={handleClick} value="MenJoggers" title="JOGGERS" />
                    <Button onClickHandler={handleClick} value="Men's Shorts" title="SHORTS" />
                    <Button onClickHandler={handleClick} value="MenCargo" title="CARGO" />
                </div>
            }
            {cat === "Men's Trousers" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="ALL PRODUCTS" />
                    <Button onClickHandler={handleClick} value="MenJoggers" title="JOGGERS" />
                    <Button onClickHandler={handleClick} value="MenLinenTrousers" title="LINEN | COTTON" />
                    <Button onClickHandler={handleClick} value="MenCargo" title="CARGO" />
                </div>
            }
            {cat === "Men's Shorts" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="ALL PRODUCTS" />
                    <Button onClickHandler={handleClick} value="MenLinenShorts" title="LINEN | COTTON" />
                    <Button onClickHandler={handleClick} value="MenDenimShorts" title="DENIM" />
                    <Button onClickHandler={handleClick} value="MenJoggerShorts" title="JOGGER | LEISURE" />
                    <Button onClickHandler={handleClick} value="MenSportsShorts" title="SPORTS" />
                </div>
            }
            {/* TSHIRTS */}
            {cat === "Men's TShirts and Polo" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="ALL PRODUCTS" />
                    <Button onClickHandler={handleClick} value="MenPoloShirts" title="POLO SHIRTS" />
                    <Button onClickHandler={handleClick} value="MenTShirts" title="T-SHIRTS" />
                </div>
            }
            {/* SHIRTS */}
            {cat === "Men's Shirts and Overshirts" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="ALL PRODUCTS" />
                    <Button onClickHandler={handleClick} value="MenShirts" title="SHIRTS" />
                    <Button onClickHandler={handleClick} value="MenOvershirts" title="OVERSHIRTS" />
                    <Button onClickHandler={handleClick} value="MenLinenShirts" title="LINEN" />
                </div>
            }
            {/* SWEATERS & CARDIGAN */}
            {cat === "Men's Sweaters and Cardigans" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="ALL PRODUCTS" />
                    <Button onClickHandler={handleClick} value="MenCardigans" title="CARDIGAN" />
                    <Button onClickHandler={handleClick} value="MenSweaters" title="SWEATERS" />
                    <Button onClickHandler={handleClick} value="MenKnitShirts" title="KNIT SHIRTS" />
                </div>
            }
             {/* HOODIES */}
            {cat === "Men's Hoodies and Sweatshirts" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="ALL PRODUCTS" />
                    <Button onClickHandler={handleClick} value="MenHoodies" title="HOODIES" />
                    <Button onClickHandler={handleClick} value="MenSweatshirts" title="SWEATSHIRTS" />
                </div>
            }

            {/* SHOES */}
            {cat === "Men's Shoes" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="ALL PRODUCTS" />
                    <Button onClickHandler={handleClick} value="Men's Sneakers" title="SNEAKERS" />
                    <Button onClickHandler={handleClick} value="Men's Formal Shoes" title="FORMAL | LOAFERS" />
                    <Button onClickHandler={handleClick} value="Men's Flat Sandals" title=" FLAT SANDALS" />
                </div>
            }
            {cat === "Men's Sneakers" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="ALL PRODUCTS" />
                    <Button onClickHandler={handleClick} value="MenCasualSneakers" title="CAUSAL SNEAKERS" />
                    <Button onClickHandler={handleClick} value="MenSportsTrainers" title="SPORTS | TRAINERS" />
                </div>
            }
            {cat === "Men's Formal Shoes" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="ALL PRODUCTS" />
                    <Button onClickHandler={handleClick} value="FormalSlipOns" title="SLIP-ONs" />
                    <Button onClickHandler={handleClick} value="FormalLaceUp" title="LACE-UPs" />
                    <Button onClickHandler={handleClick} value="MenLoafers" title="LOAFERS" />
                </div>
            }
            {cat === "Men's Flat Sandals" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="ALL PRODUCTS" />
                    <Button onClickHandler={handleClick} value="MenSandals" title="SANDALS" />
                    <Button onClickHandler={handleClick} value="MenMuleLoafers" title="MULES" />
                </div>
            }

            {/* MATCHING SETS */}
            {cat === "Men's Matchingsets and Tracksuits" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="ALL PRODUCTS" />
                    <Button onClickHandler={handleClick} value="MenLeisureSet" title="LEISURE SET" />
                    <Button onClickHandler={handleClick} value="MenTracksuit" title="TRACKSUIT" />
                </div>
            }

            {/* ACCESSORIES */}
            {cat === "Men's Accessories" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="VIEW ALL" />
                    <Button onClickHandler={handleClick} value="Men's Belts" title="BELTS" />
                    <Button onClickHandler={handleClick} value="Men's Wallets" title="WALLETS" />
                    <Button onClickHandler={handleClick} value="Men's Socks" title="SOCKS" />
                </div>
            }
            {cat === "Men's Belts" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="VIEW ALL" />
                    <Button onClickHandler={handleClick} value="MenBrownBelts" title="BROWN LEATHER" />
                    <Button onClickHandler={handleClick} value="MenBlackBelt" title="BLACK LEATHER" />
                    <Button onClickHandler={handleClick} value="MenTexturedBelt" title="TEXTURED LEATHER" />
                </div>
            }
            {cat === "Men's Wallets" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="VIEW ALL" />
                    <Button onClickHandler={handleClick} value="MenCardHolder" title="CARD HOLDER" />
                    <Button onClickHandler={handleClick} value="MenWallets" title="WALLETS" />
                </div>
            }
            {cat === "Men's Socks" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="VIEW ALL" />
                    <Button onClickHandler={handleClick} value="MenRibbedSocks" title="RIBBED SOCKS" />
                    <Button onClickHandler={handleClick} value="MenStrippedSocks" title="STRIPPED SOCKS" />
                    <Button onClickHandler={handleClick} value="MenNoShowAnckle" title="NO SHOW | ANCKLE" />
                    <Button onClickHandler={handleClick} value="MenTexturedBody" title="TEXTURED BODY" />
                    <Button onClickHandler={handleClick} value="MenTexturedCrew" title="TEXTURED CREW" />
                    
                </div>
            }


           
            {cat === "Men' Bags" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="VIEW ALL" />
                    <Button onClickHandler={handleClick} value="Men-Backpack" title="BACKPACK" />
                    <Button onClickHandler={handleClick} value="Men-CrossBag" title="Men' CROSSBAG" />
                </div>
            }
            {cat === "Kids' Socks and Belts" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="VIEW ALL" />
                    <Button onClickHandler={handleClick} value="Kids-Socks" title="SOCKS" />
                    <Button onClickHandler={handleClick} value="Kids-Tights" title="TIGHTS" />
                    <Button onClickHandler={handleClick} value="Kids-Belts" title="BELTS" />
                </div>
            }
        </div>
    )
}

export default MenSubCats