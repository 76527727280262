import React from 'react';
import './accounthome.scss';
// import { useDispatch, useSelector } from 'react-redux';
import logo from '../../assets/tunjooLogo.png';
import { useNavigate } from 'react-router-dom';
import InfoList from './InfoList';
// import { logoutUser } from '../../redux/userSlice';
import axios from 'axios';
// import { useDispatch, } from 'react-redux';

//icons
import { IoPersonOutline } from "react-icons/io5";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { SlCalender, SlHeart } from "react-icons/sl";
// import { TbTruckDelivery } from "react-icons/tb";
// import { IoWalletOutline } from "react-icons/io5";



const AccountSidebar = () => {

    // const {currentUser} = useSelector((state) => state.user);
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    // const [selected, setSelected] = useState("My Info");
    const navigate = useNavigate();
    // const dispatch = useDispatch();

    // const scrollToTop = () => {
    //     window.scrollTo(0, 0)
    // }
    // const handleLogout = () => {
    //     dispatch(logoutUser(currentUser))
    //     navigate("/");
    // }

    const handleLogout = async () => {
        try {
            await axios.post("https://tunjoo-server-36b3be406a89.herokuapp.com/api/auth/logout");
            window.localStorage.clear();
            navigate("/");
        } catch (err) {
            console.log(err);
        }
    };


    const list = [
        {
            id: 1,
            title: "My Info",
            path: `/my-account/info/${currentUser._id}`,
            icon: <IoPersonOutline />
        },
        // {
        //     id: 2,
        //     title: "My Wallet",
        //     path: `/my-account/wallet/${currentUser._id}`,
        //     icon: <IoWalletOutline />
        // },
        {
            id: 2,
            title: "My Wishlist",
            icon: <SlHeart />,
            path: `/my-account/wishlist/${currentUser._id}`,
        },
        {
            id: 3,
            title: "Order History",
            icon: <SlCalender />,
            path: `/my-account/orders/${currentUser._id}`,
        },
        // {
        //     id: 5,
        //     title: "Track My Order",
        //     icon: <TbTruckDelivery />,
        //     path: `/my-account/track-my-order/${currentUser._id}`,
        // },
        
    ]


    return (
        <div className='accMainSidebar'>
            <div className="sideInfo">
                <div className="top">
                    <img src={logo} alt="" />
                    <p>Welcome back, {currentUser.firstname}!</p>
                </div>
                <ul className="bottom">
                    {list.map((item) => (
                        <InfoList
                            id={item.title}
                            icon={item.icon}
                            path={item.path}
                        />
                    ))}
                </ul>
            </div>

            <div className='sideFooter'>
                <div className="sideFooterItem" onClick={handleLogout}>
                    <RiLogoutCircleRLine className='signoutIcon' />
                    <span>Sign Out</span>
                </div>
            </div>

        </div>
    )
}

export default AccountSidebar