import React, { useState } from 'react';
import '../responseNav.scss';
import { Link } from 'react-router-dom';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { FaHome } from "react-icons/fa";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const ResMen = ({ sidebar, setSidebar }) => {
    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    const clothingItems =[
        {
            id: 1,
            group: "Men",
            itemCat: "Clothing",
            title: 'All Clothing',
            cat: "Men's-Clothing",
            cName: 'dropdown-link2'
        },
        {
            id: 2,
            group: "Men",
            itemCat: "Clothing",
            title: 'T-Shirts | Polo Shirts',
            cat: "Men's-TShirts-and-Polo",
            cName: 'dropdown-link'
        },
        {
            id: 3,
            group: "Men",
            itemCat: "Clothing",
            title: 'Sweaters | Cardigans',
            cat: "Men's-Sweaters-and-Cardigans",
            cName: 'dropdown-link'
        },
        {
            id: 4,
            group: "Men",
            itemCat: "Clothing",
            title: 'Shirts | Overshirts',
            cat: "Men's-Shirts-and-Overshirts",
            cName: 'dropdown-link',
        },
        {
            id: 5,
            group: "Men",
            itemCat: "Clothing",
            title: 'Hoodies | Sweatshirts',
            cat: "Men's-Hoodies-and-Sweatshirts",
            cName: 'dropdown-link',
        },
        {
            id: 6,
            group: "Men",
            itemCat: "Clothing",
            title: 'Bottoms',
            cat: "Men's-Bottoms",
            cName: 'dropdown-link'
        },
        {
            id: 8,
            group: "Men",
            itemCat: "Clothing",
            title: 'Matching Sets | Tracksuits',
            cat: "Men's-Matchingsets-and-Tracksuits",
            cName: 'dropdown-link'
        },
        {
            id: 9,
            group: "Men",
            itemCat: "Clothing",
            title: 'Jackets',
            cat: "Men's-Jackets",
            cName: 'dropdown-link'
        },
    ]

    const bottomItems = [
        {
            id: 1,
            group: "Men",
            itemCat: "Clothing",
            title: 'All Bottoms',
            cat: "Men's-Bottoms",
            cName: 'dropdown-link2'
        },
        {
            id: 2,
            group: "Men",
            title: 'Trousers',
            itemCat: "Clothing",
            cat: "Men's-Trousers",
            cName: 'dropdown-link'
        },
        {
            id: 3,
            group: "Men",
            title: 'Shorts',
            itemCat: "Clothing",
            cat: "Men's-Shorts",
            cName: 'dropdown-link'
        },
    ]

    const shoeItems =[
        {
            id: 1,
            group: "Men",
            itemCat: "Shoes",
            title: 'All Shoes',
            cat: "Men's-Shoes",
            cName: 'dropdown-link2'
        },
        {
            id: 2,
            group: "Men",
            title: 'Sneakers',
            itemCat: "Shoes",
            cat: "Men's-Sneakers",
            cName: 'dropdown-link'
        },
        {
            id: 3,
            group: "Men",
            itemCat: "Shoes",
            title: 'Formal | Loafers',
            cat: "Men's-Formal-Shoes",
            cName: 'dropdown-link'
        },
        {
            id: 4,
            group: "Men",
            title: 'Sandals',
            itemCat: "Shoes",
            cat: "Men's-Flat-Sandals",
            cName: 'dropdown-link'
        },
         
    ]

    const accItems = [
        {
            id: 1,
            group: "Men",
            itemCat: "Accessories",
            title: 'All Accessories',
            cat: "Men's-Accessories",
            cName: 'dropdown-link2'
        },
        {
            id: 2,
            group: "Men",
            title: 'Belts',
            cat: "Men's-Belts",
            itemCat: "Accessories",
            cName: 'dropdown-link'
        },
        {
            id: 3,
            group: "Men",
            title: 'Wallets',
            cat: "Men's-Wallets",
            itemCat: "Accessories",
            cName: 'dropdown-link'
        },
        {
            id: 4,
            group: "Men",
            title: 'Socks',
            cat: "Men's-Socks",
            itemCat: "Accessories",
            cName: 'dropdown-link'
        },
    ]

    const brandItems = [
        {
            id: 1,
            group: "Men",
            title: 'All Brands',
            cat: 'All-Brands',
            cName: 'dropdown-link2'
        },
        {
            id: 2,
            group: "Men",
            title: 'Zara',
            itemCat: "brands",
            cat: 'ZARA-MAN',
            cName: 'dropdown-link'
        },
        {
            id: 3,
            group: "Men",
            title: 'H&M',
            cat: 'H&M-MAN',
            itemCat: "brands",
            cName: 'dropdown-link'
        },
        {
            id: 4,
            group: "Men",
            itemCat: "brands",
            title: 'Tommy Hilfigure',
            cat: 'TOMMY-HILFIGURE',
            cName: 'dropdown-link'
        },
        {
            id: 5,
            group: "Men",
            title: 'Hugo Boss',
            cat: 'HUGO-BOSS',
            itemCat: "brands",
            cName: 'dropdown-link'
        },
        {
            id: 6,
            group: "Men",
            itemCat: "brands",
            title: 'Steve Madden',
            cat: 'STEVE-MADDEN-MAN',
            cName: 'dropdown-link'
        },
        {
            id: 7,
            group: "Men",
            title: 'Gap',
            cat: 'GAP-MAN',
            itemCat: "brands",
            cName: 'dropdown-link'
        },
        {
            id: 8,
            group: "Men",
            title: 'Mango',
            cat: 'MANGO-MAN',
            itemCat: "brands",
            cName: 'dropdown-link'
        },
        {
            id: 9,
            group: "Men",
            itemCat: "brands",
            title: 'American Eagle',
            cat: 'AMERICAN-EAGLE-MAN',
            cName: 'dropdown-link'
        },
    ]

    

    

    const [openItem, setOpenItem] = useState(false);
    const [openShoes, setOpenShoes] = useState(false);
    const [openBottoms, setOpenBottoms] = useState(false);
    const [openAcc, setOpenAcc] = useState(false);
    const [openBrands, setOpenBrands] = useState(false);

    return (

        <ul className='resLinksWrapper'>

<li onClick={sidebar}>
                <Link to="/" className='men-home-item' onClick={scrollToTop}>
                    <FaHome className='sideHomeIcon' />
                    <span>MEN'S HOME PAGE</span>
                </Link>
            </li>

            {/* CLOTHING */}
            <li className="sideLinks" onClick={() => setOpenItem(!openItem)}>
                <span>CLOTHING</span>
                {openItem ? <IoIosArrowUp className='dropIcon' /> : <IoIosArrowDown className='dropIcon' />}
            </li>
            {openItem && (
                <ul className="options22" onClick={() => setOpenItem(!openItem)}>

                   {clothingItems.map((item) =>(
                        <li onClick={() => setSidebar(false)}>
                            <Link to={`/${item.group}/${item.itemCat}/${item.cat}`} className="sideLink2" onClick={scrollToTop}>
                                <p>{item.title}</p>
                                <MdKeyboardArrowRight className='dropIcon' />
                            </Link>
                        </li>
                    ))}


                </ul>
            )}

            {/* SHOES */}
            <li className="sideLinks" onClick={() => setOpenShoes(!openShoes)}>
                <span>SHOES</span>
                {openShoes ? <IoIosArrowUp className='dropIcon' /> : <IoIosArrowDown className='dropIcon' />}
            </li>
            {openShoes && (
                <ul className="options22" onClick={() => setOpenShoes(!openShoes)}>

                   {shoeItems.map((item) =>(
                        <li onClick={() => setSidebar(false)}>
                            <Link to={`/${item.group}/${item.itemCat}/${item.cat}`} className="sideLink2" onClick={scrollToTop}>
                                <p>{item.title}</p>
                                <MdKeyboardArrowRight className='dropIcon' />
                            </Link>
                        </li>
                    ))}


                </ul>
            )}

            {/* BAGS */}
            <li className="sideLinks" onClick={() => setOpenBottoms(!openBottoms)}>
                <span>BOTTOMS</span>
                {openBottoms ? <IoIosArrowUp className='dropIcon' /> : <IoIosArrowDown className='dropIcon' />}
            </li>
            {openBottoms && (
                <ul className="options22" onClick={() => setOpenBottoms(!openBottoms)}>

                   {bottomItems.map((item) =>(
                        <li onClick={() => setSidebar(false)}>
                            <Link to={`/${item.group}/${item.itemCat}/${item.cat}`} className="sideLink2" onClick={scrollToTop}>
                                <p>{item.title}</p>
                                <MdKeyboardArrowRight className='dropIcon' />
                            </Link>
                        </li>
                    ))}


                </ul>
            )}

            {/* ACCESSORIES */}
            <li className="sideLinks" onClick={() => setOpenAcc(!openAcc)}>
                <span>ACCESSORIES</span>
                {openAcc ? <IoIosArrowUp className='dropIcon' /> : <IoIosArrowDown className='dropIcon' />}
            </li>
            {openAcc && (
                <ul className="options22" onClick={() => setOpenAcc(!openAcc)}>

                   {accItems.map((item) =>(
                        <li onClick={() => setSidebar(false)}>
                            <Link to={`/${item.group}/${item.itemCat}/${item.cat}`} className="sideLink2" onClick={scrollToTop}>
                                <p>{item.title}</p>
                                <MdKeyboardArrowRight className='dropIcon' />
                            </Link>
                        </li>
                    ))}


                </ul>
            )}

            {/* BRANDS */}
            <li className="sideLinks" onClick={() => setOpenBrands(!openBrands)}>
                <span>BRANDS</span>
                {openBrands ? <IoIosArrowUp className='dropIcon' /> : <IoIosArrowDown className='dropIcon' />}
            </li>
            {openBrands && (
                <ul className="options22" onClick={() => setOpenBrands(!openBrands)}>

                   {brandItems.map((item) =>(
                        <li onClick={() => setSidebar(false)}>
                            <Link to={`/${item.group}/${item.itemCat}/${item.cat}`} className="sideLink2" onClick={scrollToTop}>
                                <p>{item.title}</p>
                                <MdKeyboardArrowRight className='dropIcon' />
                            </Link>
                        </li>
                    ))}


                </ul>
            )}

        </ul>

    )
}

export default ResMen