import React, { useState, useEffect } from 'react';
import './promo.scss';

const Promo = () => {

    // SALE CLOCK
    const [timerDays, setTimerDays] = useState();
    const [timerHours, setTimerHours] = useState();
    const [timerMins, setTimerMins] = useState();
    const [timerSecs, setTimerSecs] = useState();

    //set interval;
    let interval;

    const startTimer = () => {
        const countDownDate = new Date("April 2,2024 07:00:00").getTime();

        interval = setInterval(() => {
            const now = new Date().getTime();

            const distance = countDownDate - now;

            const days = Math.floor(distance / (24 * 60 * 60 * 1000)
            );
            const hours = Math.floor((distance % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60)
            );
            const mins = Math.floor((distance % (60 * 60 * 1000)) / (1000 * 60)
            );
            const secs = Math.floor((distance % (60 * 1000)) / (1000)
            );

            if (distance < 0) {
                //stop Timer

                clearInterval(interval.current);

            } else {
                //update timer
                setTimerDays(days);
                setTimerHours(hours);
                setTimerMins(mins);
                setTimerSecs(secs);
            }
        });

    }

    useEffect(() => {
        startTimer();
    });


    return (
        <div className='promo'>

            <div className="promoLeft">
                <h4>Coming Soon!</h4>
                <div className="itemWrapper">
                    <div className="item">
                        <span>{timerDays < 10 ? `0${timerDays}` : timerDays}</span>
                        <p>d</p>
                    </div>

                    <span className='separator'>:</span>

                    <div className="item">
                        <span>{timerHours < 10 ? `0${timerHours}` : timerHours}</span>
                        <p>h</p>
                    </div>

                    <span className='separator'>:</span>

                    <div className="item">
                        <span>{timerMins < 10 ? `0${timerMins}` : timerMins}</span>
                        <p>m</p>
                    </div>

                    <span className='separator'>:</span>

                    <div className="item">
                        <span>{timerSecs < 10 ? `0${timerSecs}` : timerSecs}</span>
                        <p>s</p>
                    </div>
                </div>

            </div>

            {/* <span className='separator1'>|</span>

            <div className="promoRight">
                <span>FREE NEXT DAY DELIVERY</span>
                <div className="textItem">
                    <h3>ON ALL K'LA ORDERS OVER UGX</h3>
                    <p>400,000</p>
                </div>
            </div> */}


        </div>
    )
}

export default Promo