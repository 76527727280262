import React, { useState } from 'react';
import './womenNav.scss';
import './dropdown.scss';
import { Link } from 'react-router-dom';

const WomenList = ({ item }) => {

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    const [dropdown, setDropdown] = useState(false);

    return (
        <li
            className="navItem"
            onMouseEnter={() => setDropdown(true)}
            onMouseLeave={() => setDropdown(false)}
        >
            <Link to={`/Women/${item.itemCat}/${item.cat}`} className='womenNavLink' onClick={scrollToTop}>
                <p style={{ color: '#b51f29' }}>{item.sale}</p>
                <span>{item.title}</span>
            </Link>

            {dropdown && item.children && (
                // <div className="dropdown-Overlay">
                    <div className="dropdownWrapper">
                    <ul className="dropdown-menu">
                        {item.children.map((dropDownItem) => (

                            <li key={dropDownItem.id} onClick={scrollToTop}>
                                <Link
                                    className={dropDownItem.cName}
                                    to={`/Women/${dropDownItem.itemCat}/${dropDownItem.cat}`}
                                    onClick={() => setDropdown(!dropdown)}
                                >
                                    <span>{dropDownItem.title}</span>
                                </Link>

                            </li>

                        ))}

                    </ul>
                    <img src={item.image} alt="" className='dropDownImg' />
                {/* </div> */}
                </div>
            )}

        </li>
    )
}

export default WomenList






// import React from 'react';
// import './womenNav.scss';

// const WomenList = ({id,title, sale, active, setSelected}) => {
//     return (
//         <li
//             className={active ? "women-listItem2" : "women-listItem"}
//             onClick={() => setSelected(id)}
//         >
            // <p style={{color:'#b51f29'}}>{sale}</p>
            // <span>{title}</span>
//         </li>
//     )
// }

// export default WomenList


