import React from 'react';
import './featured.scss';
import { Link } from 'react-router-dom';
import { kbrandData, mbrandData, wbrandData } from '../../dummydata';
import BrandCard from '../card/BrandCard';
import { IoIosArrowRoundForward } from "react-icons/io";


const FeaturedBrands = () => {

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }


    return (
        <div className='featuredBrand'>

            <div className="top">
                <h3>Featured Brands</h3>
                <Link to="Women/All-Brands" className='feature-link' onClick={scrollToTop}>
                    <span>See All</span>
                    <IoIosArrowRoundForward className='featureIcon' />
                </Link>
            </div>

            <div className="bottom">
                    {wbrandData.map(item => (
                        <BrandCard item={item} key={item.id} />
                    ))}
                </div>

        </div>
    )
}

export default FeaturedBrands


export const MenFeaturedBrands = () => {

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    return (
        <div className='featuredBrand'>

            <div className="top">
                <h3>Featured Brands</h3>
                <Link to="Women/All-Brands" className='feature-link' onClick={scrollToTop}>
                    <span>See All</span>
                    <IoIosArrowRoundForward className='featureIcon' />
                </Link>
            </div>

            <div className="bottom">
                    {mbrandData.map(item => (
                        <BrandCard item={item} key={item.id} />
                    ))}
                </div>

        </div>
    )

}


export const KidsFeaturedBrands = () => {

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    return (
        <div className='featuredBrand'>

            <div className="top">
                <h3>Featured Brands</h3>
                <Link to="Women/All-Brands" className='feature-link' onClick={scrollToTop}>
                    <span>See All</span>
                    <IoIosArrowRoundForward className='featureIcon' />
                </Link>
            </div>

            <div className="bottom">
                    {kbrandData.map(item => (
                        <BrandCard item={item} key={item.id} />
                    ))}
                </div>

        </div>
    )

}