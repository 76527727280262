// import axios from 'axios';
import axios from 'axios';
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useLocation } from 'react-router-dom';
// import { useLocation } from 'react-router-dom';

const AdminSearch = () => {

    const [searchItem, setSearchItem] = useState([]);

    const query = useLocation().search;

    useEffect(() => {
        const fetchVideos = async () => {
            const res = await axios.get(`https://tunjoo-server-36b3be406a89.herokuapp.com/api/products/search${query}`);
            setSearchItem(res.data);
        };
        fetchVideos();
    }, [query]);

    return (
        <div className='adminSearch'>
            {searchItem.map((item) => (
                <div className="searchItemWrapper" key={item._id}>
                    <img src={item?.photos[0]}  className='searchImg' alt=''/>
                    <span>{item.productName}</span>
                </div>
            ))}
        </div>
    )
}

export default AdminSearch