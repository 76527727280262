import React from 'react';
import { useState } from 'react';
import './edit.scss';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import { useSelector } from 'react-redux';
import newRequest from '../../../config/newRequest';

const NewAddress = () => {


    // const user = useSelector((state) => state.user.currentUser);
    const user = JSON.parse(localStorage.getItem("currentUser"));
    const [success, setSuccess] = useState(false);


    const [loading, setLoading] = useState(false);

    //EDIT / UPDATE USER
    const [member, setMember] = useState({
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        address: user.address,
        phone: user.phone,
        city: user.city,
        country: user.country,
        newCountry: user.newAddress.newCountry,
        newCity: user.newAddress.newCity,
        newStreet: user.newAddress.newStreet,
        accessToken: user.accessToken,
    });

    const handleChange = (e) => {
        setMember((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleSubmit = async (e) => {
        // e.preventDefault();
        setLoading(true);
        try {
            const updatedUser = {
                ...member
            };

            const res = await newRequest.put("https://tunjoo-server-36b3be406a89.herokuapp.com/api/users/update/"+ user._id, updatedUser);
            localStorage.setItem("currentUser", JSON.stringify(res.data));
            window.location.reload();
            setLoading(false);
            setSuccess(res.data);

        } catch (err) {
            console.log(err);
        }

    }


    return (

        <div className="edit">
            {loading && "....Loading"}
            <h3>Add Current Delivery Address</h3>

            <form className="editProfileWrapper" onSubmit={handleSubmit}>

                <div className="editBottom">

                    <div className="editBottomItem">
                        <label>Street Address:</label>
                        <input
                            type="text"
                            name="newStreet"
                            value={member.newStreet}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="editBottomItem">
                        <label>Country:</label>
                        <input
                            type="text"
                            name="newCountry"
                            value={member.newCountry}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="editBottomItem">
                        <label>City:</label>
                        <input
                            type="text"
                            name="newCity"
                            value={member.newCity}
                            onChange={handleChange}
                        />
                    </div>

                    <button type="submit" className="updateBtn">Update</button>
                    {success && <span style={{ color: 'green' }}>New Address Added!</span>}

                </div>

            </form>


        </div>
    )
}

export default NewAddress