import React from 'react';
import './info.scss';
import { NavLink } from 'react-router-dom';

const InfoList = ({id, icon, path}) => {
    return (
        <NavLink
            to={path}
            style={{textDecoration: 'none', color: 'inherit'}}
            className={({ isActive }) => "listItem" + (isActive ? " activated" : "")}
        >
            <span className='accSideIcon'>{icon}</span>
            <p className='accSideText'>{id}</p>
        </NavLink>
    )
}

export default InfoList