import React, {  useState } from 'react';
import './accounthome.scss';
// import { useSelector } from 'react-redux';
// import axios from 'axios';
// import FavouriteCard from '../card/FavouriteCard';

const WishList = () => {

    // const {currentUser} = useSelector((state) => state.user);
    // const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const [favorites,] = useState([]);

    // //GET WISHLIST
//    useEffect(() => {
//         const getFavorites = async () => {
//             try {
//                 const likedList = await axios.get(`https://tunjoo-server-36b3be406a89.herokuapp.com/api/users/${currentUser._id}/favorites`);
//                 setFavorites(likedList.data);
//             } catch (err) {
//                 // console.log(err);
//             }
//         };
//         getFavorites();
//     }, [currentUser]);


    return (
        <div className='accountWishlist'>
            <div className="wishlistLeft">
                <h3>My Wishlist</h3>
                <p>
                    Waiting until payday to get your dream wardrobe? Here at Tunjoo we spoil
                    you daily with New-In products so don't go forgetting
                    your must haves – Save them here to buy whenever you can, we all need to treat ourselves.
                </p>
            </div>

            <div className="wishlistRight">
                <h3>Total Items ({favorites.length})</h3>
                <hr className='divider'/>
                <div className="itemsWrapper">
                {/* {favorites.map((item) => (
                    <FavouriteCard item={item} key={item._id}/>

                ))} */}
                </div>

            </div>
        </div>
    )
}

export default WishList