// import React from 'react';
// import './type.scss';
// import { NavLink } from 'react-router-dom';


// const ShippingInfoList = ({ id,desc }) => {
//     return (
//         <NavLink 
//         style={{textDecoration: 'none', color: 'inherit'}}
//         className={({ isActive }) => "shippingTypeItem" + (isActive ? " activated" : "")}>
//             <h4 className='infoTitle'>{id}</h4>
//             <span className='infoDec'>{desc}</span>
//         </NavLink>
//     )
// }

// export default ShippingInfoList

import React from 'react';
import './type.scss';

const ShippingInfoList = ({id, desc,active, setSelected }) => {
    return (
        <li
            className={active ? "shipping-typeItem active" : "shipping-typeItem"}
            onClick={() => setSelected(id) }
        >
            <span className='infoTitle'>{id}</span>
            <span className='infoDesc'>{desc}</span>

        </li>
    )
}

export default ShippingInfoList
