import React from 'react';
import Button from './Button';
import "./subs.scss";


const KidsSubCats = ({ cat, handleClick }) => {
    return (
        <div className="subCatsWrapper">
             {/* BOYS CLOTHINGS */}
            {cat === "Boys' Clothing" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="ALL PRODUCTS" />
                    <Button onClickHandler={handleClick} value="Boys-ShirtsandOvershirts" title="SHIRTS | OVERSHIRTS" />
                    <Button onClickHandler={handleClick} value="Boys-KnitwearandSweaters" title="KNITWEAR | SWEATERS" />
                    <Button onClickHandler={handleClick} value="Boys-TshirtsandPolo" title="T-SHIRTS | POLO SHIRTS " />
                    <Button onClickHandler={handleClick} value="Boys-Bottoms" title="BOTTOMS" />
                    <Button onClickHandler={handleClick} value="Boys-MatchingSets" title="MATCHING SETS" />
                    <Button onClickHandler={handleClick} value="Boys-Jackets" title="JACKETS" />
                    <Button onClickHandler={handleClick} value="Boys-HoodiesSweatshirts" title="HOODIES | SWEATSHIRTS" />
                </div>
            }
            {cat === "Boys' Matching Sets" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="ALL PRODUCTS" />
                    <Button onClickHandler={handleClick} value="Boys-Tracksuit" title="TRACKSUIT" />
                    <Button onClickHandler={handleClick} value="Boys-TrouserSet" title="LOUNGE SET" />
                    <Button onClickHandler={handleClick} value="Boys-ShortsSet" title="SHORTS SET" />
                </div>
            }
            {cat === "Boys' Jackets" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="ALL PRODUCTS" />
                    <Button onClickHandler={handleClick} value="Boys-DenimJacket" title="DENIM" />
                    <Button onClickHandler={handleClick} value="Boys-BomberJacket" title="BOMBER" />
                    <Button onClickHandler={handleClick} value="Boys-LeatherJacket" title="LEATHER" />
                </div>
            }
            {/* BOTTOMS */}
            {cat === "Boys' Bottoms" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="ALL PRODUCTS" />
                    <Button onClickHandler={handleClick} value="Boys-PlainTrousers" title="PLAIN TROUSERS" />
                    <Button onClickHandler={handleClick} value="Boys-JoggersTracksuits" title="JOGGERS | TRACKSUITS" />
                    <Button onClickHandler={handleClick} value="Boys-Shorts" title="SHORTS" />
                    <Button onClickHandler={handleClick} value="Boys-CargoParachute" title="CARGO | PARACHUTE" />
                </div>
            }
            {/* TSHIRTS */}
            {cat === "Boys'-TShirts-and-Polo-Shirts" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="ALL PRODUCTS" />
                    <Button onClickHandler={handleClick} value="Boys-PoloShirts" title="POLO SHIRTS" />
                    <Button onClickHandler={handleClick} value="Boys-TShirts" title="T-SHIRTS" />
                </div>
            }
            {/* SHIRTS */}
            {cat === "Boys' Shirts and Overshirts" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="ALL PRODUCTS" />
                    <Button onClickHandler={handleClick} value="Boys-Shirts" title="SHIRTS" />
                    <Button onClickHandler={handleClick} value="Boys-Overshirts" title="OVERSHIRTS" />
                </div>
            }
            {/* SWEATERS & KNITWEAR */}
            {cat === "Boys' Knitwear and Sweaters" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="ALL PRODUCTS" />
                    <Button onClickHandler={handleClick} value="Boys-Knitwear" title="KNITWEAR" />
                    <Button onClickHandler={handleClick} value="Boys-Sweaters" title="SWEATERS" />
                </div>
            }
             {/* HOODIES */}
            {cat === "Boys' Hoodies and Sweatshirts" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="ALL PRODUCTS" />
                    <Button onClickHandler={handleClick} value="Boys-Hoodies" title="HOODIES" />
                    <Button onClickHandler={handleClick} value="Boys-Swatshirts" title="SWEATSHIRTS" />
                </div>
            }

            {/* GIRLS CLOTHING */}
            {cat === "Girls' Clothing" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="ALL PRODUCTS" />
                    <Button onClickHandler={handleClick} value="Girls-DressesJumpsuits" title="DRESSES | JUMPSUITS" />
                    <Button onClickHandler={handleClick} value="GirlsTopsandTshirts" title="TOPS | T-SHIRTS " />
                    <Button onClickHandler={handleClick} value="Girls-Bottoms" title="BOTTOMS" />
                    <Button onClickHandler={handleClick} value="Girls-MatchingSets" title="MATCHING SETS" />
                    <Button onClickHandler={handleClick} value="Girls-Jackets" title="JACKETS" />
                    <Button onClickHandler={handleClick} value="Grils-HoodiesSweatshirts" title="HOODIES | SWEATSHIRTS" />
                </div>
            }
            {/* DRESSES */}
            {cat === "Girls' Dresses and Jumpsuits" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="ALL PRODUCTS" />
                    <Button onClickHandler={handleClick} value="Dress" title="DRESSES" />
                    <Button onClickHandler={handleClick} value="Jumpsuits" title="JUMPSUITS" />
                </div>
            }
            {/* TOPS */}
            {cat === "Girls' Tops" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="ALL PRODUCTS" />
                    <Button onClickHandler={handleClick} value="Girls-Tishirts" title="T-SHIRTS" />
                    <Button onClickHandler={handleClick} value="Girls-Blouses" title="BLOUSES" />
                    <Button onClickHandler={handleClick} value="Girls-CropTops" title="CROP TOPS" />
                    <Button onClickHandler={handleClick} value="Girls Overshirts" title="OVERSHIRTS" />
                </div>
            }
            {cat === "Girls' Hoodies and Sweatshirts" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="ALL PRODUCTS" />
                    <Button onClickHandler={handleClick} value="Girls-Hoodies" title="HOODIES" />
                    <Button onClickHandler={handleClick} value="Girls-Sweatshirts" title="SWEATSHIRTS" />
                </div>
            }
            {cat === "Girls' Matching Sets" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="ALL PRODUCTS" />
                    <Button onClickHandler={handleClick} value="Girls-SkirtSet" title="SKIRT SET" />
                    <Button onClickHandler={handleClick} value="Girls-TrouserSet" title="TROUSER SET" />
                    <Button onClickHandler={handleClick} value="Girls-ShortsSet" title="SHORTS SET" />
                </div>
            }
            {cat === "Girls' Jackets and Sweaters" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="ALL PRODUCTS" />
                    <Button onClickHandler={handleClick} value="Girls-DenimJacket" title="DENIM" />
                    <Button onClickHandler={handleClick} value="Girls-BomberJacket" title="BOMBER" />
                    <Button onClickHandler={handleClick} value="Girls-LeatherJacket" title="LEATHER" />
                    <Button onClickHandler={handleClick} value="Girls-Sweaters" title="SWEATERS" />
                </div>
            }
            {/* BOTTOMS */}
            {cat === "Girls' Bottoms" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="ALL PRODUCTS" />
                    <Button onClickHandler={handleClick} value="Girls-PlainTrousers" title="PLAIN TROUSERS" />
                    <Button onClickHandler={handleClick} value="Girls-Skirts" title="SKIRTS" />
                    <Button onClickHandler={handleClick} value="Girls-JoggersTracksuits" title="JOGGERS | TRACKSUITS" />
                    <Button onClickHandler={handleClick} value="Girls-Shorts" title="SHORTS" />
                    <Button onClickHandler={handleClick} value="Girls-CargoParachute" title="CARGO | PARACHUTE" />
                </div>
            }


            {/* SHOES */}
            {cat === "Girls' Shoes" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="ALL PRODUCTS" />
                    <Button onClickHandler={handleClick} value="Girls-Sneakers" title="SNEAKERS" />
                    <Button onClickHandler={handleClick} value="Girls-FlatSandals" title="FLAT SANDALS" />
                    <Button onClickHandler={handleClick} value="Ballerinas" title="BALLERINAS" />
                    <Button onClickHandler={handleClick} value="Girls-Loafers" title="LOAFERS" />
                </div>
            }
            {cat === "Boys' Shoes" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="ALL PRODUCTS" />
                    <Button onClickHandler={handleClick} value="Boys-Sneakers" title="SNEAKERS" />
                    <Button onClickHandler={handleClick} value="Boys-FlatSandals" title="FLAT SANDALS" />
                    <Button onClickHandler={handleClick} value="Boys-Loafers" title="LOAFERS" />
                </div>
            }


            {/* BAGS */}
            {cat === "Girls' Bags" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="VIEW ALL" />
                    <Button onClickHandler={handleClick} value="Girls-Backpack" title="BACKPACK" />
                    <Button onClickHandler={handleClick} value="Girls-TeenBag" title="GIRLS' HANDBAG" />
                    <Button onClickHandler={handleClick} value="Girls-Wallet" title="WALLET" />
                </div>
            }
            {cat === "Boys' Bags" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="VIEW ALL" />
                    <Button onClickHandler={handleClick} value="Boys-Backpack" title="BACKPACK" />
                    <Button onClickHandler={handleClick} value="Boys-CrossBag" title="BOYS' CROSSBAG" />
                </div>
            }
            {cat === "Kids' Socks and Belts" &&
                <div className="btnWrapper">
                    <Button onClickHandler={handleClick} value="" title="VIEW ALL" />
                    <Button onClickHandler={handleClick} value="Kids-Socks" title="SOCKS" />
                    <Button onClickHandler={handleClick} value="Kids-Tights" title="TIGHTS" />
                    <Button onClickHandler={handleClick} value="Kids-Belts" title="BELTS" />
                </div>
            }
        </div>
    )
}

export default KidsSubCats